import React, { useContext } from "react";
import styled from "styled-components";
import { Translations } from "./Lang";
import { LangContext, ThemeContext } from "../../../App";
import { Column, Head, Row, Table } from "./OfferPage/Table";


const Availability = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 20px;
  font-weight: 400;
  color: ${params => params.$isAvailable
    ? '#2daa41'
    : 'red'};

  @media (max-width: 767px) {
    font-size: 14px;
    text-transform: lowercase;
  }
`

const BookingButton = styled.button`
  background-color: transparent;
  font-family: 'Gilroy-ExtraBold';
  text-decoration: underline;
  border: none;
  outline: none;
  font-size: 20px;
  padding: 0px;
  box-sizing: border-box;
  color: ${params => params.$theme.primaryColor};

  &:disabled {
    opacity: 0.25;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }
`

const Wrapper = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  color: ${params => params.$theme.primaryColor};
  font-family: 'Manrope';
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  
  @media (max-width: 767px) {
    box-sizing: border-box;
    font-size: 24px;
  }
`

const Title = styled.div`
  @media (max-width: 767px) {
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const Subtitle = styled.div`
  margin-top: 13px;
  font-size: 20px;

  @media (max-width: 767px) {
    margin-top: 8px;
    font-size: 16px;
    padding: 0px 15px;
  }
`


export function BookingOption ({ time, price, isAvailable = true })
{
  const { lang } = useContext(LangContext);
  const theme = useContext(ThemeContext);

  return (
    <>
      <Column>
        {time}
      </Column>
      <Column>
        {price}
      </Column>
      <Column>
        <Availability $isAvailable={isAvailable} >
          {isAvailable 
            ? Translations[lang].bookingTable.available 
            : Translations[lang].bookingTable.unavailable}
        </Availability>
      </Column>
      <Column>
        <BookingButton $theme={theme} disabled={!isAvailable}>
          {Translations[lang].bookingTable.bookOnline}
        </BookingButton>
      </Column>
    </>
  )
}

export function BookingTable ({ children })
{
  const { lang } = useContext(LangContext);
  const theme = useContext(ThemeContext);

  const bookingOptions = React
    .Children
    .toArray(children)
    // .filter((item) => item.type === BookingOption);


  return (
    <Wrapper $theme={theme}>
      <Title>{Translations[lang].bookingTable.title}</Title>
      <Subtitle>{Translations[lang].bookingTable.subtitle}</Subtitle>
      <Table $hPadding={35}>
        <thead>
          <Head>
            <Column>{Translations[lang].bookingTable.timeMargin}</Column>
            <Column>{Translations[lang].bookingTable.price}</Column>
            <Column>{Translations[lang].bookingTable.status}</Column>
            <Column>{Translations[lang].bookingTable.booking}</Column>
          </Head>
        </thead>
        <tbody>
          {bookingOptions.map((bookingOption, key) => <Row key={key}>{bookingOption}</Row> )}
        </tbody>
      </Table>
    </Wrapper>
  )
}
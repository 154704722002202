/**
 * Strona z listą wpisów
 */

import styled from "styled-components";
import Filters from "./Filters";
import List from "./List";
import { createContext, useState } from "react";
import ContactSection from "../../../Components/ContactSection/ContactSection";
import Footer from "../../../Components/Footer/Footer";
import CoursesSection from "../../../Components/Courses/CoursesSection";
import PageMenu from "../../../Components/Menu/Factory/PageMenu";

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  padding-top: 39px;
  box-sizing: border-box;

  @media (max-width: 767px) {
    width: 100%;
  }
`

export const FilterContext = createContext();


export default function BlogPage ({})
{
  const [showTags, setShowTags] = useState(['incentive', 'yachtcharter', 'academy', 'camp']);

  return (
    <>
      <PageMenu title="Blog" />
      <Wrapper>
        <FilterContext.Provider value={{showTags, setShowTags}}>
          <Filters/>
          <List/>
        </FilterContext.Provider>
      </Wrapper>
      <CoursesSection top="65px" />
      <ContactSection top="75px" />
      <Footer />
    </>
  )
}
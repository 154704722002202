import { useContext } from "react"
import { SectionTitle } from "./Yacht"
import { LangContext } from "../../../App"
import { Translations } from "./Lang";
import styled from "styled-components";
import AdditionalEquipment from "./AdditionalEquipment";

const Wrapper = styled.div`
  margin-top: 35px;
`

const Boxes = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 14px 17px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }
`

const Box = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-family: 'Gilroy-Light';
  font-size: 14px;
  font-weight: 400;
  color: #133b51;
  > img {
    height: 30px; 
    width: 30px;
  }
`

const Single = function ({ id, name })
{
  return (
    <Box>
      <img src={`/ico/equipment/${id}.svg`} alt=""/>
      {name}
    </Box>
  )
}


export const __renderable_equipment = [
  9, 11, 12, 13, 17, 18, 27, 29, 35
];

export default function Equipment ({ equipment })
{
  const { lang } = useContext(LangContext);

  if (equipment.length === 0) {
    return <></>
  }

  const renderableEquipmentList = equipment
    .filter(n => __renderable_equipment.includes(n.id));

  return (
    <Wrapper>
      <SectionTitle>
        {Translations[lang].equipment.title}
      </SectionTitle>
      <Boxes>
        {renderableEquipmentList.map((item, key) => (
          <Single {...item} key={key} />
        ))}
      </Boxes>
      <AdditionalEquipment equipment={equipment}/>
    </Wrapper>
  )
}

export const Translations = {
  pl: {
    title: "Spędź aktywnie czas na otwartych wodach",
    descr: "Organizujemy półkolonie dla młodych adeptów żeglarstwa –  czekają na Was kreatywne zadania, doskonała zabawa i niezapomniane przygody!",
    offer: {
      title: "Półkolonie sportowo-żeglarskie ",
      descr: <>Edukacja, rekreacja i rozrywka w jednym – to właśnie nasze sportowo-żeglarskie kolonie dla dzieci i młodzieży. Zapewniamy interesujący program kolonii, profesjonalną kadrę nauczycielską i komfortową bazę noclegową. 
      <br /> <br />
      Podczas zajęć dzieci nauczą się podstaw żeglugi, a jednocześnie aktywnie spędzą czas w stałym kontakcie z naturą. To doskonała okazja do rozpoczęcia przygody z żeglowaniem i podróżowaniem po szerokich wodach. 
      </>,
      list: [
        "Aktywnie spędzony czas w bliskości natury",
        "Kreatywne zajęcia i zadania",
        "Rozwijanie zainteresowań i pasji",
        "Rozwój cech charakteru: zaradność, pewność siebie, działanie w grupie, odpowiedzialność"
      ],
      timeTable: [ 
        "zbiórka dzieci w Ośrodku Górka Szczęśliwicka", 
        "zajęcia programowe", 
        "obiad", 
        "zajęcie programowe", 
        "podwieczorek", 
        "odbiór dzieci z Ośrodka Górka Szczęśliwicka"
      ],
      downloads: [
        {
          title: "Ogólne Warunki Uczestnictwa",
          url: "#"
        },
        {
          title: "Upoważnienie do Odbioru Dziecka",
          url: "#"
        },
        {
          title: "Regulamin Uczestnika Półkolonii",
          url: "#"
        },
        {
          title: "Co zabrać na półkolonie żeglarskie",
          url: "#"
        },
        {
          title: "Karta Informacyjna RODO",
          url: "#"
        },
        {
          title: "Wpis do Rejestru Organizatorów Turystyki",
          url: "#"
        },
        {
          title: "Gwarancja Ubezpieczeniowa",
          url: "#"
        },
        {
          title: "Standardowy Formularz informacyjny",
          url: "#"
        },
      ]
    },
  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;
export const Translations = {
  pl: {
    listTitle: "Zapewniamy",
    gallery: "Galeria",
    bookingBtn: "ZAREZERWUJ TERMIN"

  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;
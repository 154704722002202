
import { useContext } from 'react';
import { styled } from 'styled-components';
import { LangContext } from '../../../../App';
import { Translations } from './Lang';

const Bounding = styled.div`
  @media (max-width: 767px) {
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  margin-top: 70px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 32px 46px;
  background-size: cover;
  background-position: center center;
  background-image: url(/sub/academy/egzamin.webp);

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 50px;
    padding: 33px 20px;
  }
`

const Content = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 767px) {
    width: 100%;
    text-align: left;
  }
`

const Title = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 36px;
  font-weight: 400;
  color: #94073a;
`

const Descr = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  color: #000;
  margin-top: 10px;
`

const Button = styled.a`
  margin-top: 22px;
  text-decoration: none;
  font-family: 'Manrope';
  font-size: 20px;
  font-weight: 700;
  box-sizing: border-box;
  padding: 10px 45px;
  background-color: #94073a;
  color: #fff;
  border-radius: 5px;
`

export default function Exam ()
{
  const { lang } = useContext(LangContext);

  return (
    <Bounding>
      <Wrapper>
        <Content>
          <Title>
            {Translations[lang].exam.title}
          </Title>
          <Descr>
            {Translations[lang].exam.descr}
          </Descr>
          <Button href="#">
            {Translations[lang].exam.btn}
          </Button>
        </Content>
      </Wrapper>
    </Bounding>
  )
}
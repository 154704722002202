import { useEffect, useState } from "react";
import Footer from "../../Components/Footer/Footer";
import LiteMenu from "../../Components/Menu/Factory/LiteMenu";
import axios from "axios";
import __server from "../../Api";

export default function ProfilePage ()
{
  const [profile, setProfile] = useState(null);

  useEffect(() => {

    const bearer = localStorage.getItem('bearer_token');

    if (bearer == null) {
      window.location.href = '/logowanie';
      return () => {};
    }

    axios.get(`${__server}/front/profile`).then((resp) => {
      setProfile(resp.data);
    }).catch((err) => {
      localStorage.removeItem('bearer_token');
      window.location.href = '/logowanie';
    })
  }, []);


  return (
    <>
      <LiteMenu />
      <center>
        <textarea value={profile === null ? "Ładowanie..." :  JSON.stringify(profile)} />
      </center>
      <Footer />
    </>
  )
}
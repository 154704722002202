import {Translations} from "./Lang";
import styled from "styled-components";
import {useContext} from "react";
import {LangContext, ThemeContext} from "../../App";
import { FacebookIcon, InstagramIcon, YoutubeIcon } from "../Icons"

const Wrapper = styled.div`
    @media (max-width: 767px) {
    	display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
    }
`

const FollowUs = styled.div`
  margin-top: 46px;
  font-family: 'Gilroy-ExtraBold';
  font-size: 32px;
  color: ${params => params.$theme.primaryColor};
  font-weight: 400;
		
	@media (max-width: 767px) {
			font-size: 24px;
  }
`

const IconsWrapper = styled.div`
  margin-top: 27px;
  display: flex;
  gap: 18px;

  svg, path, g {
    width: 31px;
    fill: ${params => params.$theme.primaryColor};
  }

	@media (max-width: 767px) {
		margin-top: 16px;
			
		svg, path, g {
			width: 25px;	
    }
  }

`

const IconBox = styled.div`
  width: 58px;
  height: 58px;
  background-color: #fff;
  border-radius: 100vw;
  box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
	@media (max-width: 767px) {
		width: 47px;
		height: 47px;
	}
`
export default function Icons ()
{
	const theme = useContext(ThemeContext);
	const { lang } = useContext(LangContext);

	return (
		<Wrapper>
			<FollowUs $theme={theme}>
				{Translations[lang].contactSection.followUs}
			</FollowUs>
			<IconsWrapper $theme={theme}>
				<IconBox>
					<FacebookIcon />
				</IconBox>
				<IconBox>
					<InstagramIcon />
				</IconBox>
				<IconBox>
					<YoutubeIcon />
				</IconBox>
			</IconsWrapper>
		</Wrapper>
	)
}
import { useContext, useEffect, useState } from "react"
import { FilterModelsContext } from "../ModelsPage"
import Select from 'react-select'
import axios from "axios";
import __server from "../../../Api";


export default function TbxModel ()
{
  const { filters, setFilters } = useContext(FilterModelsContext);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    axios.get(`${__server}/front/model`).then((resp) => {

      setOptions(resp.data.map((opt) => {
        return {
          label: opt.model,
          value: opt.id
        }
      }));

    }).catch((err) => {
      console.error(err);
    });
  }, []);

  return (
    <>
      <Select 
        options = {options}
        placeholder = "Wybierz model"
        noOptionsMessage={() => "Nic nie znaleziono"}

        isClearable={true}
        value={options.filter((opt) => opt.value === filters.model)}
        onChange={(e) => {
          const value = e !== null
            ? e.value
            : null;

          setFilters({kind: [], minCabins: 0, maxCabins: 99, minLength: 0, maxLength: 99, model: value, page: 1})
        }}

        styles = {{
          control: (baseStyles, state) => ({
            ...baseStyles,
            marginTop: '6px',
            color: '#133b51',
            borderColor: '#32C8D2 !important',
            fontFamily: 'Gilroy-Light',
            boxShadow: state.isFocused ? '0 0 0 1px #3792ca' : 'none'
          }),
          indicatorSeparator: (baseStyles, state) => ({
            ...baseStyles,
            display: 'none'
          }),
          menuList: (baseStyles, state) => ({
            ...baseStyles,
            fontFamily: 'Gilroy-Light',
            color: '#133b51',
            zIndex: 999
          }),
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
      />
    </>
  )
}
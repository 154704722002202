export const Translations = {
  pl: {
    title: 'Najczęściej zadawane pytania',
    faq: [
      {
        title: 'Czarter całego jachtu',
        body: <>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </>
      },
      {
        title: 'Transport',
        body: <>
          Przykłady transportu do najczęściej wybieranych miejsc odbioru jachtów. 
          Każdorazowo służymy radą przy ustalaniu transportu i oferujemy pośrednictwo w zakupie biletów lotniczych.
          <br/><br/><b>Chorwacja:</b><br/>
          – przelot samolotem na lotniska: Zadar, Split, Zagrzeb, Dubrownik. Z lotniska do mariny transport autokarem (linie rejsowe) lub transfer busem (organizowany za pośrednictwem naszej firmy).<br/>
          – własnym samochodem – przejazd w większości autostradą<br/>
          – autokar rejsowy – w okresie wakacyjnym kursuje autokar wyjeżdżający z Warszawy, Wrocławia, Łodzi Polichna i Katowic.
          <br/>
          <br/><b>Grecja:</b><br/>
          – zalecamy transport Lotniczy. Przelot do Aten, Prevezy, Kos, Levkadę. Z lotniska organizujemy transfer busem do mariny.<br/>
        </>
      },
      {
        title: 'Why do we use it?',
        body: <>
          Suspendisse massa risus, pretium id interdum in, dictum sit amet ante.
          Fusce vulputate purus sed tempus feugiat.
        </>
      }
    ]
  },
  en: {
    title: 'Najczęściej zadawane pytania',
    faq: [
      {
        title: 'Czarter całego jachtu',
        body: <>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </>
      },
      {
        title: 'Transport',
        body: <>
          Przykłady transportu do najczęściej wybieranych miejsc odbioru jachtów. 
          Każdorazowo służymy radą przy ustalaniu transportu i oferujemy pośrednictwo w zakupie biletów lotniczych.
          <br/><br/><b>Chorwacja:</b><br/>
          – przelot samolotem na lotniska: Zadar, Split, Zagrzeb, Dubrownik. Z lotniska do mariny transport autokarem (linie rejsowe) lub transfer busem (organizowany za pośrednictwem naszej firmy).<br/>
          – własnym samochodem – przejazd w większości autostradą<br/>
          – autokar rejsowy – w okresie wakacyjnym kursuje autokar wyjeżdżający z Warszawy, Wrocławia, Łodzi Polichna i Katowic.
          <br/>
          <br/><b>Grecja:</b><br/>
          – zalecamy transport Lotniczy. Przelot do Aten, Prevezy, Kos, Levkadę. Z lotniska organizujemy transfer busem do mariny.<br/>
        </>
      },
      {
        title: 'Why do we use it?',
        body: <>
          Suspendisse massa risus, pretium id interdum in, dictum sit amet ante.
          Fusce vulputate purus sed tempus feugiat.
        </>
      }
    ]
  },
  de: {
    title: 'Najczęściej zadawane pytania',
    faq: [
      {
        title: 'Czarter całego jachtu',
        body: <>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </>
      },
      {
        title: 'Transport',
        body: <>
          Przykłady transportu do najczęściej wybieranych miejsc odbioru jachtów. 
          Każdorazowo służymy radą przy ustalaniu transportu i oferujemy pośrednictwo w zakupie biletów lotniczych.
          <br/><br/><b>Chorwacja:</b><br/>
          – przelot samolotem na lotniska: Zadar, Split, Zagrzeb, Dubrownik. Z lotniska do mariny transport autokarem (linie rejsowe) lub transfer busem (organizowany za pośrednictwem naszej firmy).<br/>
          – własnym samochodem – przejazd w większości autostradą<br/>
          – autokar rejsowy – w okresie wakacyjnym kursuje autokar wyjeżdżający z Warszawy, Wrocławia, Łodzi Polichna i Katowic.
          <br/>
          <br/><b>Grecja:</b><br/>
          – zalecamy transport Lotniczy. Przelot do Aten, Prevezy, Kos, Levkadę. Z lotniska organizujemy transfer busem do mariny.<br/>
        </>
      },
      {
        title: 'Why do we use it?',
        body: <>
          Suspendisse massa risus, pretium id interdum in, dictum sit amet ante.
          Fusce vulputate purus sed tempus feugiat.
        </>
      }
    ]
  }
}
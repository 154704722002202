import styled from "styled-components"
import Top from "./Top"
import Details from "./Details"
import Equipment from "./Equipment"
import GoodToKnow from "./GoodToKnow"
import BaseDetails from "./BaseDetails"
import Callendars from "./Callendars"
import { ScaleLoader } from "react-spinners"
import {useContext, useEffect, useRef, useState} from "react";
import PromotedModelsVerticalSection from "../../../Components/PromotedModels/PromotedModelsVerticalSection";
import axios from "axios"
import __server from "../../../Api"
import { LangContext } from "../../../App"
import { useParams } from "react-router-dom"

const Wrapper = styled.div`
  margin-top: 45px;
  display: flex;
  justify-content: space-between;
`

const Left = styled.div`
  width: 300px;

  @media (max-width: 767px) {
    display: none;
  }
`

const Right = styled.div`
  width: 980px;

  @media (max-width: 767px) {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

export const SectionTitle = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: #133b51;
`

const LoadingWrapper = styled.div`
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
`

/**
 * WARN: Komponent globalny wkorzystywany w Hero Search Menu
 *       przyjmuje body do wyrenderowania nad propsami,
 *       w orginalnej wresji NULL, w wersji search resuta
 *       z głównej cały pakiet premium
 */
export default function Yacht ({ yachtId, extraProp = null, disableBelowButtonsProp = false, overButtonProp = null}) 
{
  const { lang } = useContext(LangContext);

  const [data, setData] = useState(null);
  const callendar = useRef();

  useEffect(() => {
    setData(null);
    axios.get(`${__server}/front/yacht/${yachtId}?lang=${lang}`).then((resp) => {
      setData(resp.data);
    }).catch((err) => {
      console.error(err);
    });
  }, [lang]);


  if (data == null) {
    return (
      <LoadingWrapper>
        <ScaleLoader color="#133b51" />
      </LoadingWrapper>
    );
  }

  return (
    <>
      <Top 
        {...data} 
        callendar={callendar} 
        disableBelowButtonsProp={disableBelowButtonsProp}
        overButtonProp={overButtonProp}
      />
      <Wrapper>
        <Left>
          <PromotedModelsVerticalSection />
        </Left>
        <Right>
          {extraProp !== null && extraProp}
          <Details {...data} />
          <Equipment {...data} />
          <div ref={callendar} ></div>
          <Callendars {...data} />
          <GoodToKnow />
          <BaseDetails {...data} />
        </Right>
      </Wrapper>
    </>
  )
}

export const Translations = {
  pl: {
    title: "Twoje dziecko chce zostać żeglarzem jachtowym?",
    descr: "Zapisz je na obóz szkoleniowy – nauczymy go wszystkiego, co niezbędne do zaliczenia egzaminu na żeglarza jachtowego.",
    offer: {
      title: "Obóz szkoleniowy na patent żeglarza jachtowego",
      descr: <>Profesjonalne przygotowanie do żeglugi po otwartych wodach – poznaj nasz program szkolenia na żeglarza jachtowego. Z nami zdobędziesz oficjalne uprawnienia przez naukę w połączeniu z doskonałą zabawą.
      <br /> <br />
      Gwarantujemy organizację na najwyższym poziomie, pełne bezpieczeństwo uczestników, nowoczesne jachty i kreatywne zabawy. Nasz obóz to niezapomniane przygody, relaks i niepowtarzalny żeglarski klimat. 
      </>,
      list: [
        "Profesjonalną realizację pełnego programu szkolenia na st. żeglarza jachtowego",
        "Najwyższy poziom organizacyjny rejsów",
        "Bezpieczeństwo uczestników",
        "Nowoczesne i bezpieczne jachty",
        "Rozwój cech charakteru: zaradność, pewność siebie, współpraca w grupie",
        "Niezapomniane przygody, relaks i żeglarski klimat"
      ],
      timeTable: [ 
        "Przyjazd w godzinach popołudniowych, zaokrętowanie, odprawa, kolacja",
        "Śniadanie, odprawa, część teoretyczna (wykład) – lunch – część praktyczna ( żeglowanie) – kolacja*",
        "Egzamin praktyczny oraz teoretyczny",
        "Śniadanie, zakończenie obozu, powrót do domu",
      ],
      downloads: [
        {
          title: "Ogólne Warunki Uczestnictwa",
          url: "#"
        },
        {
          title: "Upoważnienie do Odbioru Dziecka",
          url: "#"
        },
        {
          title: "Regulamin Uczestnika Obozu",
          url: "#"
        },
        {
          title: "Co zabrać na obóz żeglarski",
          url: "#"
        },
        {
          title: "Karta Informacyjna RODO",
          url: "#"
        },
        {
          title: "Wpis do Rejestru Organizatorów Turystyki",
          url: "#"
        },
        {
          title: "Gwarancja Ubezpieczeniowa",
          url: "#"
        },
        {
          title: "Standardowy Formularz informacyjny",
          url: "#"
        },
      ]
    },
  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;
import { Translations } from "../../Pages/Contact/Lang";
import styled from "styled-components";
import { useContext, useState } from "react";
import { LangContext } from "../../App";

import Lightbox from 'react-awesome-lightbox';
import "react-awesome-lightbox/build/style.css";
import Carousel from "../Carousel/Carousel";

const Wrapper = styled.div`
	margin-top: ${params => params.$top}px;
	@media (max-width: 767px) {
		box-sizing: border-box;
		padding: 0px 15px
	}
`;

const MobileWrapper = styled.div`
	@media (max-width: 767px) {
		overflow: scroll;
		overflow-y: hidden;  
		scroll-snap-align: center;
		box-sizing: border-box;
  }
`;

const Title = styled.div`
  margin-top: 48px;
  text-align: center;
  font-family: 'Gilroy-Light';
  font-size: 32px;
  font-weight: 400;
  color: #133b54;

  > span {
    font-family: 'Gilroy-ExtraBold';
  }
`;

const ImageWrapper = styled.div`
	border-radius: 10px;
	background-image: url(${params => params.$src});
	background-size: cover;
	background-position: center center;
	min-height: 250px;
	cursor: pointer;

	@media (max-width: 767px) {
		flex: 1;
		width: 271px;
		height: 250px;
	}
`;

const Outer = styled.div`
	margin-top: 40px;
	display: grid;
	grid-template-columns: 0.3fr 0.7fr;
	gap: 29px;

  @media (max-width: 767px) {
  	display: flex;
  	flex-wrap: nowrap;
  	flex: 1;
  	gap: 0px;
	}
`;

const Inner = styled.div`
	display: grid;
	gap: 29px;

	@media (max-width: 767px) {
		display: flex;
		flex-wrap: nowrap;
		flex: 1;
	}
`;

const Top = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 29px;
	
	@media (max-width: 767px) {
		display: flex;
		flex-wrap: nowrap;
		flex: 1;
	}
`;

const Bottom = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 29px;

  @media (max-width: 767px) {
  	display: flex;
  	flex-wrap: nowrap;
  	flex: 1;
  }
`;

export default function Gallery({ top }) {
	const { lang } = useContext(LangContext);
	const [lightBoxImage, setLightBoxImage] = useState(null);

	const images = Array.from({ length: 5 }, (_, i) =>
		Array.from({ length: 6 }, (_, j) => i * 6 + j + 1)
	);

	const Image = ({ idx }) => {
		const imageUrl = `/gallery/img${idx}.webp`;
		return (
			<ImageWrapper
				$src={imageUrl}
				onClick={() => setLightBoxImage(imageUrl)}
			/>
		);
	};

	return (
		<>
			{lightBoxImage && <Lightbox image={lightBoxImage} onClose={() => setLightBoxImage(null)} />}
			<Wrapper $top={top}>
				<Title>{Translations[lang].gallery}</Title>
				<Carousel allowTouchMobile={false} visibleSlidesMobile={1} visibleSlidesDesktop={1}>
					{images.map((group, i) => (
						<MobileWrapper key={i}>
							<Outer>
								<Image idx={group[0]} />
								<Inner>
									<Top>
										<Image idx={group[1]} />
										<Image idx={group[2]} />
									</Top>
									<Bottom>
										{group.slice(3).map(idx => (
											<Image key={idx} idx={idx} />
										))}
									</Bottom>
								</Inner>
							</Outer>
						</MobileWrapper>
					))}
				</Carousel>
			</Wrapper>
		</>
	);
}

import styled from "styled-components";
import IncentiveMenu from "../../../../Components/Menu/Factory/IncentiveMenu";
import Footer from "../../../../Components/Footer/Footer";
import Intro from "./Intro";
import CompanyChartersSection from "../Components/CompanyChartersSection";
import Regattas from "./Regattas";
import ManagementCurses from "./ManagementCurses";
import Newsletter from "../../../../Components/Newsletter/Newsletter";
import ContactSection from "../../../../Components/ContactSection/ContactSection";
import CompanyCruiseSection from "../../../../Components/CompanyCruiseSection/CompanyCruiseSection";
import SpecialEventsSection from "./SpecialEventsSection";

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export default function IncentiveHomePage({}) 
{
  return (
    <>
      <IncentiveMenu
        title="Wszystkie ręce na pokład – integracja firmowa na otwartym morzu"
        descr="Jesteśmy Twoim partnerem w organizacji niezapomnianych rejsów firmowych. To nietuzinkowa integracja dla Twojego zespołu!"
        paddingBottom={166}
      />
      <Wrapper>
        <Intro />
        <CompanyCruiseSection />
        <CompanyChartersSection />
        <Regattas />
        <ManagementCurses />

        <SpecialEventsSection />

        <Newsletter top="75px" />
        <ContactSection top="75px" />
      </Wrapper>
      <Footer />
    </>
  );
}

import styled from "styled-components";
import Footer from "../../../../Components/Footer/Footer";
import IncentiveMenu from "../../../../Components/Menu/Factory/IncentiveMenu";
import Newsletter from "../../../../Components/Newsletter/Newsletter";
import ContactSection from "../../../../Components/ContactSection/ContactSection";
import IncentiveTopSection from "../Components/IncentiveTopSection";
import { Translations } from "./Lang";
import { useContext } from "react";
import { LangContext } from "../../../../App";
import List from "./List";

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;

  @media (max-width: 767px) {
    width: 100%;
  }
`

export default function CompanyCruisesPage ()
{
  const { lang } = useContext(LangContext);

  return (
    <>
      <IncentiveMenu
        title={Translations[lang].title}
      />
      <Wrapper>
        <IncentiveTopSection 
          descr={Translations[lang].descr}
        />
        <List />
        <Newsletter top="88px" />
        <ContactSection top="75px" />
      </Wrapper>
      <Footer />
    </>
  )
}
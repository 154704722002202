
export const Translations = {
  pl: {
    aboutUs: 'O NAS',
    blog: 'BLOG',
    companyData: 'DANE FIRMY:',
    contact: 'KONTAKT:',
    followUs: 'OBSERWUJ NAS:',
    privacy: 'POLITYKA PRYWATNOŚCI',
    rodo: 'RODO',
    tos: 'REGULAMIN',
    registerNumber: 'Wpis do Rejestru Organizatorów Turystyki nr 737'
  },
  en: {
    aboutUs: 'O NAS',
    blog: 'BLOG',
    companyData: 'DANE FIRMY:',
    contact: 'KONTAKT:',
    followUs: 'OBSERWUJ NAS:',
    privacy: 'POLITYKA PRYWATNOŚCI',
    rodo: 'RODO',
    tos: 'REGULAMIN',
    registerNumber: 'Wpis do Rejestru Organizatorów Turystyki nr 737'
  },
  de: {
    aboutUs: 'O NAS',
    blog: 'BLOG',
    companyData: 'DANE FIRMY:',
    contact: 'KONTAKT:',
    followUs: 'OBSERWUJ NAS:',
    privacy: 'POLITYKA PRYWATNOŚCI',
    rodo: 'RODO',
    tos: 'REGULAMIN',
    registerNumber: 'Wpis do Rejestru Organizatorów Turystyki nr 737'
  }
}
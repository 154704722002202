import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../../App"

const Wrapper = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-weight: 400;
  font-size: 16px;
  width: 361px;
  display: flex;
  flex-direction: column;
  color: #133b51;
  align-items: center;

  @media (max-width: 767px) {
    transform: scale(0.95);
  }
`

const Label = styled.div`
  background-color: #fff;
  align-self: flex-start;
  box-sizing: border-box;
  padding: 9px 15px;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #32c8d2;
  color: #32c8d2;
  text-transform: uppercase;
  width: fit-content;
  border-bottom: none;
  z-index: 9;
`

const Names = styled.div`
  margin-top: -1px;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 14px 15px;
  border-radius: 0px 10px 10px 10px;
  border: 1px solid #32c8d2;
  display: flex;
  justify-content: space-between;
`

const Days = styled.div`
  margin-top: 6px;
  width: 350px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 9px 10px;
  font-family: 'Gilroy-Light';
`

const DayWrapper = styled.div`
  width: 35px;
  height: 35px;
  border: 1px solid ${params => params.$border};
  background-color: ${params => params.$bg};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 16px;
`

const Day = function ({ type, number = '' })
{
  const colors = {
    0: { bg: '#ffffff', border: '#32c8d2' },
    1: { bg: '#73c4c9', border: '#32c8d2' },
    2: { bg: '#fa9e9e', border: '#fa9e9e' },
  }

  const colorScheme = colors[type];
  if (colorScheme == null) {
    return <></>
  }

  return (
    <DayWrapper $bg={colorScheme.bg} $border={colorScheme.border}>
      { type !== 3 && number }
    </DayWrapper>
  );
}

export default function Callendar ({ year, month, offset, days, availability })
{
  const { lang } = useContext(LangContext);

  const renderableDays = new Array(42).fill(0)
  for (let i = 0; i < days; i++) {
    renderableDays[i + offset] = availability[i];
  }

  const indexToDayNumber = function (idx)
  {
    const day = idx + 1 - offset;

    if (day <= 0) {
      return null;
    }

    if (day > days) {
      return null;
    }

    return day;
  }

  return (
    <Wrapper>
      <Label>
        {year} {Translations[lang].callendar.months[month - 1]}
      </Label>
      <Names>
        {Translations[lang].callendar.days.map((day, key) => (
          <div key={key}>
            {day}
          </div>
        ))}
      </Names>
      <Days>
        {renderableDays.map((availability, key) => (
          <Day type={availability} key={key} number={indexToDayNumber(key)} />
        ))}
      </Days>
    </Wrapper>
  )
}
import styled from "styled-components";
import Footer from "../../../../Components/Footer/Footer";
import ContactSection from "../../../../Components/ContactSection/ContactSection";
import YachtCharterMenu from "../../../../Components/Menu/Factory/YachtCharterMenu";

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  display: flex;
  flex-direction: column;
`

export default function YachtCharterPage ()
{
  return (
    <>
      <YachtCharterMenu />
      <Wrapper>
        <ContactSection top="50px" />
      </Wrapper>
      <Footer />
    </>
  )
}
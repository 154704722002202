import styled from "styled-components";
import Footer from "../../Components/Footer/Footer";
import LiteMenu from "../../Components/Menu/Factory/LiteMenu";
import { createContext, useEffect, useState } from "react";
import Filters from "./Filters";
import Models from "./Models";
import ShortDescription from "./ShortDescription";

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  display: flex;
  margin-top: 52px;
  justify-content: space-between;
  @media (max-width: 767px) {
    margin-top: 25px;
    width: 100%;
    flex-direction: column;
  }
`

const TopSectionMobile = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`

export const FilterModelsContext = createContext();

const parseQueryParams = function ()
{
  const filters = {
    page: 1,
    kind: [],
    company: null,
    model: null,
    minCabins: 0,
    maxCabins: 18,
    minLength: 0,
    maxLength: 60
  } 

  const searchParams = new URLSearchParams(window.location.search);
  for (const [name, value] of searchParams) {
    if (name === 'kind') {
      filters.kind = value.split(',').map(n => parseInt(n)).filter(n => !isNaN(n));
      continue;
    }

    if (!Object.keys(filters).includes(name)) {
      continue;
    }

    const cast = parseInt(value);
    filters[name] = isNaN(cast)
      ? null
      : cast;
  }

  return filters;
}

/**
 * Wyszukiwarka modeli yachtów
 */
export default function ModelsPage()
{
  const [filters, setFilters] = useState(null);

  /**
   * When user changes filters, update url get parameters
   * in history
   */
  useEffect(() => {
    if (filters === null) {
      return () => {};
    }

    const validFilters = Object.entries(filters)
      .filter(n => n[1] !== null && n[1]);

    const url = new URL(window.location);
    url.search = new URLSearchParams(validFilters).toString();
    window.history.pushState({}, '', url);
  }, [filters]);


  useEffect(() => {
    setFilters(parseQueryParams());
  }, []);

  if (filters === null) {
    return <></>;
  }

  return (
    <FilterModelsContext.Provider value={{filters, setFilters}}>
      <LiteMenu />
      <Wrapper>
        <TopSectionMobile>
          <ShortDescription />
        </TopSectionMobile>
        <Filters />
	      <Models />
      </Wrapper>
      <Footer />
    </FilterModelsContext.Provider>
  )
}
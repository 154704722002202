import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../../App"
import { useParams } from "react-router-dom"

const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 30px 0px 19px 0px;
  border-bottom: 1px solid #32c8d2;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: 767px) {
    display: block;
    width: 100%;
    padding: 25px 0px 20px 0px;
  }
`

const Image = styled.a`
  width: 250px;
  height: 170px;
  border-radius: 5px;
  background-image: url(${params => params.$src});
  background-size: cover;
  background-position: center center;

  @media (max-width: 767px) {
    display: block;
    width: 100%;
    height: 248px;
  }
`

const Content = styled.div`
  display: flex;
  height: 170px;
  gap: 17px;
  font-family: 'Gilroy-ExtraBold';
  font-weight: 400;
  color: #133b51;

  @media (max-width: 767px) {
    display: block;
    height: fit-content;
  }
`

const Button = styled.a`
  margin-top: 13px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 16px 12px;
  border-radius: 5px;
  background-color: #32c8d2;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  height: fit-content;
`

const Model = styled.a`
  text-decoration: none;
  font-size: 20px;
  color: #32c8d2;
  text-transform: uppercase;
  padding: 0px;

  @media (max-width: 767px) {
    margin-top: 12px;
  }
`

const YearAndName = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 14px;
  > span {
    color: #32c8d2;
    font-size: 20px;
  }
`

const Location = styled.div`
  display: flex;
  align-items: flex-end;
  font-family: 'Gilroy-Light';
  font-size: 14px;
  gap: 12px;
  > img {
    width: 24px;
  }
`

const Details = styled.div`
  > span {
    font-family: 'Gilroy-Light';
    margin-right: 9px; 
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 767px) {
    gap: 6px;
  }
`

export default function Yacht ({id, year, name, base, heads, wc, image, model, modelInfo})
{
  const { lang } = useContext(LangContext);
  const { slug } = useParams();

  const yachtPageUrl = `/katalog-jachtow/${slug}/${id}${window.location.search}`;

  return (
    <Wrapper>
      <Content>
        <Image $src={image} href={yachtPageUrl} />
        <Info>
          <Model href={yachtPageUrl}>
            {modelInfo.model}
          </Model>
          <YearAndName>
            {year} <span>|</span> {name}
          </YearAndName>
          <Location>
            <img src="/ico/pointer.svg" /> {base}
          </Location>
          <div>
            <Details>
              {Translations[lang].yachts.result.heads}: <span>{heads ?? '-'}</span>
              {Translations[lang].yachts.result.cabins}: <span>{modelInfo.cabins ?? '-'}</span>
              {Translations[lang].yachts.result.wc}: <span>{wc ?? '-'}</span>
            </Details>
            <Details>
              {Translations[lang].yachts.result.len}: <span>{(modelInfo.length / 100).toFixed(2)} m</span>
              {Translations[lang].yachts.result.waterTank}: <span>{null}</span>
            </Details>
          </div>
        </Info>
      </Content>
      <Button href={yachtPageUrl}>
        {Translations[lang].yachts.result.details}
      </Button>
    </Wrapper>
  )
}
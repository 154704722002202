
export const Translations = {
  pl: {
    title: "Eventy Specjalne",
    descr: <><b>Imprezy bazujące na żeglarstwie to nasza specjalność</b> i w nich czujemy sie jak ryba w wodzie. Jednak to nie jedyna nasza dzialalność. W zimie zamieniamy wierzchołki fal na szczyty gór organizując wyjazdy narciarskie oraz zawody dla grup nawet 450 osobowych lub safari w Laponii. 
      <br/><br/>
      Wiosną i latem wykorzystujemy nasze umiejętności organizując pikniki, spartakiady sportowe oraz konferencje.
    </>,
    duration: "CZAS TRAWANIA",
    people: "ILOŚĆ OSÓB",
    transport: "TRANSPORT",

    events: [
      {
        title: "SKI INCENTIVE",
        duration: "2-7 dni",
        people: "dowolna",
        transport: "autokar, samolot, dojazd własny",
        descr: "Organizujemy wyjazdy dla firm (ski incentive)na narty oraz snowboard. Posiadamy własną Szkołę Narciarstwa i Snowboardu Forsail Ski Club na Górce Szczęśliwickiej w Warszawie oraz kadrę licencjonowanych instruktorów narciarstwa i snowboardu.",
        list: [
          "Dowolna ilość uczestników.",
          "Kompleksowa organizacja całego wyjazdu.",
          "Wynajem sprzętu narciarskiego i snowboardowego.",
          "Najlepsza kadra instruktorów narciarskich i snowboardowych..",
          "Własna szkoła narciarska na Górce Szczęśliwickiej.",
          "Organizacja zawodów narciarskich.",
          "Gala rozdania nagród.",
          "Oprawa fotograficzna i filmowa."
        ]
      },
      {
        title: "ARKTYCZNE SAFARI W JAPONI",
        duration: "3-7dni",
        people: "dowolna",
        transport: "samolot",
        descr: "Magiczny wyjazd do Laponii – krainy Świętego Mikołaja, zorzy polarnej i reniferów. Na uczestników czekają następujące atrakcje:",
        list: [
          "Safari na skuterach śnieżnych.",
          "Rejs lodołamaczem.",
          "Kąpiel w kombinezonach termicznych w lodowatych wodach Zatoki Botnickiej.",
          "Nocleg w hotelu lodowym.",
          "Nocleg w igloo.",
          "Jazda psimi zaprzęgami.",
          "Wizyta na farmie reniferów, nauka jazdy saniami z reniferem.",
          "Nocna pogoń na skuterach za zorzą polarną.",
          "Wspinaczka po lodospadzie.",
          "Wyścigi gokartów na lodzie.",
          "Sauna fińska.",
        ]
      },
      {
        title: "SPARTAKIDY SPORTOWE",
        duration: "2-3 dni",
        people: "dowolna",
        transport: "autokar, dojazd własny",
        descr: "",
        list: [
          ""
        ]
      },
      {
        title: "PIKNIKI",
        duration: "2-3 dni",
        people: "dowolna",
        transport: "autokar, dojazd własny",
        descr: "",
        list: [
          ""
        ]
      },
      {
        title: "Konferencje firmowe",
        duration: "1-2 dni",
        people: "dowolna",
        transport: "autokar, dojazd własny",
        descr: "",
        list: [
          ""
        ]
      },
      {
        title: "EVENTY ONLINE I HYBRYDOWE",
        duration: "dowolny",
        people: "dowolna",
        transport: "autokar, dojazd własny",
        descr: "",
        list: [
          ""
        ]
      },
    ]
  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;
import styled from "styled-components";
import {Translations} from "./Lang";
import {useContext} from "react";
import {LangContext} from "../../App";

const Wrapper = styled.div`
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	
	background-image: url(/skippers/hero-bg.webp);
	background-size: cover;
	background-position: top center;
	box-sizing: border-box;
	padding: 40px 64px 125px 64px;
	border-radius: 10px;
	
	font-family: 'Gilroy-Light';
	font-weight: 400;
	color: #133b51;
	align-items: center;
	text-align: center;

	@media (max-width: 767px) {
		margin-top: 0px;
		padding: 23px 23px 59px 23px;
		border-radius: 0px;
	}
`

const Title = styled.div`
	font-size: 32px;
	> span {
		font-family: 'Gilroy-ExtraBold';
	}

	@media (max-width: 767px) {
		font-size: 24px;
	}
`

const Descr = styled.div`
	margin-top: 20px;
	width: 710px;
	font-size: 20px;

	@media (max-width: 767px) {
		width: 100%;
		font-size: 16px;
	}
`

export default function Top ()
{
	const { lang } = useContext(LangContext);

	return (
		<Wrapper>
			<Title>
				{Translations[lang].top.title}
			</Title>
			<Descr>
				{Translations[lang].top.descr}
			</Descr>

		</Wrapper>
	)
}
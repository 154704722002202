
export const Translations = {
  pl: {
    yachtKind: "Rodzaj jachtu",
    startBase: "Baza startowa",
    endBase: "Baza końcowa",
    obligatoryPayment: "Opłaty obowiązkowe",
    yachtPrice: "Cena jachtu",
    charterPrice: "Pakiet czarterowy",
    sudoPayments: "Opłaty administracyjne",
    securityDeposit: "Kaucja",
    commission: "Prowizja",
    dateFrom: "Data od",
    dateTo: "Data do",
    discount: "Rabat",
    price: "Cena",
    bookingButton: "Zarezerwuj"
  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;
/**
 * Ciekawostki/Aktualności
 */

import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext, useEffect, useState } from "react"
import { LangContext } from "../../App"
import __server, { __gcloud } from "../../Api"
import axios from "axios"
import { FilterTag, filterList } from "../../Pages/Blog/List/Filters"
import { convDate } from "../../Pages/Blog/List/List"

const BlogTop3Wrapper = styled.div`
  width: 1320px;
  margin-top: ${props => props.$top};

  @media (max-width: 767px) {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 15px;
    margin-top: 80px;
  }
`

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.div`
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 32px;
  color: #133b51;

  > span {
    font-family: 'Gilroy-ExtraBold';
  }

  @media (max-width: 767px) {
    font-size: 24px;
  }
`

const ReadMore = styled.a`
  text-decoration: none;
  border-radius: 10px;
  border: 2px solid #133b51;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #133b51;
  box-sizing: border-box;
  padding: 15px 43px;
  background-color: transparent;
  cursor: pointer;

  @media (max-width: 767px) {
    display: none;
  }
`

const BlogsList = styled.div`
  margin-top: 34px;
  display: flex;
  gap: 30px;

  @media (max-width: 767px) {
    margin-top: 25px;
    flex-direction: column;
  }
`

const Blog = styled.a`
  width: 100%;
  text-decoration: none;
`

const BlogImage = styled.div`
  height: 240px;
  background-image: url(${__gcloud}/${props => props.$bg});
  background-size: cover;
  backgrund-position: center center;
  border-radius: 10px;
  display: flex;
  box-sizing: border-box;
  padding-top: 23px;

  @media (max-width: 767px) {
    height: 223px;
  }
`

const Date = styled.div`
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 16px;
  color: #133b51;
  margin-top: 12px;
`

const BlogTitle = styled.div`
  margin-top: 10px;
  font-family: 'Gilroy-ExtraBold';
  font-weight: 400;
  font-size: 20px;
  color: #133b51;
`

const BlogExcerpt = styled.div`
  margin-top: 10px;
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 16px;
  color: #133b51;
`

export default function BlogTop3 ({ top })
{
  const {lang, setLang} = useContext(LangContext);
  const [list, setList] = useState([]);

  useEffect(() => {
    axios.get(`${__server}/front/blog?page=1`).then((resp) => {
      setList(resp.data.data.splice(0, 3));
    }).catch((err) => {
      console.error('[blogTop3 fail]', err);
    })
  }, []);


  return (
    <BlogTop3Wrapper $top={top}>
      <TopBar>
        <Title>
          {Translations[lang].title}
        </Title>
        <ReadMore href="/blog">
          {Translations[lang].showMore}
        </ReadMore>
      </TopBar>
      <BlogsList>
        {list.map((blog, key) => (
          <Blog key={key} href={`/blog/${blog.slug}`}>
            <BlogImage $bg={blog.image}>
              <FilterTag color={filterList.find(n => n.tag == blog.category).color}>{blog.category}</FilterTag>
            </BlogImage>
            <Date>
              {convDate(blog.created)}
            </Date>
            <BlogTitle>
              {blog.title}
            </BlogTitle>
            <BlogExcerpt>
              {blog.excerpt}
            </BlogExcerpt>
          </Blog>
        ))}
      </BlogsList>
    </BlogTop3Wrapper>
  )
}
import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../../App"


const Wrapper = styled.div`
  margin-top: 70px;
  border: 1px solid #32c8d2;
  box-shadow: 4px 4px 30px 0px rgba(170, 170, 170, 0.15);
  background-color: #f8f8f8;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 26px 20px;
  font-weight: 400;
  color: #133b51;
`

const Title = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 20px;
`

const Descr = styled.div`
  margin-top: 14px;
  font-family: 'Gilroy-Light';
  font-size: 16px;
`


export default function GoodToKnow ()
{
  const { lang } = useContext(LangContext);

  return (
    <Wrapper>
      <Title>
        {Translations[lang].goodToKnow.title}
      </Title>
      <Descr>
        {Translations[lang].goodToKnow.descr}
      </Descr>
    </Wrapper>
  )
}
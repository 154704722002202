
import { styled } from 'styled-components';

const LinkWrapper = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: ${params => params.$isDisplayed ? 700 : 300};
`

export const Link = function ({ href, children })
{
  const isCurrentlyDisplayed = window.location.pathname === href;
  return (
    <LinkWrapper $isDisplayed={isCurrentlyDisplayed} href={href}>
      {children}
    </LinkWrapper>
  )
}

export const Title = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 48px;
  font-weight: 400;
  line-height: 49px;
  color: #fff;
  box-sizing: border-box;
  padding-left: 28px;

  @media (max-width: 767px) {
    font-size: 32px;
    width: 285px;
    line-height: 35px;
    padding-left: 0px;
  }
`

export const Subtitle = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  box-sizing: border-box;
  padding-left: 28px;
  margin-bottom: 10px;

  @media (max-width: 767px) {
    font-size: 16px;
    padding-left: 0px;
  }
`;

export const Descr = styled.div`
  margin-top: 10px;
  font-family: 'Gilroy-Light';
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  box-sizing: border-box;
  padding-left: 28px;
  width: 450px;

  @media (max-width: 767px) {
    margin-top: 4px;
    font-size: 14px;
    width: 340px;
    padding-left: 0px;
  }
`;
/**
 * Strona pojedyńczego bloga/wpisu
 */

import { useParams } from "react-router-dom"
import ContactSection from "../../../Components/ContactSection/ContactSection";
import Footer from "../../../Components/Footer/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import __server, { __gcloud } from "../../../Api";

import { FilterTag, filterList } from "../List/Filters";
import styled from "styled-components";
import { IconBlock, Icons, convDate } from "../List/List";
import Newsletter from "../../../Components/Newsletter/Newsletter";
import BlogTop3 from "../../../Components/Blog/BlogTop3";
import PageMenu from "../../../Components/Menu/Factory/PageMenu";
import BlogMenu from "../../../Components/Menu/Factory/BlogMenu";

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  padding-top: 22px;
  box-sizing: border-box;

  @media (max-width: 767px) {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const FlexBox = styled.div`
  display: flex;
  margin-bottom: 21px;

  @media (max-width: 767px) {
    margin-top: 15px;
  }
`

const BlogContent = styled.div`
  padding-top: 35px;
  box-sizing: border-box;
  width: 1320px;
  margin: auto;
  font-family: 'Gilroy';
  color: #133b51;

  p, li {
    font-family: 'Gilroy-Light';
    font-size: 20px;
    line-height: 36px;
    margin-block-start: 0px;
    margin-block-end: 0px;
  }

  h1 {
    font-family: 'Gilroy-ExtraBold';
    font-size: 32px;
    font-weight: 400;
  }

  p strong {
    font-family: 'Gilroy-ExtraBold';
    font-weight: 400;
  }

  @media (max-width: 767px) {
    margin-top: 30px;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 15px;
    
    p, li {
      font-size: 16px;
      line-height: 25px;
    }

    h1 {
      font-size: 24px;
    }
  }

`

export default function BlogSinglePage ({})
{
  const { slug } = useParams();

  const [blog, setBlog] = useState(null);

  useEffect(() => {
    axios.get(`${__server}/front/blog/${slug}`).then((resp) => {
      setBlog(resp.data);
      // document.querySelector('#blog-content').innerHTML = resp.data.content;
    }).catch((err) => {
      console.log('failed to download blog article', err);
      window.location.href = '/404';
    })
  }, []);

  return (
    <>
      <BlogMenu title={blog?.title ?? ''} backgroundImage={`${__gcloud}/${blog?.image}`}/>
      {blog && (
        <>
          <Wrapper>
            <FlexBox>
              <FilterTag color={filterList.find(n => n.tag == blog.category).color}>
                {blog.category}
              </FilterTag>
            </FlexBox>
            <Icons>
              <IconBlock>
                <img src="/ico/calendar.svg" width="19" />
                {convDate(blog.created)}
              </IconBlock>
              <IconBlock>
                <img src="/ico/time.svg" width="19" />
                {blog.reading_time} min czytania
              </IconBlock>
            </Icons>
          </Wrapper>
          <BlogContent dangerouslySetInnerHTML={{ __html: blog.content }} />
        </>
      )}
      <Wrapper>
        <Newsletter/>
        <BlogTop3 top="90px"/>
      </Wrapper>
      <ContactSection top="75px" />
      <Footer/>
    </>
  )
}
import axios from "axios";
import { useContext, useEffect, useState } from "react"
import __server, { __gcloud } from "../../../Api";
import { FilterContext } from "./BlogPage";
import styled from "styled-components";
import { FilterTag, filterList } from "./Filters";
import { ScaleLoader } from "react-spinners";

const ListWraper = styled.div`
  margin-top: 32px;

  @media (max-width: 767px) {
    margin-top: 19px;
    display: flex;
    flex-direction: column;
    gap: 33px;
  }
`

const ListItem = styled.div`
  padding: 30px 52px;
  box-sizing: border-box;
  display: flex;
  gap: 37px;
  width: 100%;

  @media (max-width: 767px) {
    padding: 0px 15px;
    flex-direction: column-reverse;
    gap: 14px;
  }
`

const ListSeparator = styled.div`
  width: 1320px;
  height: 1px;
  background-color: #133851;
  filter: drop-shadow(4px 4px 6.8px rgba(0, 0, 0, 0.5));
  opacity: 0.5;

  @media (max-width: 767px) {
    display: none;
  }
`

const ListImage = styled.div`
  width: 394px;
  height: 243px;
  border-radius: 10px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${__gcloud}/${props => props.$bg});
  display: flex;
  padding-top: 23px;
  box-sizing: border-box;
  cursor: pointer;

  @media (max-width: 767px) {
    width: 100%;
    height: 223px;
    order: 1;
  }
`

const ListContent = styled.div`
  width: calc(100% - 394px - 37px);
  @media (max-width: 767px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`

const Title = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 20px;
  font-weight: 400;
  color: #133b51;
  margin-top: 4px;
  cursor: pointer;

  @media (max-width: 767px) {
    order: 3;
    margin-top: 10px;
  }
`

export const Icons = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  margin-top: 9px;

  @media (max-width: 767px) {
    order: 2;
    margin-top: 0px;
  }
`

export const IconBlock = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-family: 'Gilroy';
  font-size: 16px;
  font-weight: 300;
  color: #133b51;
`

const Excerpt = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 16px;
  font-weight: 400;
  color: #000;
  margin-top: 20px;
  height: 115px;
  overflow: hidden;

  @media (max-width: 767px) {
    order: 4;
    margin-top: 0px;
    height: fit-content;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;

  @media (max-width: 767px) {
    order: 5;
    flex-direction: row;
    margin-top: 23px;
  }
`


const ReadMoreButton = styled.button`
  background-color: transparent;
  border: 1px solid #133851;
  border-radius: 10px;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  color: #133851;
  padding: 10px 32px;
  align-self: flex-end;
`;

const LoadMoreSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`

const LoadMoreButton = styled.button`
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #133b51;
  box-sizing: border-box;
  padding: 15px 29px;
  border: 2px solid #133b51;
  border-radius: 10px;
  background-color: transparent;
  outline: none;
`


export const convDate = function (dt)
{
  dt = new Date(dt);

  const months = [
    "STYCZEŃ", "LUTY", "MARZEC", "KWIECIEŃ", "MAJ", "CZERWIEC",
    "LIPIEC", "SIERPIEŃ", "WRZESIEŃ", "PAŹDZIERNIK", "LISTOPAD", "GRUDZIEŃ"
  ];

  return `${months[dt.getMonth()]} ${dt.getDate()}, ${dt.getFullYear()}`;
}

export default function List ({}) 
{
  const {showTags, setShowTags} = useContext(FilterContext);
  const [list, setList] = useState([]);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchList([], 1);
  }, [showTags]);

  const fetchList = function (oldList, pageNum)
  {
    setLoading(true);
    const filters = showTags.map((tag) => `category[]=${tag}`).join('&')
    axios.get(`${__server}/front/blog?page=${pageNum}&${filters}`).then((resp) => {
      setPage(pageNum);
      setHasMore(pageNum < parseInt(Math.ceil(resp.data.total / resp.data.per_page)));
      setList([...oldList, ...resp.data.data]);
      setLoading(false);

    }).catch((err) => {
      console.log('failed to fetch blog list', err);
    });
  }


  return (
    <ListWraper>
      {list.map((blog, key) => 
        <div key={key}>
          <ListItem onClick={(e) => {
            window.location.href = `/blog/${blog.slug}`;
          }}>
            <ListImage $bg={blog.image}>
              <FilterTag color={filterList.find(n => n.tag == blog.category).color}>
                #{blog.category}
              </FilterTag>
            </ListImage>
            <ListContent>
              <Title>{blog.title}</Title>
              <Icons>
                <IconBlock>
                  <img src="/ico/calendar.svg" width="19" />
                  {convDate(blog.created)}
                </IconBlock>
                <IconBlock>
                  <img src="/ico/time.svg" width="19" />
                  {blog.reading_time} min czytania
                </IconBlock>
              </Icons>
              <Excerpt>
                {blog.excerpt}
              </Excerpt>
              <ButtonWrapper>
                <ReadMoreButton>
                  Czytaj więcej
                  <img src="/ico/arrow-right-btn.svg" />
                </ReadMoreButton>
              </ButtonWrapper>
            </ListContent>
          </ListItem>
          <ListSeparator/>
        </div>
      )}
      <LoadMoreSection>
        {loading ? (<ScaleLoader color="#133b51" />) : 
          (hasMore && (
            <LoadMoreButton onClick={(e) => {
              fetchList(list, page + 1);
            }}>
              doładuj
            </LoadMoreButton>
          ))
        }
      </LoadMoreSection>
    </ListWraper>
  )
}
import styled from "styled-components"
import { motion } from "framer-motion"
import { useContext, useState } from "react"
import { ThemeContext } from "../../../App"



const Wrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 150px;
  background-color: #fff;
  border-radius: 34px 0px 0px 34px;
  box-sizing: border-box;
  padding: 12px 10px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 767px) {
    display: none;
  }
`

const Item = styled.a`
  > img {
    width: 54px;
    height: 42px;
  }
`


export default function MenuSlider ({ /** UNUSED: */ top = 270 })
{
  const sliderItems = [
    {url: '/incentive', img: 'incentive'},
    {url: '/academy', img: 'academy'},
    {url: '/yacht-charter', img: 'yacht-charter'},
    {url: '/camp', img: 'camp'},
  ];

  const [shown, setShown] = useState(false);
  const theme = useContext(ThemeContext);

  const itemsSortingOrder = function (a, theme)
  {
    return theme.name === 'main' && a.img === 'yacht-charter' 
      ? -10
      : a.img !== theme.name
        ? 1
        : -1;
  }


  return (
    <Wrapper>
      {sliderItems.sort((a, b) => itemsSortingOrder(a, theme)).map((item, key) => (
        <Item href={item.url} key={key}>
          <img src={`/menu/${item.img}.svg`} />
        </Item>
      ))}
    </Wrapper>
  )
}

export const Translations = {
  pl: {
    signin: 'Zaloguj się',
    signup: 'Rejestracja',

    mail: 'Adres e-mail',
    passw: 'Hasło',

    login: {
      descr: 'Zaloguj się do platformy kursowej aby uzyskać dostęp',
      btn: 'Zaloguj się',
      forgotPassw: 'Nie pamiętam hasła',
      errors: {
        401: "Adres email lub hasło są niepoprawne",
        400: "Bad request",
        423: "Należy potwierdzić adres email",
        403: "Konto zostało zablokowane"
      }

    },

    register: {
      descr: 'Utwórz konto do platformy kursowej aby uzyskać dostęp',
      btn: 'Załóż Konto',
      btnNext: 'Dalej',

      name: 'Imie',
      surname: 'Nazwisko',
      tel: 'Numer telefonu',
      street: 'Ulica',
      house: 'Nr Domu',
      postal: 'Kod pocztowy',
      city: 'Miasto',
      country: 'Wybierz kraj',
      state: 'Wybierz województwo',
      repeat: 'Powtórz Hasło',
      tos: 'Wyrażam zgodę na otrzymywanie wiadomości marketingowychi potwierdzam zapoznanie się z Polityką Prywatności',
      errors: {
        emptyField: 'Wszystkie pola muszą być wypełnione',
        409: 'Użytkownik już istnieje'
      }
    },
    reset: {
      descr: 'Zresetuj hasło do platformy kursowej aby uzyskać dostęp',
      btn: 'Wyślij Kod',
      done: 'Na podany adres email został wysłany link resetujący'
    },
    activate: {
      descr: 'Aktywuj konto do platformy kursowej aby uzyskać dostęp',
      text: 'Na podany adres e-mail został wysłany link aktywacyjny',
      btn: 'Wyślij ponownie',
      errors: {
        404: "Użytkownik nie istnieje",
        405: "Użytkownik już został aktywowany",
        403: "Konto zostało zablokowane"
      }
    }
  }
}


Translations.en = Translations.pl;
Translations.de = Translations.pl;
import { useContext } from "react";
import { LangContext } from "../../App";
import LiteMenu from "../../Components/Menu/Factory/LiteMenu";
import styled from "styled-components";
import Footer from "../../Components/Footer/Footer";
import { Translations } from "./Lang";

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Oops = styled.img`
  margin-top: 115px;
  width: 404px;
`

const Title = styled.div`
  margin-top: 25px;
  font-family: 'Gilroy-ExtraBold';
  font-size: 32px;
  font-weight: 400;
  color: #133b51;
  text-align: center;
`

const Descr = styled.div`
  width: 560px;
  margin-top: 7px;
  font-family: 'Gilroy-Light';
  font-size: 24px;
  font-weight: 400;
  color: #133b51;
  text-align: center;
`

const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 22px;
  text-decoration: none;
  box-sizing: border-box;
  font-family: 'Manrope';
  padding: 11px 14px;
  color: #fff;
  background-color: #133b51;
  border-radius: 5px;
  font-weight: 700;
`

export default function NotFoundPage ({})
{
  const { lang } = useContext(LangContext);

  return (
    <>
      <LiteMenu />
      <Wrapper>
        <Oops src="/oops.webp" />
        <Title>
          {Translations[lang].title}
        </Title>
        <Descr>
          {Translations[lang].descr}
        </Descr>
        <Button href="/">
          {Translations[lang].btn}
        </Button>
      </Wrapper>
      <Footer />
    </>
  )
} 
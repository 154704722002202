import { useContext } from "react"
import styled from "styled-components"
import { LangContext } from "../../App"
import { Translations } from "./Lang"

const Wrapper = styled.div`
  @media (max-width: 767px) {
    box-sizing: border-box;
    padding: 15px;
  }
`

const Outter = styled.footer`
  width: 1320px;
  margin: auto;
  background-image: url(/footer/bg.webp);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: 63px;
  border-radius: 10px;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Inner = styled.div`
  background-color: rgba(20, 58, 81, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding-bottom: 28px;
`

const Logo = styled.img`
  margin-top: 46px;
  width: 225px;
  cursor: pointer;

  @media (max-width: 767px) {
    margin-top: 32px;
  }
`

const Nav = styled.div`
  margin-top: 39px;
  display: flex;
  gap: 20px;
  font-size: 16px;
  color: #fff;
  font-family: 'Gilroy-ExtraBold';
  font-weight: 400;
  text-transform: uppercase;

  @media (max-width: 767px) {
    margin-top: 26px;
    flex-direction: column;
    text-align: center;
    gap: 8px;
  }
`

const BottomNav = styled.div`
  margin-top: 39px;
  display: flex;
  gap: 20px;
  font-size: 16px;
  color: #fff;
  font-family: 'Gilroy-ExtraBold';
  font-weight: 400;
  text-transform: uppercase;

  @media (max-width: 767px) {
    box-sizing: border-box;
    padding: 0px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`

const NavLink = styled.a`
  text-decoration: none;
  color: #fff;
`

const BottomNavLink = styled.a`
  text-decoration: none;
  color: #fff;
  @media (max-width: 767px) {
    font-size: 12px;
  }
`

const Boxes = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 43px;

  @media (max-width: 767px) {
    justify-content: center;
    align-items: flex-start;
    margin-top: 26px;
    flex-wrap: wrap;
    gap: 30px 10px;
    box-sizing: border-box;
    padding: 15px;
  }
`

const Section = styled.div`
  display: flex;
  gap: 18px;
  font-size: 16px;
  color: #fff;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const Label = styled.div`
  font-family: 'Gilroy-ExtraBold';
`

const Content = styled.div`
  font-family: 'Gilroy-Light';
`

const Row = styled.a`
  text-decoration: none;
  color: #fff;
  display: flex;
  gap: 10px;

  @media (max-width: 767px) {
    width: 160px;
  }
`

const Copyrights = styled.div`
  padding: 30px 0px;
  text-align: center;
  font-family: 'Gilroy-Light';
  font-size: 16px;
  color: #133b51;

  > a {
    text-decoration: none;
    color: inherit;
  }

  @media (max-width: 767px) {
    padding: 20px 0px;
  }
`

export default function Footer ({})
{
  const { lang, setLang } = useContext(LangContext);

  return (
    <Wrapper>
      <Outter>
        <Inner>
          <Logo src="/footer/logo.svg" />
          <Nav>
            <NavLink href="/incentive">Incentive</NavLink>
            <NavLink href="/academy">Academy</NavLink>
            <NavLink href="/camp">Camp</NavLink>
            <NavLink href="/yacht-charter">Yacht Charter</NavLink>
            <NavLink href="/o-nas">{Translations[lang].aboutUs}</NavLink>
            <NavLink href="/blog">{Translations[lang].blog}</NavLink>
          </Nav>
          <Boxes>
            <Section>
              <Label>
                {Translations[lang].companyData}
              </Label>
              <Content>
                <Row>
                  FORSAIL SP.J.
                </Row>
                <Row>
                  NIP 5272452434
                </Row>
                <Row>
                  <div style={{width: '238px'}}>
                    {Translations[lang].registerNumber}
                  </div>
                </Row>
              </Content>
            </Section>
            <Section>
              <Label>
                {Translations[lang].contact}
              </Label>
              <Content>
                <Row href="tel:+48668678019">
                  <img src="/ico/phone.svg" width="16px" />
                    +48 668 678 019
                </Row>
                <Row href="tel:+48887234362">
                  <img src="/ico/phone.svg" width="16px" />
                  +48 887 234 362
                </Row>
                <Row href="mailto:biuro@forsail.pl">
                  <img src="/ico/envelope.svg" width="16px" />
                  biuro@forsail.pl
                </Row>
                <Row href="mailto:forsail@incentive.pl">
                  <img src="/ico/pointer2.svg" width="16px" />
                  ul. Ciołka 13, <br/>
                  01-445 Warszawa
                </Row>
              </Content>
            </Section>
            <Section>
              <Label>
                {Translations[lang].followUs}
              </Label>
              <Content>
                <Row>
                  <img src="/ico/facebook.svg" height="15px" />
                  Forsail
                </Row>
                <Row>
                  <img src="/ico/instagram.svg" width="13px" />
                  @forsail_incentive
                </Row>
                <Row>
                  <img src="/ico/youtube.svg" width="14px" />
                  forsail_incentive
                </Row>
                <Row>
                  <img src="/ico/youtube.svg" width="14px" />
                  forsail_camp
                </Row>
              </Content>
            </Section>
          </Boxes>
          <BottomNav>
            <BottomNavLink href="/">{Translations[lang].privacy}</BottomNavLink> |
            <BottomNavLink href="/">{Translations[lang].rodo}</BottomNavLink> |
            <BottomNavLink href="/">{Translations[lang].tos}</BottomNavLink>
          </BottomNav>
        </Inner>
      </Outter>
      <Copyrights>
        Copyright &copy; 2024 Powered by <a href="https://repulse.pl/" target="_blank">Repulse</a>
      </Copyrights>
    </Wrapper>
  )
}
import { useContext } from "react";
import Footer from "../../../../Components/Footer/Footer";
import IncentiveMenu from "../../../../Components/Menu/Factory/IncentiveMenu";
import { LangContext } from "../../../../App";
import { Translations } from "./Lang";
import styled from "styled-components";
import IncentiveTopSection from "../Components/IncentiveTopSection";
import Card from "./Card";
import Newsletter from "../../../../Components/Newsletter/Newsletter";
import ContactSection from "../../../../Components/ContactSection/ContactSection";

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;

  @media (max-width: 767px) {
    margin-top: 17px;
    width: 100%;
  }
`

const Row = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 30px;

  @media (max-width: 767px) {
    margin-top: 27px;
    flex-direction: column;
    gap: 27px;
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

export default function ManagementCursesPage ()
{
  const { lang } = useContext(LangContext);

  const topLeft = Translations[lang].cardTopLeft;
  const topRight = Translations[lang].cardTopRight;
  const midLeft = Translations[lang].cardMiddleLeft;
  const midRight = Translations[lang].cardMiddleRight;
  const bot = Translations[lang].cardBottom;

  return (
    <>
      <IncentiveMenu 
        title={Translations[lang].title}
      />
      <Wrapper>
        <IncentiveTopSection 
          descr={Translations[lang].descr}
        />

        <Row>
          <Card title={topLeft.title} content={topLeft.contents}/>
          <Card title={topRight.title} content={topRight.contents}/>
        </Row>

        <Row>
          <Card title={midLeft.title} content={midLeft.contents}/>
          <Card title={midRight.title} content={midRight.contents}/>
        </Row>

        <Row>
          <Card title={bot.title} content={bot.contents}/>
        </Row>

        <Newsletter top="72px" />
        <ContactSection top="75px" />
      </Wrapper>
      <Footer />
    </>
  )
}
import styled from "styled-components";
import { Translations } from "./Lang";
import { useContext } from "react";
import { LangContext } from "../../App";
import { slugify } from "../../utils/slugger";

const Wrapper = styled.a`
  text-decoration: none;
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.15);
`

const Image = styled.div`
  width: 100%;
  height: 164px;
  border-radius: 10px 10px 0px 0px;
  background-image: url(${params => params.$src});
  background-size: cover;
  background-position: center center;
`

const Content = styled.div`
  box-sizing: border-box;
  padding: 15px 16px;
  color: #133b51;
  font-weight: 400;
  font-family: 'Gilroy-ExtraBold';
`

const Title = styled.div`
  font-size: 20px;
  color: #32c8d2;
  text-transform: uppercase;
  height: 25px;
  overflow: hidden;
`

const Props = styled.div`
  margin-top: 7px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`

const Prop = styled.div`
  font-size: 14px;
  > span {
    display: block;
    margin-top: 5px;
    font-family: 'Gilroy-Light';
  }
`

const Button = styled.button`
  outline: none;
  border: none;
  margin-top: 13px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 0px;
  border-radius: 5px;
  background-color: #32c8d2;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  color: #fff;
`

const clipModelName = function (name)
{
  return name.length > 20
    ? name.substr(0, 20) + "..."
    : name;
}

const convertToMeters = function (length)
{
  return `${(parseInt(length) / 100).toFixed(2)}m`;
}

export default function Model({ id, model, minYear, maxYear, cabins, length, image }) 
{
  const { lang } = useContext(LangContext);

  const YearTag = function ()
  {
    return minYear === maxYear
      ? <>{minYear}</>
      : <>{minYear}-{maxYear}</>
  }

  return (
    <Wrapper href={`/katalog-jachtow/${slugify(id, model)}${window.location.search}`}>
      <Image $src={image} />
      <Content>
        <Title>
          {clipModelName(model)}
        </Title>
        <Props>
          <Prop>
            {Translations[lang].result.year}: <span><YearTag /></span>
          </Prop>
          <Prop>
            {Translations[lang].result.cabins}: <span>{cabins}</span>
          </Prop>
          <Prop>
            {Translations[lang].result.len}: <span>{convertToMeters(length)}</span>
          </Prop>
        </Props>
        <Button>
          {Translations[lang].result.checkout}
        </Button>
      </Content>
    </Wrapper>
  )
}
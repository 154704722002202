
export const Translations = {
  pl: {
    title: "Czartery Dla Firm",
    descr: <>Ofertę naszą kierujemy do <b>Prezesów oraz właścicieli firm</b>, którzy poszukują ciekawych miejsc na spotkania biznesowe. Luksusowy jacht, jako miejsce na spotkanie biznesowe daje praktycznie nieograniczone możliwości. Mazury, Bałtyk, Chorwacja, Grecja, Karaiby, Seszele i wiele innych destynacji. Utrzymywanie odpowiednich relacji z klientami było i będzie zawsze najważniejszym celem każdego przedsiębiorstwa. Możliwość wykorzystania luksusowego jachtu łączy przyjemne z pożytecznym.</>,

    upperCard: {
      descr: <>Proponujemy Państwu <b>całoroczny czarter jachtu</b> na wyłączność Państwa firmy. W ramach opłaty rocznej mają Państwo do dyspozycji:</>,
      list: [
        "Luksusowy, 9 metrowy jacht śródlądowy, w pełni wyposażony, port stacjonowania – Mazury", 
        "Luksusowy jacht jednokadłubowy lub katamaran w Chorwacji, Grecji lub na Karaibach", 
        "Pełny serwis jachtu: ubezpieczenie OC+AC, paliwo, sprzątanie, naprawy"
      ]
    },
    lowerCard: {
      descr: <>Dodatkowo oferujemy <b>kompleksową organizację</b> każdego wyjazdu, w tym m.in.:</>,
      list: [
        "Obsługę sternika",
        "Transport na miejsce spotkania dla Państwa i Państwa klientów", 
        "Podstawienie samolotów biznesowych od 2-14 miejsc na najbliższe lotnisko",
        "Zakup biletów lotniczych, rezerwację hoteli",
        "Zaprowiantowanie jachtów",
        "Możliwość obrandowania jachtu logo firmy"
      ]
    },

  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;

import { useContext } from "react";
import { LangContext } from "../../App";
import { Translations } from "./Lang";
import ContactSection from "../../Components/ContactSection/ContactSection";
import styled from "styled-components";
import Newsletter from "../../Components/Newsletter/Newsletter";
import BlogTop3 from "../../Components/Blog/BlogTop3";
import Footer from "../../Components/Footer/Footer";

import PhotoAlbum from "react-photo-album";
import PageMenu from "../../Components/Menu/Factory/PageMenu";
import Gallery from "../../Components/Gallery/Gallery";

const NewsletterRow = styled.div`
  width: 1320px;
  margin: auto;
  display: flex;
  gap: 121px;
  margin-top: 59px;
  justify-content: space-between;

	@media (max-width: 767px) {
		width: 100%;
		flex-direction: column;
		gap: 30px;
	}
`

const ContactDetails = styled.div`
  width: 310px;

	@media (max-width: 767px) {
		width: 100%;
		box-sizing: border-box;
		padding: 0px 15px;
	}
`

const DetailsTitle = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-weight: 400;
  font-size: 32px;
  color: #133b51;

	@media (max-width: 767px) {
		font-size: 24px;
	}
`

const Details = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Detail = styled.a`
  text-decoration: none;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  
	@media (max-width: 767px) {
		gap: 12px;
	}
`

const DetailIcon = styled.img`
  margin-top: 7px;
  width: 27px;

	@media (max-width: 767px) {
		width: 22px;
	}
`

const DetailText = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 32px;
  font-weight: 400;
  color: #133b54;

	@media (max-width: 767px) {
		font-size: 24px;
	}
`

const Wrapper = styled.div`
	width: 1320px;
	margin: auto;

	@media (max-width: 767px) {
		width: 100%; 
	}
`

export default function ContactPage ({})
{
  const { lang } = useContext(LangContext);

  const contactDetails = [
    {icon: '/ico/phone-blue.svg', text: '+48 668 678 019', href: 'tel:+48668678019'},
    {icon: '/ico/phone-blue.svg', text: '+48 887 234 362', href: 'tel:+48887234362'},
    {icon: '/ico/envelope-blue.svg', text: 'biuro@forsail.pl', href: 'mailto:biruo@forsail.pl'},
    {icon: '/ico/pointer3.svg', text: <>ul.Ciołka 13,<br/>01-445 Warszawa</>, href: ''}
  ];

  return (
    <>
      <PageMenu title="Kontakt" />
      <ContactSection top="40px" />
      <NewsletterRow>
        <ContactDetails>
          <DetailsTitle>
            {Translations[lang].contact}
            <Details>
            {contactDetails.map((detail, key) => (
              <Detail key={key} href={detail.href}>
                <DetailIcon src={detail.icon} />
                <DetailText>{detail.text}</DetailText>
              </Detail>
            ))}
            </Details>
          </DetailsTitle>
        </ContactDetails>
        <Newsletter padding="42px 52px" />
      </NewsletterRow>
      <Wrapper>
        <Gallery top={40} />
        <BlogTop3 top="72px" />
      </Wrapper>
      <Footer />
    </>
  )
}


const urlfy = function (str)
{
  str = str.replaceAll(' ', '-');
  str = str.replaceAll('_', '-');
  str = encodeURIComponent(str);
  return str;
}

export const slugify = function (id, name)
{
  return `${id}-${urlfy(name)}`.toLocaleLowerCase();
}

export const unslugify = function (slug)
{
  const toks = slug.split('-');
  return parseInt(toks.at(0));
}
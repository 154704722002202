import PageMenu from "../../Components/Menu/Factory/PageMenu";
import styled from "styled-components";
import {Translations} from "./Lang";
import {useContext} from "react";
import {LangContext} from "../../App";
import Top from "./Top";
import WhatIsSkipper from "./WhatIsSkipper";
import Footer from "../../Components/Footer/Footer";
import ContactSection from "../../Components/ContactSection/ContactSection";
import Newsletter from "../../Components/Newsletter/Newsletter";
import Preferences from "./Preferences";
import Pricing from "./Pricing";
import Faq from "./Faq";
import Gallery from "../../Components/Gallery/Gallery";

const Wrapper = styled.div`
	width: 1320px;
	margin: auto;
	
	display: flex;
	flex-direction: column;

	@media (max-width: 767px) {
		width: 100%;
	}
`

export default function SkippersPage ()
{
	const { lang } = useContext(LangContext);

	return (
		<>
			<PageMenu
				title={Translations[lang].title}
			/>
			<Wrapper>
				<Top />
				<WhatIsSkipper />
				<Preferences />
				<Pricing />
				<Faq />
				<Gallery top={0} />
				<ContactSection top="45px" />
			</Wrapper>
			<Footer />
		</>
	)
}
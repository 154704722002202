
import { useContext } from "react"
import { Translations as MenuTranslations } from "../../../Components/Menu/Lang"
import { LangContext } from "../../../App"
import SelectRow from "./SelectRow";
import { convertTranslationToOptions } from "../../../utils/translation-to-options";
import Range from "./Range";
import RowInput from "./RowInput";
import { OffersFilterContext } from "../OfertyPage";

export default function WcInput ()
{
  const { lang } = useContext(LangContext);
  const { filters } = useContext(OffersFilterContext);

  return (
    <RowInput 
      label={MenuTranslations[lang].slWc}
      subtitle={`${filters.minWc}-${filters.maxWc}`}
      htInput={<Range min={0} max={23} name="Wc" />}
    />
  )
}
import styled from "styled-components"
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Translations } from "./Lang";
import {useContext, useEffect, useState} from "react";
import { LangContext } from "../../../App";
import Popup from "./Popup";
import ReservationTermsPopup from "./ReservationTermsPopup";
import WhichPatentPopup from "./WhichPatentPopup";
import { formatNumberByCountry } from "../../../utils/number-formatter";

const Wrapper = styled.div`
  margin-top: 45px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;

  @media (max-width: 767px) {
    display: block;
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const Left = styled.div`
  > div {
    z-index: 0;
  }

  .image-gallery-slide img {
    width: 100%;
    height: 340px;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
    border-radius: 5px
  }

  .fullscreen .image-gallery-slide img {
    margin-top: 10vh;
    width: auto;
    height: 80vh;
  }
  .image-gallery-thumbnail {
    border-radius: 5px;
  }

  .image-gallery-thumbnail img {
    height: 75px;
    object-fit: cover;
  }
`

const Right = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-weight: 400;
  color: #133b51;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
  padding: 5px 0px;

  background-image: url(/about/watermark2.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
`

const Title = styled.div`
  font-size: 32px;
  color: #32c8d2;

  @media (max-width: 767px) {
    margin-top: 28px;
  }
`

const Location = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 11px;
  gap: 17px;
  font-family: 'Gilroy-Light';
  font-size: 20px;

  > div > span {
    color: #32c8d2;
    font-family: 'Gilroy-Bold';
    font-size: 25px;
  }

  > div > b {
    font-family: 'Gilroy-ExtraBold';
  }

  > img {
    width: 29px;
  }
`

const Buttons = styled.div`
  margin-top: 28px;
  display: flex;
  gap: 12px;

  @media (max-width: 767px) {
    justify-content: space-between;
  }
`

const Button = styled.button`
  cursor: pointer;
  outline: none;
  background-color: transparent;
  box-sizing: border-box;
  border: 1px solid #133b51;
  border-radius: 10px;
  padding: 16px 28px;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  color: #133b51;

  @media (max-width: 767px) {
    padding: 16px 12px;
  }
`

const Upper = styled.div`
`

const Lower = styled.div`
`

const PriceTag = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 20px;

  @media (max-width: 767px) {
    margin-top: 28px;
  }
`

const Price = styled.div`
  font-size: 40px;
  margin-top: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767px) {
    display: block;
    font-size: 32px;
    margin-top: 0px;
  }
`

const CalendarButton = styled.a`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 10px;
  background-color: #32c8d2;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  height: fit-content;
  cursor: pointer;

  @media (max-width: 767px) {
    margin-top: 14px;
  }
`

export default function Top ({ 
  images, model, name, base, country, deposit, currency, 
  callendar, overButtonProp, disableBelowButtonsProp
})
{
  const { lang } = useContext(LangContext);

  const [isReservationsTermsPopupOpen, setIsReservationsTermsPopupOpen] = useState(false);
  const [isWhichPatentsPopupOpen, setIsWhichPatentsPopupOpen] = useState(false);

  const gallery = images.map((image) => {
    return {
      original: image,
      thumbnail: image
    }
  });

  const DepositTag = function ()
  {
    return parseInt(deposit) === 0
      ? <>{Translations[lang].top.noDeposit}</>
      : <> {Translations[lang].top.from} {formatNumberByCountry(deposit, lang)} {currency} </>
  }

  return (
    <>
      <Wrapper>
        <Left>
          <ImageGallery
            items={gallery}
            thumbnailPosition="left"
            showPlayButton={false}
          />
        </Left>
        <Right>
          <Upper>
            <Title>
              {model} | {name}
            </Title>
            {overButtonProp === null && <Location>
              <img src="/ico/pointer.svg" /> <div>{base} <span>|</span> <b>{country}</b></div>
            </Location>}
            {overButtonProp}
            <Buttons>
              <Button onClick={(e) => setIsWhichPatentsPopupOpen(true)}>{Translations[lang].top.whichPatent}</Button>
              <Button onClick={(e) => setIsReservationsTermsPopupOpen(true)}>{Translations[lang].top.reservationTerms}</Button>
            </Buttons>
          </Upper>
          {overButtonProp === null && <Lower>
            <PriceTag>
              {Translations[lang].top.priceTag}
            </PriceTag>
            <Price>
              <DepositTag />
              <CalendarButton onClick={(e) => callendar.current.scrollIntoView({behavior: 'smooth'})}>
                {Translations[lang].top.calendarButton}
              </CalendarButton>
            </Price>
          </Lower>}
        </Right>
      </Wrapper>
      <ReservationTermsPopup isOpen={isReservationsTermsPopupOpen} setIsOpen={setIsReservationsTermsPopupOpen} />
      <WhichPatentPopup isOpen={isWhichPatentsPopupOpen} setIsOpen={setIsWhichPatentsPopupOpen} />
    </>
  )

}
import axios from "axios"
import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import __server, { __gcloud } from "../../../../Api"
import { Translations } from "./Lang"
import { LangContext } from "../../../../App"
import { ScaleLoader } from "react-spinners"

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    height: 100%;
    align-items: flex-start;
    justify-content: center;
    overflow: scroll;
  }
`

const Card = styled.div`
  width: 1100px;
  background-color: #fff;
  border-radius: 40px;

  @media (max-width: 767px) {
    margin-top: 100px;
    width: 90%;
  }
`

const Image = styled.div`
  height: 300px;
  background-size: cover;
  
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 48.34%, rgba(255, 255, 255, 0.80) 70.21%, #FFF 99.8%), url(${__gcloud}/${params => params.$src});
  background-position: center center;
  border-radius: 40px 40px 0px 0px;
`

const Content = styled.div`
  margin-top: -87px;
  color: #133b51;
  font-family: 'Gilroy-ExtraBold';
  font-size: 20px;
  font-weight: 400;
`

const Top = styled.div`
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
`

const CloseIcon = styled.img.attrs({ src: "/ico/close-popup.svg" })`
  width: 80px;
  cursor: pointer;
`

const Inner = styled.div`
  box-sizing: border-box;
  padding: 0px 67px 30px 67px;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    padding: 0px 23px 40px 23px;
  }
`

const Title = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 36px;
  font-weight: 400;
  width: 485px;

  @media (max-width: 767px) {
    width: 100%;
    font-size: 32px;
    line-height: 35px;
  }
`

const Group = styled.div`
  margin-top: 18px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`

const Props = styled.div`
  margin-bottom: 50px;
`

const Prop = styled.div`
  > span {
    font-family: 'Gilroy-Light';
  }
`

const Subtitle = styled.div``

const List = styled.div`
  ul {
    margin-block-start: 0px;
    padding-inline-start: 20px;
  }

  li {
    font-family: 'Gilroy-Light';
  }
`

const Button = styled.button`
  font-family: 'Manrope';
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  background-color: #1a87c5;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px 30px;
  outline: none;
  margin-top: 25px;
  border: none;
  cursor: pointer;
  align-self: center;
  width: fit-content;
`

export default function Popup ({ item, setItem })
{
  const { lang } = useContext(LangContext);

  const [data, setData] = useState(null);

  useEffect(() => {
    axios.get(`${__server}/front/incentive/${item}`).then((resp) => {
      setData(resp.data);
    }).catch((err) => {
      console.error(err);
      setItem(null);
    }).finally(() => {
      
    });
  }, []);

  return (
    <Wrapper>
      {data === null && <ScaleLoader color="#1a87c5" width={10} height={100} radius={20} />}
      {data && <Card>
        <Image $src={data.image} >
          <Top>
            <CloseIcon onClick={(e) => setItem(null)} />
          </Top>
        </Image>
        <Content>
          <Inner>
            <Title>
              {data.title}
            </Title>
            <Group>
              <div>
                <Props>
                  <Prop>
                    {Translations[lang].place}: <span>{data.place}</span>
                  </Prop>
                  <Prop>
                    {Translations[lang].departure}: <span>{data.departure}</span>
                  </Prop>
                  <Prop>
                    {Translations[lang].duration}: <span>{data.duration}</span>
                  </Prop>
                  <Prop>
                    {Translations[lang].transport}: <span>{data.transport}</span>
                  </Prop>
                  <Prop>
                    {Translations[lang].yachts}: <span>{data.yachts}</span>
                  </Prop>
                </Props>
                <Subtitle>
                  {Translations[lang].additionalAttractions}
                </Subtitle>
                <List dangerouslySetInnerHTML={{ __html: data.additionalAtraction}}/>
              </div>
              <div>
                <Subtitle>
                  {Translations[lang].mainAttractions}
                </Subtitle>
                <List dangerouslySetInnerHTML={{ __html: data.mainAtraction }}/>
              </div>
            </Group>
            <Button>
              {Translations[lang].bookIn}
            </Button>
          </Inner>
        </Content>
      </Card>}
    </Wrapper>
  )
}
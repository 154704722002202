
export const Translations = {
  pl: {
    title: "Rejsy Firmowe",
    descr: <>
      <b>Rejsy firmowe</b> to doskonała forma integracji i motywacji pracowników. Elegancja, komfort oraz możliwość odkrywania uroków lądu od zupełnie innej, czasem jedynej możliwej strony – od strony wody to nie tylko wspaniały relaks ale również doskonała okazja do mniej formalnych, ale częstokroć bardziej produktywnych, kameralnych spotkań biznesowych. 
      <br/><br/>
      Proponujemy rejsy <b>luksusowymi jachtami</b> po ciepłych morzach Grecji, Chorwacji, Karaibów, Seszeli oraz naszych polskich Mazurach.</>,
    departure: 'Termin',
    duration: 'Czas wyjazdu',
    details: 'SZCZEGÓŁY',
    place: 'Miejsce',
    yachts: 'Jachty',
    transport: 'Transport',
    mainAttractions: 'Główne atrakcje',
    additionalAttractions: 'Atrakcje dodatkowe',
    bookIn: 'Zarezerwuj'
  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;
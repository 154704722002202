import React, { useContext } from "react";
import Tabs, {Tab} from "../Tabs";
import { BookingTable } from "../../BookingTable";
import Article from "../../Article";
import { LangContext } from "../../../../../App";
import { Translations } from "./Lang";

import TimeTable from "../TimeTable";
import CampFaq from "./CampFaq";
import CampIcons from "./CampIcons";
import DownloadBox, { Downloader } from "../DownloadBox";
import GoogleMaps from "../../../../../Components/GoogleMaps/GoogleMaps";
import styled from "styled-components";

const GoogleMapsWrapper = styled.div`
  height: 437px;

  @media (max-width: 767px) {
    box-sizing: border-box;
    padding: 0px 15px;
    height: 183px;
  }
`

/**
 * Dla tabów przekazujemy wersję tabelki z bookingiem
 */
export default function CampTabs ({ name, children })
{
  const childrenList = React.Children.toArray(children);
  const bookingTable = childrenList.filter((item) => item.type === BookingTable).at(0);
  const timeTable = childrenList.filter((item) => item.type === TimeTable).at(0);
  const downloadBox = childrenList.filter((item) => item.type === DownloadBox).at(0);

  const { lang } = useContext(LangContext);

  return (
    <Tabs>
      <Tab title="TERMINY I CENA">
        <Article title={Translations[lang].price} contents={Translations[lang][name].price} />
        <Article title={Translations[lang][name].includedPrice} contents={Translations[lang][name].deadlines.includedList} />
        <Article title={Translations[lang][name].notincludedPrice} contents={Translations[lang][name].deadlines.notincludedList} />
        <Article title={Translations[lang].discounts} contents={Translations[lang][name].deadlines.discountsList} />
        <Article title={Translations[lang][name].extraInfo} contents={Translations[lang][name].deadlines.extraInfoList} />
        <Article title={Translations[lang][name].warning} contents={Translations[lang][name].deadlines.warningText} />
        {bookingTable}
        <Article title="" contents={Translations[lang][name].belowTableList} />
      </Tab>
      <Tab title="PROGRAM">
        <Article title={Translations[lang][name].program.title} />
        {timeTable}
        <Article title={Translations[lang][name].program.listTitle} contents={Translations[lang][name].program.list} />
        <Article title={Translations[lang][name].program.infoTitle} contents={Translations[lang][name].program.programInfo} />
        <Article title={Translations[lang][name].program.extraInfo} contents={Translations[lang][name].program.extraInfoList} />
        <Article title="" contents={Translations[lang][name].program.extraInfoDescr} />
        {bookingTable}
        <Article title="" contents={Translations[lang][name].belowTableList} />
      </Tab>
      <Tab title="MIEJSCE">
        <Article title={Translations[lang][name].place.title} contents={Translations[lang][name].place.descr} />
        <GoogleMapsWrapper>
          <GoogleMaps lat={52.45} lon={21.04} hei={'100%'} />
        </GoogleMapsWrapper>
        {bookingTable}
        <Article title="" contents={Translations[lang][name].belowTableList} />
      </Tab>
      <Tab title="FAQ">
        <CampFaq name={name} />
        {bookingTable}
        <Article title="" contents={Translations[lang][name].belowTableList} />
      </Tab>
      <Tab title="PLIKI DO POBRANIA">
        {downloadBox}
        {bookingTable}
        <Article title="" contents={Translations[lang][name].belowTableList} />
      </Tab>
      <Tab title="DLACZEGO FORSAIL">
        <CampIcons />
        {bookingTable}
        <Article title="" contents={Translations[lang][name].belowTableList} />
      </Tab>
    </Tabs>
  )
}
import styled from "styled-components"
import { Column, Left, Row, Table } from "./Table"
import React from "react"

const Wrapper = styled.div`
  margin-bottom: 30px;
`

export function TimeSlice ({ time, activity })
{
  return (
    <>
      <Column>
        <Left>
          {time}
        </Left>
      </Column>
      <Column style={{width: '70%'}}>
        <Left>
          {activity}
        </Left>
      </Column>
    </>
  )
}

export default function TimeTable ({children})
{
  const timeSlices = React
    .Children
    .toArray(children)

  return (
    <Wrapper>
      <Table $hPadding={20}>
        <tbody>
          {timeSlices.map((slice, key) => <Row key={key}>{slice}</Row>)}
        </tbody>
      </Table>
    </Wrapper>
  )
}
export const Translations = {
  pl: {
    title: (
      <>
        Oferta <span>szkoleń żeglarskich</span>
      </>
    ),
    descr: "Dla adeptów żeglarskich przygód",
    loc: "Lokalizacja",
    price: "Cena",
    checkOut: "Sprawdź",
    goToOffer: "Przejdź do oferty",
    list: [
      {
        img: "Chorwacja2.webp",
        title: (
          <>
            Jachtowy sternik morski /<br />
            rejs stażowy
          </>
        ),
        loc: "CHORWACJA",
        price: "od 2 950 zł",
        href: "/academy/szkolenie-jachtowy-sternik-morski",
      },
      {
        img: "Mazury.webp",
        title: (
          <>
            Żeglarz
            <br />
            Jachtowy
          </>
        ),
        loc: "Obóz Mazury",
        price: "od 2 950 zł",
        href: "",
      },
      {
        img: "Chorwacja1.webp",
        title: (
          <>
            Szkolenie
            <br />
            na katamaranie
          </>
        ),
        loc: "CHORWACJA",
        price: "od 2 950 zł",
        href: "",
      },
      {
        img: "Zegrzynskie.webp",
        title: (
          <>
            Szkolenie praktyczne
            <br />
            żeglarz jachtowy
          </>
        ),
        loc: "J. ZEGRZYŃSKIE",
        price: "od 2 950 zł",
        href: "",
      },
    ],
  },
};

Translations.en = Translations.pl;
Translations.de = Translations.pl;

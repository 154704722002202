import { useContext } from "react";
import { Link } from "../Abstract/MenuComponents";
import { LangContext } from "../../../App";

import Menu from "../Abstract/Menu";
import styled from "styled-components";
import { Translations } from "./Lang";
import { IconBlock, IconBlockImage, IconBlockLink } from "../Components/MenuStripe";

const Subtitle = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  color: #133b51;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`

const Title = styled.div`
  margin-top: 9px;
  font-family: 'Gilroy-ExtraBold';
  font-size: 48px;
  line-height: 48px;
  font-weight: 400;
  color: #133b51;
  width: 640px;
  
  @media (max-width: 767px) {
    font-size: 32px;
    width: 250px;
    line-height: 35px;
  }
`

const Descr = styled.div`
  margin-top: 26px;
  font-family: 'Gilroy-Light';
  font-size: 16px;
  font-weight: 400;
  color: #133b51;
  width: 450px;

  @media (max-width: 767px) {
    margin-top: 9px;
    font-size: 16px;
    width: 327px;
  }
`

const MiddleWrapper = styled.div`
  width: 685px;
  padding-bottom: ${params => params.$paddingBottom}px;

  @media (max-width: 767px) {
    padding-bottom: 20px;
    width: 100%;
  }
`

export default function IncentiveMenu ({ title, descr, paddingBottom = 66 })
{
  const { lang } = useContext(LangContext);

  return (
    <Menu 
      menuSliderTop={240}
      backgroundImage="/sub/incentive/bg.webp"

      navigation={<>
        <Link href="/incentive/rejsy-firmowe">{Translations[lang].incentive.companyCruises}</Link>
        <Link href="/incentive/czartery-dla-firm">{Translations[lang].incentive.companyCharters}</Link>
        <Link href="/incentive/regaty-firmowe">{Translations[lang].incentive.companyRegattas}</Link>
        <Link href="/incentive/szkolenia-menadzerskie">{Translations[lang].incentive.managementCurses}</Link>
        <Link href="/incentive/eventy-specjalne">{Translations[lang].incentive.specialEvents}</Link>
      </>}

      middleSection={<MiddleWrapper $paddingBottom={paddingBottom}>
        <Subtitle>Incentive</Subtitle>
        <Title>{title}</Title>
        <Descr>{descr}</Descr>
        </MiddleWrapper>
      } 

      stripeItems={
        <>
          <IconBlock>
            <IconBlockImage src="/ico/phone.svg" />
            <IconBlockLink href="tel:+48668676019">
              +48 668 676 019
            </IconBlockLink>
          </IconBlock>
          <IconBlock>
            <IconBlockImage src="/ico/phone.svg" />
            <IconBlockLink href="tel:+48887234362">
              +48 887 234 362
            </IconBlockLink>
          </IconBlock>
        </>
      }
    />
  )
}
import { useContext, useState } from "react";
import { Form, Input, InputButton, Row, Label, Error } from "./AuthPage";
import { Translations } from "./Lang";
import { LangContext } from "../../App";
import SelectInput from "./SelectInput";
import __states from "../../Constants/States";

import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import plLocale from 'i18n-iso-countries/langs/pl.json';
import deLocale from 'i18n-iso-countries/langs/de.json';

import { useForm } from 'react-hook-form';
import styled from "styled-components";
import { ScaleLoader } from "react-spinners";
import axios from "axios";
import __server from "../../Api";

const LoadingBox = styled.div`
  width: 360px;
  height: 329px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function Register ({})
{

  const { lang } = useContext(LangContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
    defaultValues: {
      mail: '',
      tel: '',
      passw: '',
      passw2: '',
      name: '',
      surname: '',
      street: '',
      house: '',
      post: '',
      city: '',
      country: '',
      state: ''
    }
  });

  /** Etap w formularzu */
  const [stage, setStage] = useState(0);

  /** Wojewodztwa */
  const wojewodztwa = __states.map((state) => { return {
    value: state,
    label: state
  }});

  /** Kraje */
  countries.registerLocale(plLocale);
  countries.registerLocale(enLocale);
  countries.registerLocale(deLocale);

  const countryList = Object.keys(countries.getAlpha3Codes()).map((code) => { return {
    value: code,
    label: countries.getName(code, lang)
  }});

  const onSubmit = function (data)
  {
    /** Reset błędu / ładowania */
    setLoading(true);
    setError(false);

    /** Dodatkowe sprawdzenia */
    if (data.passw !== data.passw2) {
      setError("Hasła muszą być jednakowe");
      setLoading(false);
      return;
    }

    /** API */
    const {pasw2, ...datagram} = data;
    datagram.state = __states.indexOf(datagram.state);
    axios.post(`${__server}/user/register`, datagram).then((resp) => {
      console.log(resp);
      window.location.href = `/aktywacja/${resp.data.mail}`;
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
      setError(Translations[lang].register.errors[err.response.status]);
      setStage(0);
    })
  }

  const stages = [
    <>
      <Row>
        <Input type="mail" placeholder={Translations[lang].mail} {...register('mail', { required: true })} />
      </Row>
      <Row>
        <Input placeholder={Translations[lang].register.name} {...register('name', { required: true })} />
      </Row>
      <Row>
        <Input placeholder={Translations[lang].register.surname} {...register('surname', { required: true })} />
      </Row>
      <Row>
        <Input placeholder={Translations[lang].register.tel} {...register('tel', { required: true })} />
      </Row>
      {error && <Error message={error} />}
      <Row>
        <InputButton type="button" value={Translations[lang].register.btnNext} onClick={(e) => {
          e.preventDefault();
          const data = getValues();

          if ([data.name, data.surname, data.tel, data.mail].some((n) => n === "")) {
            setError(Translations[lang].register.errors.emptyField);
            return;
          }

          setError(null);
          setStage(1);
        }} />
      </Row>
    </>,
    <>
      <Row>
        <Input placeholder={Translations[lang].register.street} {...register('street', { required: true })} />
        <Input placeholder={Translations[lang].register.house} style={{width: "50%"}} {...register('house', { required: true })} />
      </Row>
      <Row>
        <Input placeholder={Translations[lang].register.postal} style={{width: "50%"}} {...register('post', { required: true })} />
        <Input placeholder={Translations[lang].register.city} {...register('city', { required: true })} />
      </Row>
      <Row>
        <SelectInput options={wojewodztwa} placeholder={Translations[lang].register.state} onChange={(e) => setValue('state', e.value) }/>
      </Row>
      <Row>
        <SelectInput options={countryList} placeholder={Translations[lang].register.country} onChange={(e) => setValue('country', e.value )}  />
      </Row>
      {error && <Error message={error} />}
      <Row>
        <InputButton type="button" value={Translations[lang].register.btnNext} onClick={(e) => {
          e.preventDefault();
          const data = getValues();

          if ([data.street, data.house, data.post, data.city, data.state, data.country].some((n) => n === "")) {
            setError(Translations[lang].register.errors.emptyField);
            return;
          }

          setError(null);
          setStage(2);
        }} />
      </Row>
    </>,
    <>
      <Row>
        <Input type="password" placeholder={Translations[lang].passw} {...register('passw')} />
      </Row>
      <Row>
        <Input type="password" placeholder={Translations[lang].register.repeat} {...register('passw2')} />
      </Row>
      <Row>
        <Input type="checkbox" id="tos" style={{width: '20px'}} required />
        <Label htmlFor="tos">
          {Translations[lang].register.tos}
        </Label>
      </Row>
      {error && <Error message={error} />}
      <Row>
        <InputButton type="submit" value={Translations[lang].register.btn} />
      </Row>
    </>
  ]


  return loading ? (
    <LoadingBox>
      <ScaleLoader color="#133b51" />
    </LoadingBox>
  ) : (
    <Form onSubmit={handleSubmit((data) => onSubmit(data))}>
      {stages[stage]}
    </Form>
  )


}
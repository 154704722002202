import styled from "styled-components"


const Wrapper = styled.div`
  margin-top: 13px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 235px;

  background-image: url(/watermark.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center right;

  @media (max-width: 767px) {
    background-image: none;
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const Text = styled.div`
  width: 939px;
  text-align: center;
  color: #133b51;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  > b {
    font-family: 'Gilroy-ExtraBold';
  }

  @media (max-width: 767px) {
    width: 100%;
    text-align: left;
    font-size: 16px;
  }
`

export default function IncentiveTopSection ({ descr })
{
  return (
    <Wrapper>
      <Text>
        {descr}
      </Text>
    </Wrapper>
  )
}
export const Translations = {
  pl: {
    listTitle: "Zapewniamy",
    listButton: "SZCZEGÓŁY SZKOLENIA",
    "camp-polkolonie": {
      title: "Półkolonie nad Zalewem Zegrzyńskim",
      descr:
        "Zalew Zegrzyński to idealne miejsce dla młodych żeglarzy – czysta woda, piękne krajobrazy i przyjazne warunki do nauki. Nasze półkolonie dla dzieci w wieku 8-14 lat doskonale łączą w sobie zabawę z edukacją. Nad zalewem czeka pełen program aktywności, który rozwija umiejętności żeglarskie oraz promuje zdrowy styl życia. Nauka żeglarstwa, zajęcia tematyczne, gry, zabawy i wiele więcej – tego wszystkiego doświadczy Twoje dziecko na naszych półkoloniach! ",
      list: [
        "Aktywnie spędzony czas w bliskości natury ",
        "Kreatywne zajęcia i zadania ",
        "Rozwijanie zainteresowań i pasji ",
        "Rozwój cech charakteru: zaradność, pewność siebie, działanie w grupie, odpowiedzialność ",
      ],
    },
    "camp-obozy": {
      title: "Obozy Żeglarskie",
      descr:
        "Nasze obozy żeglarskie to intensywne, całodniowe programy dla młodzieży w wieku 12-18 lat, które umożliwiają pełne zanurzenie w świecie żeglarstwa. Kursy dostosowane do poziomu umiejętności uczestników, od podstaw po zaawansowane techniki żeglarskie, wyprawy żeglarskie po okolicznych akwenach i programy team-building – to najwyższy standard organizacji, pełne bezpieczeństwo i świetna zabawa dla każdego!",
      list: [
        "Najwyższy poziom organizacyjny rejsów ",
        "Bezpieczeństwo uczestników",
        "Komfortowe, nowoczesne i bezpieczne jachty Antila 27",
        "Rozwój cech charakteru: zaradność, pewność siebie, współpraca w grupie",
        "Niezapomniane przygody, relaks i żeglarski klimat",
      ],
    },
    "academy-sternik": {
      title: "Szkolenie Jachtowy Sternik Morski",
      descr: (
        <>
          Szkolenie Sternik Morski to kompleksowy kurs, który przygotowuje do
          prowadzenia jachtów morskich i oceanicznych. Obejmuje teorię oraz praktykę,
          które są niezbędne do bezpiecznego i efektywnego żeglowania na wodach morskich.
          <br />
          Kurs zawiera: naukę nawigacji, techniki żeglowania, procedury awaryjne,
          pierwszą pomoc, prawo morskie i wiele innych przydatnych na morzu umiejętności.
          Szkolenie kończy się otrzymaniem certyfikatu.
        </>
      ),
      list: [
        "Doświadczonych sterników",
        "Bezpieczne i nowoczesne jachty",
        "Indywidualne podejście do uczestników",
        "Wyżywienie w miejscu szkolenia (śniadanie, obiad) – opcjonalnie",
        "Miłą, żeglarską atmosferę",
        "Zniżki na czartery i rejsy dla uczestników",
      ],
    },
    "academy-zeglarz": {
      title: "Szkolenie Żeglarz Jachtowy",
      descr: (
        <>
          Szkolenie Żeglarz Jachtowy to idealny wybór dla tych, którzy chcą
          nauczyć się sztuki żeglowania i zdobyć podstawowe umiejętności
          potrzebne do prowadzenia jachtu żaglowego. To doskonałe rozwiązanie,
          by rozpocząć przygodę z żeglarstwem! Nauka obsługi jachtu, podstawowe
          manewry, obsługa żagli i steru, czytanie map, planowanie tras,
          wprowadzenie do systemów nawigacyjnych, podstawowe procedury
          bezpieczeństwa – te i wiele więcej umiejętności zdobędziesz podczas
          kursu!
        </>
      ),
      list: [
        "Profesjonalną realizację pełnego programu szkolenia na st. żeglarza jachtowego",
        "Najwyższy poziom organizacyjny szkolenia",
        "Bezpieczeństwo uczestników",
        "Nowoczesny i bezpieczny jacht",
        "Niezapomniane przygody, relaks i żeglarski klimatę",
      ],
    },
    "academy-doszkolenie": {
      title: "Kursy Doszkalające",
      descr: (
        <>
          Chcesz podnieść swoje kwalifikacje i zdobyć dodatkowe umiejętności w
          żeglarstwie? Nasze kursy doszkalające będą dla Ciebie idealnym
          rozwiązaniem! Z nami przygotujesz się na kolejne wyzwanie czekające na
          wodzie! <br/>Oferujemy szeroką gamę szkoleń, które obejmują zaawansowane
          techniki żeglarskie, zarządzanie jachtem i wiele innych, a po
          ukończeniu kursu otrzymasz odpowiedni certyfikat, potwierdzający
          nabyte przez Ciebie umiejętności.
        </>
      ),
      list: [
        "specjalistyczne szkolenia",
        "profesjonalne materiały edukacyjne",
        "indywidualne podejście",
        "doświadczonych instruktorów",
        "certyfikat potwierdzający umiejętności",
      ],
    },
    "academy-staz": {
      title: "Rejsy Stażowe",
      descr: (
        <>
          Rejsy stażowe to doskonała okazja do zdobycia praktycznego
          doświadczenia na wodzie pod okiem doświadczonych instruktorów. To
          kluczowy element w procesie zdobywania profesjonalnych uprawnień
          żeglarskich. <br/>Uczymy pracy zespołowej i zarządzania jachtem, zapewniamy
          bezpośrednie wsparcie i wskazówki od doświadczonych kapitanów i
          instruktorów, a dodatkowo szkolimy w prawdziwych warunkach morskich!
          Dołącz do Forsail Academy i rozwijaj swoje pasje żeglarskie z
          najlepszymi specjalistami!
        </>
      ),
      list: [
        "Doświadczonych sterników",
        "Bezpieczne i nowoczesne jachty",
        "Indywidualne podejście do uczestników",
        "Wyżywienie w miejscu szkolenia (śniadanie, obiad) – opcjonalnie",
        "Miłą, żeglarską atmosferę",
        "Zniżki na czartery i rejsy dla uczestników",
      ],
    },
  },
};

Translations.en = Translations.pl;
Translations.de = Translations.pl;

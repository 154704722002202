import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../App"
import Accordion from "./Accordion"

const Bounding = styled.div`
	@media (max-width: 767px) {
		box-sizing: border-box;
		padding: 0px 15px;
	}
`

const Wrapper = styled.div`
  margin-top: 100px;
  border-radius: 10px;
  padding: 23px 36px;
	height: 462px;
  display: flex;
	justify-content: space-between;
	background-image: url(/home/faq.webp);
	background-size: cover;
	background-position: center center;

	@media (max-width: 767px) {
		height: fit-content;
		flex-direction: column;
		padding: 17px 15px;
	}
`

const Left = styled.div`
	width: 661px;

	@media (max-width: 767px) {
		width: 100%;
	}
`

const Title = styled.div`
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 32px;
  color: #fff;

  > span {
    font-family: 'Gilroy-ExtraBold';
  }
`

const Btn = styled.a`
	text-decoration: none;
	display: block;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  box-sizing: border-box;
  padding: 15px 22px;
  border-radius: 10px;
  border: 2px solid #fff;
  width: fit-content;
	height: fit-content;
	z-index: 999;

	@media (max-width: 767px) {
		margin-top: 20px;
		align-self: center;
	}
`

const Duck = styled.div`
	z-index: 9;
	width: 698px;
	height: 525px;
	background-image: url(/home/duck.webp);
	background-size: 698px 525px;
	margin-top: -475px;
	background-repeat: no-repeat;
	background-position: center right;
	// translate: 50px;
	margin-left: calc(1320px - 648px);
	
	@media (max-width: 767px) {
		display: none;
	}

`

export default function Faq ({})
{
	const { lang } = useContext(LangContext);

	return (
		<Bounding>
			<Wrapper>
				<Left>
					<Title>
						{Translations[lang].faq.title}
					</Title>
					<Accordion />
				</Left>
				<Btn href="/faq">
					{Translations[lang].faq.btn}
				</Btn>
			</Wrapper>
			<Duck />
		</Bounding>
	);
}
/**
 * WARN: Strukturalny element wykorzystywany na wielu
 *       podstronach, enigmatyczna nazwa oznacza qlid
 *       w strukture katalogów i langa.
 */

import { Translations } from "./Lang";
import { LangContext, ThemeContext } from "../../../../App"
import { useContext, useState } from "react"
import { styled } from "styled-components"
import Lightbox from 'react-awesome-lightbox';
import "react-awesome-lightbox/build/style.css";


const Wrapper = styled.div`
  display: flex;
  gap: 28px;
  margin-top: ${params => params.$top}px;

  @media (max-width: 767px) {
    flex-direction: column;
    box-sizing: border-box;
    padding: 0px 15px;
    gap: 24px;
  }
`

const Left = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Right = styled.div`
  width: 50%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 34px 43px;
  
  @media (max-width: 767px) {
    width: 100%;
    padding: 23px;
  }
`

const Title = styled.div`
  color: ${params => params.$theme.primaryColor};
  font-family: 'Gilroy-ExtraBold';
  font-weight: 400;
  font-size: 32px;

  @media (max-width: 767px) {
    font-size: 24px;
  }
`

const Descr = styled.div`
  margin-top: 15px;
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #000;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const ListTitle = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-weight: 400;
  font-size: 24px;
  color: #143a51;
`

const List = styled.ul`
  padding-inline-start: 30px;
`

const Element = styled.li`
  padding-left: 7px;
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: #143a51;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`

const Thumbs = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px 39px;

  @media (max-width: 767px) {
    margin-top: 24px;
    gap: 17px 22px;
  }
`

const Thumb = styled.div`
  width: 100%;
  height: 184px;
  background-image: url(${params => params.$img});
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  cursor: pointer;

  @media (max-width: 767px) {
    height: 111px;
  }
`

const MainPhoto = styled.div`
  margin-top: 10px;
  height: max-content;
  border-radius: 10px;
  flex: auto;
  background-image: url(${params => params.$img});
  background-size: cover;
  background-position: center center;
`

const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Details = styled.a`
  margin-top: 20px;
  text-decoration: none;
  font-family: 'Manrope';
  font-size: 20px;
  font-weight: 700;
  box-sizing: border-box;
  padding: 14px 30px;
  background-color: #94073a;
  color: #fff;
  border-radius: 5px;
`

export default function Leaflet ({ name, top, details = null })
{
  const { lang } = useContext(LangContext);
  const theme = useContext(ThemeContext);

  const [lightBoxImage, setLightBoxImage] = useState(null);

  const mainPhoto = `/leaflet/${name}/main.webp`;
  
  const thumbs = [
    `/leaflet/${name}/top-left.webp`,
    `/leaflet/${name}/top-right.webp`,
    `/leaflet/${name}/bottom-left.webp`,
    `/leaflet/${name}/bottom-right.webp`
  ]

  const translations = Translations[lang][name];
  const title = translations.title;
  const descr = translations.descr;
  const list = translations.list;

  return (
    <Wrapper $top={top}> 
      {lightBoxImage && <Lightbox image={lightBoxImage} onClose={(e) => setLightBoxImage(null)} />}
      <Left>
        <Title $theme={theme}>
          {title}
        </Title>
        <Descr>
          {descr}
        </Descr>
        <MainPhoto $img={mainPhoto} />
      </Left>
      <Right>
        <ListTitle>
          {Translations[lang].listTitle}
        </ListTitle>
        <List>
          {list.map((item, key) => <Element key={key}>{item}</Element>)}
        </List>
        <DetailsWrapper>
          { details && <Details href={details}>{Translations[lang].listButton}</Details>}
        </DetailsWrapper>
        <Thumbs>
          {thumbs.map((thumb, key) => (
            <Thumb $img={thumb} onClick={(e) => setLightBoxImage(thumb)} key={key} />
          ))}
        </Thumbs>
      </Right>
    </Wrapper>
  )
}
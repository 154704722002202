import {styled} from "styled-components";
import OfferCard from "./OfferCard";

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  display: flex;
  gap: 28px;
  margin-top: 56px;

  @media (max-width: 767px) {
    margin-top: 22px;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

export default function OffersBundle ({leftName, leftUrl, rightName, rightUrl})
{
	return (
		<Wrapper>
			<OfferCard name={leftName} href={leftUrl} />
			<OfferCard name={rightName} href={rightUrl} />
		</Wrapper>
	)
}
import { Translations as MenuTranslations } from "../../../Components/Menu/Lang"
import { useContext } from "react";
import { LangContext } from "../../../App";
import styled from "styled-components";
import { OffersFilterContext } from "../OfertyPage";
import RowInput from "./RowInput";

const Datebox = styled.input.attrs({type: "date"})`
  width: 100%;
  margin-top: 6px;
  color: hsl(0, 0%, 20%);
  background-color: #fff;
  outline: none;
  border: 1px solid #32C8D2;
  border-radius: 4px;
  font-family: 'Gilroy-Light';
  font-weight: 400;
  box-sizing: border-box;
  padding: 9px 8px;
  
  &:focus {
    box-shadow: 0 0 0 1px #3792ca;
  }
  
  &::-webkit-calendar-picker-indicator {
    opacity: 0; 
  }

  &::before {
    content: '';
    position: absolute;
    opacity: 0.3;
    width: 20px;
    height: 20px;
    background-image: url('/ico/chevron-input.svg');
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
    translate: 248px;
  }
  
  &:hover::before {
    opacity: 0.5;
  }
`

export default function CallendarInput ()
{
  const { lang } = useContext(LangContext);
  const { filters, setFilters } = useContext(OffersFilterContext);

  return (
    <RowInput
      label={MenuTranslations[lang].spDate}
      htInput={
        <Datebox 
          value={filters.start}
          onChange={(e) => setFilters({...filters, start: e.target.value})}
        />
      }     
    />
  )
}

import { useContext, useState } from 'react';
import { styled } from 'styled-components';

import { LangContext, ThemeContext } from '../../../../App'
import { Translations } from './Lang';

import Lightbox from 'react-awesome-lightbox';
import "react-awesome-lightbox/build/style.css";

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;

  @media (max-width: 767px) {
    width: 100%;
  }
`
const Hero = styled.div`
  background-image: url(/sub/top/${params => params.$name}/main.webp);
  background-size: cover;
  background-position: center bottom;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 53px 0px 267px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 767px) {  
    padding: 20px 15px 211px 15px;
  }
`

const Title = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 32px;
  font-weight: 400;
  color: ${params => params.$theme.primaryColor};

  @media (max-width: 767px) {
    font-size: 24px;
  }
`

const Descr = styled.div`
  text-align: center;
  width: 1000px;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  color: #143a51;
  > span {
    color: ${params => params.$theme.primaryColor};
    font-family: 'Gilroy-ExtraBold';
  }

  @media (max-width: 767px) {
    margin-top: 18px;
    width: 100%;
    font-size: 16px;
  }
`

const AltLeaflet = styled.div`
  margin-top: 60px;
  display: flex;
  gap: 28px;

  @media (max-width: 767px) {
    margin-top: 20px;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0px 15px;
    gap: 25px;
  }
`

const Left = styled.div`
  width: 50%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 36px 55px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 20px;
  }
`

const Right = styled.div`
  width: 50%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 15px;

  @media (max-width: 767px) {
    width: 100%;
    gap: 5px 9px;
  }
`

const Thumb = styled.div`
  background-image: url(${params => params.$img});
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  height: 155px;

  @media (max-width: 767px) {
    height: 97px;
  }
`

const ListTitle = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 24px;
  font-weight: 400;
  color: ${params => params.$theme.primaryColor};
`

const List = styled.ul`
  padding-inline-start: 30px;
`

const Element = styled.li`
  padding-left: 7px;
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: #143a51;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Booking = styled.a`
  margin-top: 60px;
  text-decoration: none;
  font-family: 'Manrope';
  font-size: 20px;
  font-weight: 700;
  box-sizing: border-box;
  padding: 14px 30px;
  background-color: ${params => params.$theme.primaryColor};
  color: #fff;
  border-radius: 5px;
`

/**
 * WARN: Struktura plików dla sekcji TOP
 *       na podstronach z ofertami:
 *     
 *       /sub/top/{name}/main.webp
 *                      /top-left.webp
 *                      /top-right.webp
 *                      /bottom-left.webp
 *      Galeria na dole: 
 *                      /g{0}.webp ... g{galSize}.webp
 * 
 *     Ponad to, tłumaczenia przekazwne
 *     też są w sposób super dobry!
 */
export default function Top ({ name, pageTranslations })
{
  const { lang } = useContext(LangContext);
  const theme = useContext(ThemeContext);

  const [lightBoxImage, setLightBoxImage] = useState(null);

  const thumbsList = [
    "top-left", "top-right", "bottom-left", "bottom-right"
  ];

  return (
    <Wrapper>
      {lightBoxImage && <Lightbox image={lightBoxImage} onClose={(e) => setLightBoxImage(null)} />}
      <Hero $name={name}>
        <Title $theme={theme}>
          {pageTranslations[lang].offer.title}
        </Title>
        <Descr $theme={theme}>
          {pageTranslations[lang].offer.descr}
        </Descr>
      </Hero>
      <AltLeaflet>
        <Left>
          <ListTitle $theme={theme}>
            {Translations[lang].listTitle}
          </ListTitle>
          <List>
            {pageTranslations[lang].offer.list.map((item, key) => (
              <Element key={key}>{item}</Element>
            ))}
          </List>
        </Left>
        <Right>
          {thumbsList.map((item, key) => (
            <Thumb $img={`/sub/top/${name}/${item}.webp`} key={key} onClick={(e) => setLightBoxImage(`/sub/top/${name}/${item}.webp`)}/>
          ))}
        </Right>
      </AltLeaflet>
      <ButtonWrapper>
        <Booking $theme={theme}>{Translations[lang].bookingBtn}</Booking>
      </ButtonWrapper>
    </Wrapper>
  )
}
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { LangContext } from "../../../App";
import LiteMenu from "../../../Components/Menu/Factory/LiteMenu";
import Footer from "../../../Components/Footer/Footer";
import axios from "axios";
import __server from "../../../Api";
import styled from "styled-components";
import { Translations } from "./Lang";

import Yacht from "./Yacht";


const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  margin-top: 34px;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const BackButton = styled.a`
  text-decoration: none;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  color: #133b51;

  > img {
    width: 40px;
    margin-right: 14px;
  }

  @media (max-width: 767px) {
    box-sizing: border-box;
    padding: 0px 15px;
  }
`


export default function YachtPage ()
{
  const { model, id } = useParams();
  const { lang } = useContext(LangContext);
  
  return (
    <>
      <LiteMenu />
      <Wrapper>
				<BackButton href={`/katalog-jachtow/${model}${window.location.search}`}>
					<img src="/ico/arrow-back.svg" alt="" />
					{Translations[lang].backButton}
				</BackButton>
        <Yacht yachtId={id} />
      </Wrapper>
      <Footer />
    </>
  )
}
import styled from "styled-components";
import { useContext } from "react";
import Select from 'react-select'
import { OffersFilterContext } from "../OfertyPage";

const Wrapper = styled.div`
  width: 100%;
`

const Label = styled.div`
  font-size: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  > span {
    font-family: 'Gilroy-Light';
    opacity: 0.5;
  }
`

export default function RowInput ({ label, htInput, subtitle = null })
{
  return (
    <Wrapper>
      <Label>
        {label}
        {subtitle && <span>{subtitle}</span>}
      </Label>
      {htInput}
    </Wrapper>
  )
}
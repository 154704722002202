import axios from "axios";
import { useContext, useEffect, useState } from "react"
import { ScaleLoader } from "react-spinners";
import styled from "styled-components";
import __server, { __gcloud } from "../../../../Api";
import { LangContext } from "../../../../App";
import { Translations } from "./Lang";
import Popup from "./Popup";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 62px;

  @media (max-width: 767px) {
    margin-top: 27px;
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const Grid = styled.div`
  width: 100%;
  display: grid;
  gap: 34px 24px;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`

const Card = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.2);
`

const Image = styled.div`
  width: 100%;
  height: 265px;
  background-image: url(${__gcloud}/${params => params.$src});
  background-position: center center;
  background-size: cover;
  border-radius: 10px 10px 0px 0px;

  @media (max-width: 767px) {
    height: 260px;
  }
`

const Content = styled.div`
  box-sizing: border-box;
  padding: 18px 38px 35px 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 24px;
  font-weight: 400;
  color: #133b51;
  text-align: center;
  height: 60px;

  @media (max-width: 767px) {
    font-size: 20px;
  }
`

const Props = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin-top: 7px;
`

const Prop = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  color: #133b51;
  > span {
    text-transform: none;
    font-family: 'Gilroy-ExtraBold';
    margin-left: 6px;
  }
`

const Button = styled.button`
  font-family: 'Manrope';
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  background-color: #1a87c5;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px 30px;
  outline: none;
  margin-top: 25px;
  border: none;
  cursor: pointer;
`

const Group = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default function List ()
{
  const { lang } = useContext(LangContext);

  const [popupItem, setPopupItem] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${__server}/front/incentive?lang=${lang}`).then((resp) => {
      setList(resp.data);
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
      setIsLoading(false);
    });

  }, []);

  const onButtonClick = function (itemId)
  {
    window.scrollTo(0, 0);
    setPopupItem(itemId);
  }

  return (
    <Wrapper>
      {popupItem && <Popup item={popupItem} setItem={setPopupItem} />}
      {isLoading && (<ScaleLoader color="#133b51" />)}
      <Grid>
        {list.map((item, key) => (
          <Card key={key}>
            <Image $src={item.image}/>
            <Content>
              <Title>
                {item.title}
              </Title>
              <Group>
                <Props>
                  <Prop>
                    {Translations[lang].departure}: <span>{item.departure}</span>
                  </Prop>
                  <Prop>
                    {Translations[lang].duration}: <span>{item.duration}</span>
                  </Prop>
                </Props>
                <Button onClick={(e) => onButtonClick(item.id)}>
                  {Translations[lang].details}
                </Button>
              </Group>
            </Content>
          </Card>
        ))}
      </Grid>
    </Wrapper>
  )
}
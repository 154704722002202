import styled from "styled-components"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  margin-top: 53px;
`

const Image = styled.div`
  width: 100%;
  height: 250px;
  border-radius: 10px;
  background-image: url(${params => params.$src});
  background-size: cover;
  background-position: center center;
`

/**
 * Zdjęcia renderowane z katalogu /sub/academy/{name}/g0.webp .. gN.webp
 * @returns 
 */
export default function AcademyCarousel ({name, galSize})
{
  const imageList = new Array(galSize).fill(null).map((n, i) => 
    `/sub/academy/${name}/g${i}.webp`);

  return (
    <Wrapper>
      {imageList.map((image, key) => (
        <Image $src={image} key={key} />
      ))}
    </Wrapper>
  )
}
import { useContext } from "react";
import { Error, Form, Input, Link, LoginBtn, Row, Submit } from "./AuthPage";
import { LangContext } from "../../App";
import { Translations } from "./Lang";
import styled from "styled-components";
import { useState } from 'react';
import { ScaleLoader } from 'react-spinners';

import axios from 'axios';
import __server from '../../Api.js'

import { useForm } from 'react-hook-form';

const SpinnerBox = styled.div`
  width: 360px;
  height: 184px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default function Login ({})
{
  const { lang } = useContext(LangContext);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // const [mail, setMail] = useState('');
  // const [passw, setPassw] = useState('');

  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    defaultValues: {
      mail: '',
      passw: ''
    }
  });

  const onLogin = function (data)
  {
    /** Reset ładowania / błędów */
    setLoading(true);
    setError(null);

    /** API */
    axios.post(`${__server}/user/login`, data).then((resp) => {
      const token = resp.data.token;
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      localStorage.setItem("bearer_token", token);

      window.location.href = '/';
    }).catch((err) => {
      setLoading(false);
      console.log(err);
      setError(Translations[lang].login.errors[err.response.status]);
    })
  }

  return loading ? (
    <SpinnerBox>
      <ScaleLoader color="#133b51" />
    </SpinnerBox>
    ) : (
    <Form onSubmit={handleSubmit(data => onLogin(data))}>
      <Row>
        <Input type="email" placeholder={Translations[lang].mail}  {...register('mail')} />
      </Row>
      <Row>
        <Input type="password" placeholder={Translations[lang].passw} {...register('passw')} />
      </Row>
      {error && <Error message={error} /> }
      <Row>
        <Link href="/resetowanie-hasla">
          {Translations[lang].login.forgotPassw}
        </Link>
        <LoginBtn>
          {Translations[lang].login.btn}
        </LoginBtn>
      </Row>
    </Form>
  )
}

export const Translations = {
  pl: {
    title: "Regaty Firmowe",
    descr: <>Regaty żeglarskie to doskonała <b>forma integracji i motywacji pracowników</b> oraz wykorzystanie analogii pracy załogi na jachcie oraz zespołu pracującego w firmie. Regaty żeglarskie to także doskonała <b>forma promocji i budowania wizerunku firmy</b>. Idealna jako narzędzie wsparcia sprzedaży, adresowane do najważniejszych kontrahentów, kluczowych odbiorców czy klientów. 
      <br/><br/>
      Imprezy utrzymywane w konwencji żeglarskiej – jako regaty sportowe, cieszą się bardzo dużym uznaniem wśród zaproszonych gości, a dla firmy zapraszającej są doskonałą okazją do budowy wizerunku firmy oryginalnej, niestandardowej, będącej „na fali” w przenośni i dosłownie.
      </>,

    infoSection: {
      leftCard: {
        title: "Żeglarskie Imprezy Regatowe ",
        content: <><ul>
          <li>dowolna liczba uczestników – od kilku do kilkuset osób</li>
          <li>dowolny czas trwania- regaty jedno i wielodniowe</li>
          <li>Polska lub dowolny region na świecie</li>
          <li>brak konieczności przygotowania kondycyjnego i merytorycznego uczestników...</li>
          </ul>
        </>
      },
      rightCard: {
        title: "Na czym polegają Regaty Firmowe ",
        content:<><b>Miejsce:</b> Regaty mogą zostać rozegrane na jeziorze lub morzu, w Polsce lub dowolnym miejscu na świecie, pod warunkiem występowania odpowiedniej infrastruktury i warunków żeglarskich. Mamy doświadczenie i polecimy odpowiednie miejsca.
          <br/><br/>
          <b>Zakwaterowanie:</b> W przypadku regat morskich uczestnicy są zakwaterowani na jachtach, na których się ścigają. Każdego dnia nocleg jest w marinie, wyposażonej w odpowiednią infrastrukturę (prąd, wodę, prysznice z ciepłą wodą). W przypadku regat śródlądowych uczestnicy mogą być zakwaterowani na jachtach lub w hotelu.
          <br/><br/>
          <b>Jachty:</b> Na jachtach morskich zwykle jest 4 do 5 dwuosobowych kajut z pościelą. Kajuty są zamykane, co daje poczucie prywatności uczestnikom. Na jachtach znajdują się 3 -4 toalety oraz łazienki z prysznicami i gorącą wodą, kuchnia z pełnym wyposażeniem (kuchenka gazowa, lodówki, piekarnik, garnki, naczynia, sztućce) umożliwiającym przygotowywanie posiłków, radio i głośniki. Jachty śródlądowe w zależności od wielkości i standardu mogą również posiadać wyposażenie kuchenne. Oddzielne i zamykane kajuty posiadają tylko większe jednostki. Wyżywienie: na jachtach morskich śniadanie może być przygotowywane przez uczestników na pokładzie lub zorganizowane w restauracji. Lunch zwykle jedzony jest w czasie żeglowania, na kolację uczestnicy idą do restauracji, w której jest również możliwość zorganizowania dyskoteki.
          <br/><br/>
          <b>Bezpieczeństwo:</b> przykładamy dużą wagę do sprawy bezpieczeństwa uczestników. Jachty wyposażone są w niezbędne środki bezpieczeństwa adekwatne do rejonu żeglowania. Każdorazowo na regatach zapewniamy zabezpieczenie ratowników medycznych oraz informujemy odpowiednie służby o przeprowadzaniu regat. Wykorzystujemy naszą znajomość akwenów i wieloletnie doświadczenie oraz więdzę z dziedziny żeglarstwa i meteorologii. Posiadamy certyfikat STCW (International Convention on Standards of Training).
          <br/><br/>
          <b>Transport uczestników:</b> na życzenie organizujemy transport uczestników drogą lądową lub lotniczą.
          </>
      },
    },
    offers: {
      deadline: "TERMIN",
      departure: "CZAS WEYJAZDU",
      button: "ZAPISZ SIĘ",
      list: [
        {
          title: "Regaty firmowe Polska",
          deadline: "maj-wrzesień",
          departure: "1-2 dni"
        },
        {
          title: "Regaty firmowe Karaiby",
          deadline: "listopad-kwiecień",
          departure: "7 dni"
        },
        {
          title: "Regaty firmowe Chorwacja",
          deadline: "maj-wrzesień",
          departure: "1-2 dni"
        },
      ]
    }
  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;
import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../App"
import Accordion from "./Accordion"

const Bounding = styled.div`
	@media (max-width: 767px) {  
		box-sizing: border-box;
		padding: 0px 15px;
	}
`

const Wrapper = styled.div`
  margin-top: 100px;
  border-radius: 10px;
  padding: 23px 36px;
	height: 462px;
  display: flex;
	justify-content: space-between;
	background-image: url(/home/faq.webp);
	background-size: cover;
	background-position: center center;

	@media (max-width: 767px) {
		height: fit-content;
		margin-top: 50px;
	}
`

const Left = styled.div`
	width: 661px;
`

const Title = styled.div`
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 32px;
  color: #fff;

  > span {
    font-family: 'Gilroy-ExtraBold';
  }
`

const Wheel = styled.div`
	z-index: 9;
	width: 726px;
	height: 571px;
	background-image: url(/skippers/steer.webp);
	background-size: 726px 571px;
	margin-top: -571px;
	background-repeat: no-repeat;
	background-position: center right;
	margin-left: calc(1320px - 726px);

	@media (max-width: 767px) {
		display: none;
	}
`

export default function Faq ({})
{
	const { lang } = useContext(LangContext);

	return (
		<Bounding>
			<Wrapper>
				<Left>
					<Title>
						{Translations[lang].faq.title}
					</Title>
					<Accordion />
				</Left>
			</Wrapper>
			<Wheel />
		</Bounding>
	);
}
export const Translations = {
  pl: {
    exam: {
      title: "Przygotuj się do egzaminu wykonując testy ",
      descr:
        "Specjalnie dla Ciebie – kompleksowa platforma szkoleniowa dla aspirujących żeglarzy. Zwiększ swoje szanse na sukces!",
      btn: "ZRÓB TEST",
    },
  },
};

Translations.en = Translations.pl;
Translations.de = Translations.pl;

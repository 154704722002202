import styled from "styled-components";

const Wrapper = styled.div`
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0,0,0, 0.5);
		
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
		
	z-index: 99;
`

const Box = styled.div`
	width: 1095px;
	box-sizing: border-box;
	padding: 15px;
		
	border-radius: 10px;
	background-color: #fff;
		
	background-image: url(/about/watermark2.png);
	background-size: 600px;
	background-position: center right 10%;
	background-repeat: no-repeat;
		
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	z-index: 99999999999;
`

const Content = styled.div`
	margin-top: -15px;
	width: 100%;
	box-sizing: border-box;
	padding: 0px 60px 40px 60px;
`

const CloseButton = styled.img`
	width: 33px;
	cursor: pointer;
	z-index: 9999;
`

export const Title = styled.div`
	font-family: 'Gilroy-ExtraBold';
	font-size: 24px;
	color: #32c8d2;
`

export const Subtitle = styled.div`
	font-family: 'Gilroy-Light';
	font-size: 24px;
	color: #133b51;
`

export const Descr = styled.div`
	margin-top: 40px;
	font-family: 'Gilroy-Light';
	font-size: 16px;
	line-height: 28px;
	color: #133b51;
`

export default function Popup ({ body, isOpen, setIsOpen })
{
	if (!isOpen) {
		return <></>;
	}

	return (
		<Wrapper>
			<Box>
				<CloseButton src="/ico/close-popup-charter.svg" onClick={(e) => setIsOpen(false)}/>
				<Content>
					{body}
				</Content>
			</Box>
		</Wrapper>
	)
}
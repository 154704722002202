
export const Translations = {
  pl: {
    title: <>Nasze <span>Kierunki</span></>,
    descr: 'Luksusowy hotel w urokliwym miejscu z pięknym widokiem na morze i płynący po nim jacht, to niewątpliwy atut wyjazdu incentive. My proponujemy miejsce na tym właśnie jachcie, z którego możesz podziwiać zarówno ten hotel, jak i setki innych, jeszcze piękniejszych miejsc, niedostępnych z lądu. Poprzez SAIL INCENTIVE – żeglarskie imprezy firmowe – odkrywamy nowe lub znane destynacje na nowo!',
  },
  en: {
    title: <>Nasze <span>Kierunki</span></>,
    descr: 'Luksusowy hotel w urokliwym miejscu z pięknym widokiem na morze i płynący po nim jacht, to niewątpliwy atut wyjazdu incentive. My proponujemy miejsce na tym właśnie jachcie, z którego możesz podziwiać zarówno ten hotel, jak i setki innych, jeszcze piękniejszych miejsc, niedostępnych z lądu. Poprzez SAIL INCENTIVE – żeglarskie imprezy firmowe – odkrywamy nowe lub znane destynacje na nowo!',
  },
  de: {
    title: <>Nasze <span>Kierunki</span></>,
    descr: 'Luksusowy hotel w urokliwym miejscu z pięknym widokiem na morze i płynący po nim jacht, to niewątpliwy atut wyjazdu incentive. My proponujemy miejsce na tym właśnie jachcie, z którego możesz podziwiać zarówno ten hotel, jak i setki innych, jeszcze piękniejszych miejsc, niedostępnych z lądu. Poprzez SAIL INCENTIVE – żeglarskie imprezy firmowe – odkrywamy nowe lub znane destynacje na nowo!',
  }
}
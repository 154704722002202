import {Translations} from "./Lang";
import {styled} from "styled-components";
import {useContext} from "react";
import {LangContext, ThemeContext} from "../../../../../App";


const Card = styled.div`
  width: 50%;
  background-color: #fff;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Image = styled.div`
  width: 50%;
  height: 315px;
  background-image: url(${params => params.$img});
  background-size: cover;
  background-position: center center;
  border-radius: 10px 0px 0px 10px;

  @media (max-width: 767px) {
    width: 45%;
    height: 210px;
  }
`

const Container = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  box-sizing: border-box;
  padding: 40px 16px 26px 16px;

  @media (max-width: 767px) {
    width: 55%;
    padding: 14px 5px 16px 5px;
  }
`

const Title = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 24px;
  font-weight: 400;
  color: #133b51;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`

const Mid = styled.div`
`

const Bot = styled.div`
  display: flex;
  flex-direction: column;
`

const Property = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 16px;
  font-weight: 400;
  color: #133b51;
  > span {
    font-family: 'Gilroy-ExtraBold';
  }

  @media (max-width: 767px) {
    margin-top: 5px;
    font-size: 12px;
  }
`

const Details = styled.a`
  margin-top: 25px;
  text-decoration: none;
  font-family: 'Manrope';
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  background-color: ${params => params.$theme.primaryColor};
  box-sizing: border-box;
  padding: 14px 30px;
  border-radius: 5px;

  @media (max-width: 767px) {
    margin-top: 18px;
    font-size: 16px;
  }
`
export default function OfferCard ({name, href})
{
	const { lang } = useContext(LangContext);
	const theme = useContext(ThemeContext);

	return (
		<Card>
			<Image $img={`/sub/camp/${name}.webp`} />
			<Container>
				<Title>
					{Translations[lang][name].title}
				</Title>
				<Mid>
					{Translations[lang][name].props.map((item, key) => (
						<Property key={key}>{item}</Property>
					))}
				</Mid>
				<Bot>
					<Property>{Translations[lang][name].age}</Property>
					<Details href={href} $theme={theme}>{Translations[lang].details}</Details>
				</Bot>
			</Container>
		</Card>
	)
}

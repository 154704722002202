import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../App"
import Models from "./Models"
import VerticalModels from "./VerticalModels";

const Wrapper = styled.div`
	width: 100%;
	background-color: rgba(255, 255, 255, 0.1);
	box-shadow: 4px 4px 20px 0px rgba(50, 200, 210, 0.08);
	border: 1px solid #32c8d2;
	border-radius: 4px;
	box-sizing: border-box;
	padding: 14px 17px;

	@media (max-width: 767px) {
		display: none;
	}
`

const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const Title = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-weight: 400;
  font-size: 20px;
  color: #133b51;

  > span {
    font-family: 'Gilroy-ExtraBold';
  }
`

export default function PromotedModelsVerticalSection ()
{
  const { lang } = useContext(LangContext);

  return (
    <Wrapper>
      <Top>
        <Title>
          {Translations[lang].altTitle}
        </Title>
      </Top>
      <VerticalModels />
    </Wrapper>
  )
}

export const formatNumberByCountry = function (number, lang, minFracDigits = 0) 
{
  const langTable = {
    pl: "pl-PL",
    en: "en-US",
    de: "de-DE"
  };

  const userLang = langTable[lang];

  return new Intl
    .NumberFormat(userLang, { 
      useGrouping: true,
      minimumFractionDigits: minFracDigits,
      maximumFractionDigits: minFracDigits,
    })
    .format(number)
}
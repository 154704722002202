
import { useContext } from "react"
import { Translations as MenuTranslations } from "../../../Components/Menu/Lang"
import { LangContext } from "../../../App"
import SelectRow from "./SelectRow";
import { convertTranslationToOptions } from "../../../utils/translation-to-options";

export default function DaysInput ()
{
  const { lang } = useContext(LangContext);

  return (
    <SelectRow
      name="days" 
      options={convertTranslationToOptions(MenuTranslations[lang].spDays)} 
      label={MenuTranslations[lang].slDays}
      placeholder={MenuTranslations[lang].spChoose} 

      isSearchable={false}
      isClearable={false}
    />
  )
}
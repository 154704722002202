
export const Translations = {
  pl: {
    title: <>Zapisz się do <span>newslettera</span></>,
    description: 'Miej dostęp do nowości w naszej ofercie i bieżących promocjach',
    mail: 'Wpisz swój e-mail',
    btn: 'Zapisuję się'
  },
  en: {
    title: <>Zapisz się do <span>newslettera</span></>,
    description: 'Miej dostęp do nowości w naszej ofercie i bieżących promocjach',
    mail: 'Wpisz swój e-mail',
    btn: 'Zapisuję się'
  },
  de: {
    title: <>Zapisz się do <span>newslettera</span></>,
    description: 'Miej dostęp do nowości w naszej ofercie i bieżących promocjach',
    mail: 'Wpisz swój e-mail',
    btn: 'Zapisuję się'
  }
}
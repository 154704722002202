export const Translations = {
  pl: {
    pageTitle: "O nas",
    meetForsail: (
      <>
        Poznaj <span>Forsail</span>
      </>
    ),
    meetDescr: (
      <>
        Firma Forsail powstała w 2004 roku. Forsail współtworzy zespół
        doświadczonych i kreatywnych ludzi, specjalizujących się w obsłudze
        klientów biznesowych, w zakresie podróży motywacyjnych (Incentive) oraz
        programów teambuldingowych nastawionych na aktywne formy turystyki.
        Pomagamy w budowaniu i udoskonalaniu relacji oraz procesów
        komunikacyjnych w zespołach oraz relacjach z klientami. <br />
        <br /> Wykorzystujemy oryginalną i nowoczesną metodę budowy wizerunku
        opartą na marketingu bezpośrednim, którego narzędziami jest organizacja
        imprez typu incentive oraz promotional events.
      </>
    ),
    iconList1Title: (
      <>
        Gdzie stawiamy na <span>doświadczenie</span>
      </>
    ),
    iconList1: [
      "16-letnie doświadczenie w organizacji incentive szytych na miarę",
      "Ponad 500 zrealizowanych imprez firmowych w kraju i zagranicą",
      "Ponad 25.000 uczestników wzięło udział w imprezach organizowanych przez Forsail",
    ],
    iconList2Title: (
      <>
        Gdzie stawiamy na <span>świat</span>
      </>
    ),
    iconList2: [
      "Lider w dziedzinie incentive pod żaglami – autorskie programy: Mazury, Bałtyk, Morze Śródziemne, Karaiby, Seszele, Tajlandia",
      "Czartery jachtów w kraju i zagranicą – sprawdzeni armatorzy, najlepsze żeglarskie destynacje",
      "Stała i doświadczona kadra",
    ],
    goodOrganization: (
      <>
        Gdzie stawiamy na <span>dobrą organizację</span>
      </>
    ),
    goodOrganizationCards: [
      "Organizacja regat żeglarskich",
      "Rejsy firmowe – propozycja dla ciekawych świata, doskonały sposób na integrację",
      "Spotkania VIP na jachtach – wyjątkowe, ekskluzywne spotkania na megajachtach dla niewielkich grup",
      "Wyjazdy egzotyczne – szeroka oferta wyjazdów incentives w najpiękniejsze regiony świata",
    ],
    safety: (
      <>
        Gdzie stawiamy na <span>bezpieczeństwo</span>
      </>
    ),
    safetyDescr:
      "Bezpieczeństwo to dla nas absolutnie kluczowa kwestia – tutaj nie ma miejsca na błędy czy kompromisy. Przy organizacji naszych rejsów, szkoleń i eventów utrzymujemy najwyższe standardy, aby zapewnić spokojny i komfortowy pobyt na wodzie. Nowoczesne statki, wykwalifikowana załoga i procedury awaryjne – pełne bezpieczeństwo z Forsail!",
    safetyCards: [
      "Najwyższy poziom bezpieczeństwa na wodzie",
      "Polisa ubezpieczeniowa OC",
      "Wpis do rejestru Organizatorów Turystyki i Pośredników Turystycznych",
      "Gwarancja OC organizatora turystyki – cały świat",
    ],
    winterToo: (
      <>
        Również <span>zimą</span>
      </>
    ),
    winterBlocks: [
      "Posiadamy własną Szkołę narciarską Forsail Ski Club",
      "Organizujemy wyjazdy narciarskie w Polsce i zagranicą",
      "Nauka jazd, zawody narciarskie, imprezy towarzyszące",
    ],
    readMore: "Czytaj więcej",
  },
  en: {
    pageTitle: "O nas",
    meetForsail: (
      <>
        Poznaj <span>Forsail</span>
      </>
    ),
    meetDescr: (
      <>
        Firma Forsail powstała w 2004 roku. Forsail współtworzy zespół
        doświadczonych i kreatywnych ludzi, specjalizujących się w obsłudze
        klientów biznesowych, w zakresie podróży motywacyjnych (Incentive) oraz
        programów teambuldingowych nastawionych na aktywne formy turystyki.
        Pomagamy w budowaniu i udoskonalaniu relacji oraz procesów
        komunikacyjnych w zespołach oraz relacjach z klientami. <br />
        <br /> Wykorzystujemy oryginalną i nowoczesną metodę budowy wizerunku
        opartą na marketingu bezpośrednim, którego narzędziami jest organizacja
        imprez typu incentive oraz promotional events.
      </>
    ),
    iconList1Title: (
      <>
        Gdzie stawiamy na <span>doświadczenie</span>
      </>
    ),
    iconList1: [
      "16-letnie doświadczenie w organizacji incentive szytych na miarę",
      "Ponad 500 zrealizowanych imprez firmowych w kraju i zagranicą",
      "Ponad 25.000 uczestników wzięło udział w imprezach organizowanych przez Forsail",
    ],
    iconList2Title: (
      <>
        Gdzie stawiamy na <span>świat</span>
      </>
    ),
    iconList2: [
      "Lider w dziedzinie incentive pod żaglami – autorskie programy: Mazury, Bałtyk, Morze Śródziemne, Karaiby, Seszele, Tajlandia",
      "Czartery jachtów w kraju i zagranicą – sprawdzeni armatorzy, najlepsze żeglarskie destynacje",
      "Stała i doświadczona kadra",
    ],
    goodOrganization: (
      <>
        Gdzie stawiamy na <span>dobrą organizację</span>
      </>
    ),
    goodOrganizationCards: [
      "Organizacja regat żeglarskich",
      "Rejsy firmowe – propozycja dla ciekawych świata, doskonały sposób na integrację",
      "Spotkania VIP na jachtach – wyjątkowe, ekskluzywne spotkania na megajachtach dla niewielkich grup",
      "Wyjazdy egzotyczne – szeroka oferta wyjazdów incentives w najpiękniejsze regiony świata",
    ],
    safety: (
      <>
        Gdzie stawiamy na <span>bezpieczeństwo</span>
      </>
    ),
    safetyDescr:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    safetyCards: [
      "Najwyższy poziom bezpieczeństwa na wodzie",
      "Polisa ubezpieczeniowa OC",
      "Wpis do rejestru Organizatorów Turystyki i Pośredników Turystycznych",
      "Gwarancja OC organizatora turystyki – cały świat",
    ],
    winterToo: (
      <>
        Również <span>zimą</span>
      </>
    ),
    winterBlocks: [
      "Posiadamy własną Szkołę narciarską Forsail Ski Club",
      "Organizujemy wyjazdy narciarskie w Polsce i zagranicą",
      "Nauka jazd, zawody narciarskie, imprezy towarzyszące",
    ],
    readMore: "Czytaj więcej",
  },
  de: {
    pageTitle: "O nas",
    meetForsail: (
      <>
        Poznaj <span>Forsail</span>
      </>
    ),
    meetDescr: (
      <>
        Firma Forsail powstała w 2004 roku. Forsail współtworzy zespół
        doświadczonych i kreatywnych ludzi, specjalizujących się w obsłudze
        klientów biznesowych, w zakresie podróży motywacyjnych (Incentive) oraz
        programów teambuldingowych nastawionych na aktywne formy turystyki.
        Pomagamy w budowaniu i udoskonalaniu relacji oraz procesów
        komunikacyjnych w zespołach oraz relacjach z klientami. <br />
        <br /> Wykorzystujemy oryginalną i nowoczesną metodę budowy wizerunku
        opartą na marketingu bezpośrednim, którego narzędziami jest organizacja
        imprez typu incentive oraz promotional events.
      </>
    ),
    iconList1Title: (
      <>
        Gdzie stawiamy na <span>doświadczenie</span>
      </>
    ),
    iconList1: [
      "16-letnie doświadczenie w organizacji incentive szytych na miarę",
      "Ponad 500 zrealizowanych imprez firmowych w kraju i zagranicą",
      "Ponad 25.000 uczestników wzięło udział w imprezach organizowanych przez Forsail",
    ],
    iconList2Title: (
      <>
        Gdzie stawiamy na <span>świat</span>
      </>
    ),
    iconList2: [
      "Lider w dziedzinie incentive pod żaglami – autorskie programy: Mazury, Bałtyk, Morze Śródziemne, Karaiby, Seszele, Tajlandia",
      "Czartery jachtów w kraju i zagranicą – sprawdzeni armatorzy, najlepsze żeglarskie destynacje",
      "Stała i doświadczona kadra",
    ],
    goodOrganization: (
      <>
        Gdzie stawiamy na <span>dobrą organizację</span>
      </>
    ),
    goodOrganizationCards: [
      "Organizacja regat żeglarskich",
      "Rejsy firmowe – propozycja dla ciekawych świata, doskonały sposób na integrację",
      "Spotkania VIP na jachtach – wyjątkowe, ekskluzywne spotkania na megajachtach dla niewielkich grup",
      "Wyjazdy egzotyczne – szeroka oferta wyjazdów incentives w najpiękniejsze regiony świata",
    ],
    safety: (
      <>
        Gdzie stawiamy na <span>bezpieczeństwo</span>
      </>
    ),
    safetyDescr:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    safetyCards: [
      "Najwyższy poziom bezpieczeństwa na wodzie",
      "Polisa ubezpieczeniowa OC",
      "Wpis do rejestru Organizatorów Turystyki i Pośredników Turystycznych",
      "Gwarancja OC organizatora turystyki – cały świat",
    ],
    winterToo: (
      <>
        Również <span>zimą</span>
      </>
    ),
    winterBlocks: [
      "Posiadamy własną Szkołę narciarską Forsail Ski Club",
      "Organizujemy wyjazdy narciarskie w Polsce i zagranicą",
      "Nauka jazd, zawody narciarskie, imprezy towarzyszące",
    ],
    readMore: "Czytaj więcej",
  },
};

import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

export default function GoogleMaps ({ lat, lon, hei })
{
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDC2CYQ0Q3ZrXWUC1IJ8sGKli26wcHav6c"
  })

  if (!isLoaded) {
    return <></>;
  }

  return (
    <>
      <GoogleMap 
        center={{lat: lat, lng: lon}}
        zoom={15}
        mapContainerStyle={{
          width: '100%',
          height: hei,
          borderRadius: '10px'
        }}
      >
        <Marker position={{lat: lat, lng: lon}} />
      </GoogleMap>
    </>
  )
}
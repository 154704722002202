export const Translations = {
  pl: {
    faq: [
      {
        title: "Czy muszę mieć ukończone 18 lat, by zapisać się na kurs?",
        body: "Tak. Kurs przeznaczony jest dla osób pełnoletnich. Aby zapisać się na kurs, w dniu wpisu należy mieć ukończone 18 lat."
      },
      {
        title: "Jak wygląda egzamin na Jachtowego Sternika Morskiego?",
        body: "Egzamin na Jachtowego Sternika Morskiego składa się z dwóch części – praktycznej i teoretycznej. Aby zaliczyć część teoretyczną, należy odpowiedzieć na co najmniej 65 spośród 75 pytań oraz bezbłędnie rozwiązać zadanie nawigacyjne. W części praktycznej sprawdzeniu będą podlegały różnorodne manewry."
      },
      {
        title: "Czy oferujecie zniżki na egzamin?",
        body: "Ogólny koszt egzaminu wynosi 350 zł, ale dla osób uczących się do 26. roku życia przewidziana jest zniżka w wysokości 50% tej kwoty."
      },
      {
        title: "Co warto zabrać ze sobą na szkolenie?",
        body: "Na szkolenie polecamy zabrać ze sobą ochronę przed deszczem w postaci kurtki oraz spodni przeciwdeszczowych, ubranie oraz buty na zmianę, ciepłą bluzę lub sweter, czapkę z daszkiem, krem z filtrem UV oraz okulary przeciwsłoneczne."
      }
    ],
    yacht: {
      typeOfYacht: "RODZAJ JACHTU", 
      length: "DŁUGOŚĆ", 
      width: "SZEROKOŚĆ", 
      submerge: "ZANURZENIE", 
      cabins: "ILOŚĆ KABIN", 
      toilets: "ILOŚĆ WC", 
      grota: "POWIERZCHNIA GROTA", 
      foka: "POWIERZCHNIA FOKA", 
      fins: "PŁETWY STEROWE", 
    }
  }
}
import styled from "styled-components";
import { Accordion as LibAccordion, AccordionItem as Item } from "@szhsin/react-accordion";
import { useContext } from "react";
import { LangContext } from "../../App";
import { Translations } from "./Lang";

const Wrapper = styled.div`
  width: 100%;
  margin-top: 21px;
`;

const ItemWithChevron = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={
      <>
        {header}
        <img className="chevron-down" src="./ico/accordion-arrow.svg" alt="Rozwiń" />
      </>
    }
  />
);

const AccordionItem = styled(ItemWithChevron)`
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.15);
  .szh-accordion__item {
    &-btn {
      border: none;
      cursor: pointer;
      border-radius: 5px;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 12px 16px;
      font-family: Gilroy-Light;
      font-size: 18px;
      font-weight: 400;
      color: #133b51;
      background-color: #fff;
      text-align: left;
    }

    &-content {
      font-family: Gilroy-Light;
      font-size: 16px;
      font-weight: 400;
      color: #133b51;
      transition: height 0.3s cubic-bezier(0, 0, 0, 1);
    }

    &-panel {
      padding: 12px 16px 20px 16px;
    }

    & b { /* FIXME: Why doesnt it work? */
      font-family: Gilroy-ExtraBold !important;
    }
  }

  .chevron-down {
    margin-left: auto;
    transition: transform 0.3s cubic-bezier(0, 0, 0, 1);
  }

  &.szh-accordion__item--expanded {
    .szh-accordion__item {
      &-btn {
        font-family: Gilroy-ExtraBold;
      }
    }
    .chevron-down {
      transform: rotate(180deg);
    }
  }
`;

export default function Accordion ({})
{
  const { lang } = useContext(LangContext);

  return (
    <Wrapper>
      <LibAccordion transition transitionTimeout={300}>
        {Translations[lang].faq.accordion.map((item, key) => (
          <AccordionItem header={item.title} key={key} initialEntered={key == 1}>
            {item.descr}
          </AccordionItem>
        ))}
      </LibAccordion>
    </Wrapper>
  )
}
import styled from "styled-components"
import { SectionTitle } from "./Yacht"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../../App"
import GoogleMaps from "../../../Components/GoogleMaps/GoogleMaps"

const Wrapper = styled.div`
  margin-top: 70px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 36px;

  @media (max-width: 767px) {
    display: block;
  }
`

const Left = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-weight: 400;
  color: #133b51;

  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
`

const Box = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 16px;
  > img {
    width: 29px;
    height: 34px; 
  }
`

const Name = styled.div`
  font-size: 20px;
  color: #32c8d2;
`

const Props = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-family: 'Gilroy-Light';
`

export default function BaseDetails ({ base, country, city, address, lat, lon })
{
  const { lang } = useContext(LangContext);

  const location = [city, address, country].filter(n => n !== "").join(" | ");

  return (
    <Wrapper>
      <Left>
        <SectionTitle>
          {Translations[lang].baseDetails.title}
        </SectionTitle>
        <Box>
          <img src="/ico/pointer.svg" />
          <div>
            <Name>
              {base}
            </Name>
            <Props>
              {location}
            </Props>
          </div>
        </Box>
      </Left>
      <GoogleMaps lat={lat} lon={lon} hei={255} />
    </Wrapper>
  )
}

export const Translations = {
  pl: {
    title: "404 Nie znaleziono strony.",
    descr: "Wygląda na to, że strona, którą chcesz wyświetlić nie istnieje ",
    btn: "Przejdź na stronę główną"
  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;
/**
 * Sekcja newslettera
 */

import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext, ThemeContext } from "../../App"

const Wrapper = styled.div`
  @media (max-width: 767px) {
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const OutterNewsletter = styled.div`
  width: 100%;
  background-image: url(/newsletter/bg.webp);
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 10px;
  margin-top: ${props => props.$top};

  @media (max-width: 767px) {
    background-size: cover;
    margin-top: 50px;
  }
`

const InnerNewsletter = styled.div`
  box-sizing: border-box;
  padding: ${props => props.$padding};
  border-radius: 10px;
  background: ${params => params.$theme.newsletterGradient};
  display: flex;
  flex-direction: column;
  gap: 17px;
  color: #fff;
  font-family: 'Gilroy';
  @media (max-width: 767px) {
    padding: 20px;
  }
`

const Title = styled.div`
  font-weight: 300;
  color: ${params => params.$theme.newsletterTextColor};
  font-size: 40px;
  > span {
    font-family: 'Gilroy-ExtraBold';
    font-weight: 400;
  }

  @media (max-width: 767px) {
    font-size: 26px;
  }
`

const Description = styled.div`
  font-weight: 300;
  font-size: 24px;
  color: ${params => params.$theme.newsletterTextColor};
  width: 440px;
  @media (max-width: 767px) {
    width: 249px;
    font-size: 20px;
  }
`

const InputBox = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    padding-right: 20px;
  }
`

const MailInput = styled.input`
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  border: 1px solid ${params => params.$theme.newsletterTextColor || "#fff"};
  border-radius: 10px;
  background: transparent;
  padding: 16px 10px;
  outline: none;
  width: 430px;

  &::placeholder {
    color: ${params => params.$theme.newsletterTextColor || "#fff"};
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`

const SignIn = styled.button`
  box-sizing: border-box;
  background-color: ${params => params.$theme.newsletterBtnBackground || params.$theme.primaryColor};
  font-family: 'Gilroy-ExtraBold';
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  color: ${params => params.$theme.newsletterTextColor || "#fff"};
  padding: 14px 33px;
  border: none;
  border-radius: 10px;
`

export default function Newsletter ({ padding = '40px 87px', top = '0px'})
{
  const {lang, setLang} = useContext(LangContext);
  const theme = useContext(ThemeContext);

  return (
    <Wrapper>
      <OutterNewsletter $top={top}>
        <InnerNewsletter $padding={padding} $theme={theme}>
          <Title $theme={theme}>
            {Translations[lang].title}
          </Title>
          <Description $theme={theme}>
            {Translations[lang].description}
          </Description>
          <InputBox>
            <MailInput $theme={theme} type="mail" placeholder={Translations[lang].mail} />
            <SignIn $theme={theme}>
              {Translations[lang].btn}
            </SignIn>
          </InputBox>
        </InnerNewsletter>
      </OutterNewsletter>
    </Wrapper>
  )
}
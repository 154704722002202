import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../../../App"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
  margin-top: 50px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    box-sizing: border-box;
    padding: 0px 15px;
    gap: 30px;
  }
`

const Offer = styled.div`
  width: 100%;
  border-radius: 10px;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.2);
  background-color: #fff;
`

const Image = styled.div`
  width: 100%;
  height: 264px;
  background-image: url(/sub/incentive/company-regatts/${params => params.$imageId}.webp);
  background-size: cover;
  background-position: center center;
  border-radius: 10px 10px 0px 0px;

  @media (max-width: 767px) {
    height: 220px;
  }
`

const Content = styled.div`
  box-sizing: border-box;
  padding: 28px 0px 35px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 767px) {
    padding: 23px 0px 23px 0px;
  }
`

const Title = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  color: #133b51;

  @media (max-width: 767px) {
    font-size: 20px;
  }
`

const Props = styled.div`
  margin-top: 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  @media (max-width: 767px) {
    margin-top: 17px;
  }
`

const Prop = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #133b51;

  > span {
    font-family: 'Gilroy-ExtraBold';
  }
`

const Button = styled.a`
  margin-top: 25px;
  border-radius: 5px;
  background-color: #1a87c5;
  box-sizing: border-box;
  padding: 11px 36px;
  border: none;
  font-family: 'Manrope';
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  @media (max-width: 767px) {
    margin-top: 20px;
  }
`

export default function Offers ()
{
  const { lang } = useContext(LangContext);

  return (
    <Wrapper>
      {Translations[lang].offers.list.map((offer, key) => (
        <Offer key={key}>
          <Image $imageId={key} />
          <Content>
            <Title>
              {offer.title}
            </Title>
            <Props>
              <Prop>
                {Translations[lang].offers.deadline}: <span>{offer.deadline}</span> 
              </Prop>
              <Prop>
                {Translations[lang].offers.departure}: <span>{offer.departure}</span> 
              </Prop>
            </Props>
            <Button>
              {Translations[lang].offers.button}
            </Button>
          </Content>
        </Offer>
      ))}
    </Wrapper>
  )
}
import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../App"
import { formatNumberByCountry } from "../../utils/number-formatter"
import { __renderable_equipment } from "../Yacht/View/Equipment"
import { redirectDocument } from "react-router-dom"
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Wrapper = styled.div`
  box-sizing: border-box;
  padding-bottom: 20px;

  display: flex;
  gap: 28px;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const Details = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

const Detail = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 16px;
  display: flex;
  align-items: center;  
  gap: 7px;

  > img {
    width: 15px;
    translate: 0px -2px;
  }

  > span {
    font-family: 'Gilroy-ExtraBold';
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }
`

const Pipe = styled.div`
  background-color: #32c8d2;
  height: 15px;
  width: 1px;
`

const Location = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 6px;

  > img {
    width: 24px;
    translate: 0px -5px;
    margin-right: 6px;
  }
`


const Sections = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  font-family: 'Gilroy-Light';
  font-weight: 400;
  color: #133b51;
  box-sizing: border-box;
`

const Top = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const Middle = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 20px;
`

const Bottom = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

const PriceTag = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 32px;

  @media (max-width: 767px) {
    font-size: 28px;
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 3px;
`

const MiddleExtras = styled.div`
  width: 500px;
  display: grid;
  grid-template-columns: 0.45fr 0.55fr;
  gap: 20px;
`

export default function SkeletonItem ()
{

  return (
    <Wrapper>
      <Skeleton width={230} height={290}/>
      <Sections>
        <Top>
          <Left>
            <Skeleton width={250} height={25} />
            <Details>
              <Skeleton width={300} height={20} />
            </Details>
            <Location>
              <Skeleton width={200} height={30} />
            </Location>
          </Left>
          <Right>
            <Skeleton width={65} height={16} />
            <Skeleton width={90} height={16} />
          </Right>
        </Top>
        <Middle>
          <MiddleExtras>
            <Skeleton width={216} height={113} />
            <Skeleton width={264} height={113} />
          </MiddleExtras>
          <Right>
            <Detail>
              <Skeleton width={90} height={19} />
            </Detail>
            <PriceTag>
              <Skeleton width={150} height={40} />
            </PriceTag>
            <Detail>
              <Skeleton width={120} height={19} />
            </Detail>
          </Right>
        </Middle>
        <Bottom>
          <Left>
            <Details>
              <Skeleton width={400} height={20} />
            </Details>
          </Left>
          <Right>
            <Skeleton width={180} height={54} />
          </Right>
        </Bottom>
      </Sections>
    </Wrapper>
  )
}

export const Translations = {
  pl: {
    title: <>Statki <span>Forsail</span></>,
    btn: "Wszystkie statki",
    altTitle: "WYRÓŻNIONE JACHTY"
  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;
import { useContext } from "react"
import { FilterModelsContext } from "./ModelsPage"
import styled from "styled-components";
import { LangContext } from "../../App";
import { Translations } from "./Lang";

const Wrapper = styled.div`
  margin-top: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  font-family: 'Gilroy-Light';
  font-size: 16px;
  font-weight: 400;
  color: #133b51;
`

const ArrowWrapper = styled.a`
  text-decoration: none;
  opacity: ${params => params.$opacity};
  ${params => !params.$isDisabled && 'cursor: pointer;'}
  > img {
    width: 6px;
    transform: rotate(${params => params.$rot}deg);
  }

`

const Arrow = function ({ last_page, isForward })
{
  const { filters, setFilters } = useContext(FilterModelsContext);
  const isDisabled = 
    (isForward && filters.page >= last_page) ||
    (!isForward && filters.page == 1);

  const onClick = function (e)
  {
    if (isDisabled) {
      return;
    }

    const navToPage = isForward
      ? filters.page + 1
      : filters.page - 1


    setFilters({...filters, page: navToPage});
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  return (
    <ArrowWrapper 
      $isDisabled={isDisabled}
      $rot={isForward ? 180 : 0} 
      $opacity={isDisabled ? 0.3 : 1.0}
      onClick={onClick}
    >
      <img src="/ico/arrow-page.svg" />
    </ArrowWrapper>
  )
}

export default function Pagination ({ last_page })
{
  const { filters } = useContext(FilterModelsContext);
  const { lang } = useContext(LangContext);

  return (
    <Wrapper>
      <Arrow isForward={false} last_page={last_page} />
      {filters.page} {Translations[lang].pagination.of} {last_page}
      <Arrow isForward={true} last_page={last_page} />
    </Wrapper>
  )
}
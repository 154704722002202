import { useContext, useState } from "react";
import { Form, Input, InputButton, Row, Error } from "./AuthPage";
import { LangContext } from "../../App";
import { Translations } from "./Lang";
import axios from "axios";
import __server from "../../Api";
import styled from "styled-components";
import { ScaleLoader } from "react-spinners";
import { useParams } from "react-router-dom";

const SpinnerBox = styled.div`
  width: 360px;
  height: 264px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Gilroy-Light';
  font-size: 22px;
  font-weight: 400;
  color: #133b51;
  text-align: center;
`

export default function Activate ({})
{
  const { lang } = useContext(LangContext);

  const { mail } = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [btnTimeout, setBtnTimeout] = useState(0);

  return (
    <SpinnerBox>
      <Form>
        <Row>
          {Translations[lang].activate.text}
        </Row>
        {error && <Error message={error} /> }
        <Row>
          <InputButton disabled={btnTimeout != 0} type="button" value={`${Translations[lang].activate.btn} ${btnTimeout != 0 ? '(' + btnTimeout + 's)' : ''}`} onClick={(e) => {
            e.preventDefault();

            setBtnTimeout(60);
            axios.get(`${__server}/user/resent_activation/${mail}`).then((resp) => {
              let sec = 60;
              const intv = setInterval(() => {
                setBtnTimeout(sec);
                if (sec-- <= 0) {
                  clearInterval(intv);
                }
              }, 1000);
            })
            .catch((err) => {
              console.log(err);
              setError(Translations[lang].activate.errors[err.response.status]);
            });

          }} />
        </Row>
      </Form>
    </SpinnerBox>
  )
}
import styled from "styled-components";
import Footer from "../../../../Components/Footer/Footer";
import Newsletter from "../../../../Components/Newsletter/Newsletter";
import ContactSection from "../../../../Components/ContactSection/ContactSection";
import Leaflet from "../../Components/Leaflet/Leaflet";
import AcademyMenu from "../../../../Components/Menu/Factory/AcademyMenu";
import Exam from "./Exam";

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export default function AcademyPage({}) 
{
  return (
    <>
      <AcademyMenu
        subtitle="Academy"
        title="Wypłyń sam na szerokie wody – zapisz się na kurs"
        descr="Oferujemy szeroki wachlarz szkoleń, które pozwolą Ci zdobyć niezbędne umiejętności i certyfikaty, aby rozwijać swoją przygodę na wodzie."
        paddingBottom={166}
      />
      <Wrapper>
        <Leaflet
          name="academy-sternik"
          top={55}
          details="/academy/szkolenie-jachtowy-sternik-morski"
        />
        <Leaflet
          name="academy-zeglarz"
          top={90}
          details="/academy/szkolenie-zeglarz-jachtowy"
        />
        <Exam />
        <Leaflet
          name="academy-doszkolenie"
          top={90}
          details="/academy/kursy-doszkalajace"
        />
        <Leaflet
          name="academy-staz"
          top={70}
          details="/academy/rejsy-stazowe"
        />
        <Newsletter top="64px" />
        <ContactSection top="64px" />
      </Wrapper>
      <Footer />
    </>
  );
}

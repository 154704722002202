import {Translations} from "../Lang";
import styled from "styled-components";
import {useContext} from "react";
import {LangContext, ThemeContext} from "../../../App";
import axios from "axios";
import __server from "../../../Api";

const UserButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #fff;
  border-radius: 5px;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  color: ${params => params.$theme.menuButtonSecondary};
  box-sizing: border-box;
  padding: 14px 20px;
  cursor: pointer;
`

const ContactButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid ${params => params.$theme.menuButtonPrimary};
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 400;
  color: ${params => params.$theme.menuButtonPrimary};
  box-sizing: border-box;
  padding: 14px 35px;
		
	@media (max-width: 767px) {
		border-color: #fff;
		color: #fff;
	}
`

export default function MenuButtons ()
{
	const { lang } = useContext(LangContext);
	const theme = useContext(ThemeContext);

  const isLoggedIn = localStorage.getItem('bearer_token') !== null;


  const onLogout = function (e)
  {
    axios.get(`${__server}/user/logout`).then((resp) => {
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
      localStorage.removeItem('bearer_token');
      window.location.href = '/';
    })
  }

  const RenderUserButton = function ()
  {
    if (window.location.pathname === '/profil') {
      return (
        <UserButton $theme={theme} onClick={onLogout}>
          Wyloguj się
        </UserButton>
      )
    }

    return (
			<UserButton href={isLoggedIn ? "/profil" : "/logowanie"} $theme={theme}>
        {isLoggedIn ? 'Moje konto' : 'Zaloguj się'}
			</UserButton>
    )
  }

	return (
		<>
			<ContactButton href='/kontakt' $theme={theme}>
				{Translations[lang].contact}
			</ContactButton>
      <RenderUserButton />
		</>
	)
}
import { useContext } from "react";
import styled from "styled-components"
import { LangContext } from "../../../App";
import { Translations } from "../Lang";
import { FilterModelsContext } from "../ModelsPage";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 9px;
`

const Kind = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Gilroy-Light';
`

const Checkbox = styled.input.attrs({type: 'checkbox'})`
  appearance: none;
  width: 16px;
  height: 16px;
  background-color: white;
  border: 1px solid #32C8D2;
  border-radius: 2px;
  display: inline-block;
  position: relative;

  &:checked {
    background-color: #32C8D2;
  }

  &:checked::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px rgba(50, 200, 210, 0.5);
  }
`

export default function Kinds ()
{
  const { lang } = useContext(LangContext);
  const { filters, setFilters } = useContext(FilterModelsContext);

  const kindList = [0, 1, 2, 3];


  const toggleKind = function (id)
  {
    if (filters.kind.includes(id)) {
      setFilters({...filters, kind: filters.kind.filter(n => n != id), page: 1});
      return;
    }

    const kinds = [...filters.kind];
    kinds.push(id);
    setFilters({...filters, kind: kinds, page: 1});
  }

  return (
    <Wrapper>
      {kindList.map((id, key) => (
        <Kind key={key}>
          <Checkbox checked={filters.kind.includes(id)} onChange={(e) => toggleKind(id)} />
          {Translations[lang].filters.kinds[id]}
        </Kind>
      ))}
    </Wrapper>
  )
}

export const Translations = {
  pl: {
    title: "Szkolenia Menadżerskie",
    descr: <>
      Szkolenia outdoor sprawdzają się, kiedy jest potrzeba zintegrowania ludzi, utworzenia zespołu, wyznaczenia nowych ról, “wyjęcia” z istniejącego kontekstu/środowiska (firmy) po to aby odizolować od rutynowych zadań, zachowań czy działań. Dają możliwość pracy na neutralnym, innym gruncie, <b>dzięki czemu można usprawniać , wprowadzać innowacje, udoskonalać  to co wymaga zmiany.</b>
      <br/><br/>
      Realizacja projektów outdoorowych może służyć rekreacji, ale także nabywaniu nowych kompetencji czy realizacji konktretych celów. Takim gruntem może być jacht.
      </>,

    cardTopLeft: {
      title: "Przykładowe Tematy Szkoleń",
      contents: [
        "Budowanie zespołu",
        "Zarządzanie zespołem",
        "Komunikacja",
        "Zarządzanie projektem",
        "Narzędzia coachingowe w zarzadzaniu zespołem",
        "Coaching zespołowy",
        "Praca z problemem",
        "Podejmowanie decyzji"
      ]
    },
    cardTopRight: {
      title: "Jak to działa?",
      contents: "Jacht jest przestrzenią na której zachodzą wszelkie procesy społeczne: współpraca, rywalizacja, dezorganizacja, reorganizacja, rozwój, postęp, konflikty. Bywa różnie. Ta przestrzeń jest miejscem do identyfikacji i zrozumienia właśnie takich procesów. Podjęcia decyzji co jest do zmiany i wypracowania najlepszych możliwych sposobów na dokonanie pożadanej zmiany."
    },
    cardMiddleLeft: {
      title: "Efektem tego mogłby być:",
      contents: [
        "Realizacja celów w szybszy, inny sposób",
        "Wypracowanie jednolitych i zrozumiałych dla wszystkich zasad i procedur",
        "Indywidualne zaangażowanie w ich stworzenie, przestrzeganie i egzekwowanie",
        "Zintegrowanie wartości z zachowaniami",
        "Uzyskanie większej odpowiedzialności indywidualnej za realizację wspólnych celów",
        "Współpraca miedzy członkami zespołu, który doświadczył efektów synergii",
        "Lepsza i skuteczniejsza komunikacja",
        "Wzmocnienie myślenia w kategoriach MY a nie JA",
        "Szacunek, zaufanie i otwartość",
        "Nowe lepsze relacje."
      ]
    },
    cardMiddleRight: {
      title: "Dlaczego szkolenie na jachcie?",
      contents: [
        "Dla dokonania pożądanej zmiany na wielu poziomach: zachowań, umiejętności, przekonań, wartości, tożsamości, indywidualnie i grupowo",
        "Akceleracja zmian",
        "Dla wyższej efektywności inwestycyji. Czas szkoleniowy versus cena",
        "Praktyczne ćwiczenia od pierwszej do ostatniej minuty szkolenia, indywidualne i grupowe. W związku z tym ciągłe uczestnictwo w procesie",
        "Dla uzyskania głębszej i trwałej zmiany, dzięki metaforze jaką tworzy zmiana środowiska",
        "Dla skumulowania procesu (na czas rejsu) lub rozciągnięcia (kilka miesięcy) w zależności od rezultatu, który ma być uzyskany",
        "Dla możliwości dywersyfikowania poziomu szkolenia na różne grupy pracownicze: Pracownicy szeregowi, menagerowie, zarząd. Działania służące uwspólnieniu doświadczeń na bazie jednolitej platformy jaką jest żeglarstwo"
      ]
    },
    cardBottom: {
      title: "Czym to jest?",
      contents: [
        "Jest nauką 24h na dobę",
        "Uczeniem się o sobie, o innych, o nas. Kim jestem dla siebie, kim jestem dla innych? W różnych kontekstach. Co robię i jak to robię? Czemu to służy i jaki jest tego efekt?",
        "Koniecznością odniesienia do różnych zachodzących sytuacji inerpersonalnych, konfliktów, które trzeba rozwiązać tu i teraz.",
        "Zaangażowaniem w realizację celu i celów pośrednich w innym kontekście, z innej perspektywy"
      ]
    }
  }
};


Translations.en = Translations.pl;
Translations.de = Translations.pl;
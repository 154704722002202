import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../App"
import { formatNumberByCountry } from "../../utils/number-formatter"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 36px;
  margin-bottom: 40px;
`

const Left = styled.div`
  font-weight: 400;
  color: #133b51;
`

const ObligatoryPayments = styled.div`
  border: 1px solid #32c8d2;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 15px 10px;
`

const Title = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 24px;
`

const Payment = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: 'Gilroy-Light';
  font-size: 20px;
  color: #133b51;

  > bdi {
    font-family: 'Gilroy-ExtraBold';
    font-size: 40px;
  }
`

const Name = styled.div`
  width: 200px;
`

const Payments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;

  box-sizing: border-box;
  padding: 0px 8px;
  margin-top: 15px;
`

const SmallRow = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0px 23px;
  font-family: 'Gilroy-Light';
  font-size: 14px;
  margin-top: 9px;
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Dates = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
`

const Date = styled.div`
  border: 1px solid #32c8d2;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px 14px;
  display: flex;
  align-items: center;
  gap: 16px;

  > img {
    flex-shrink: 0;
    width: 30px;
  }

  font-weight: 400;
  color: #133b51;
`

const DateBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`

const Label = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 15px;
`

const Stamp = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 20px;
`

const Summary = styled.div`
  box-sizing: border-box;
  padding-left: 80px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const MidRow = styled.div`
  display: flex;
  font-family: 'Gilroy-Light';
  font-weight: 400;
  color: #133B51;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
`

const BookingButton = styled.a`
  width: fit-content;
  align-self: flex-end;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: 10px;
  background-color: #32c8d2;
  border-radius: 10px;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  box-sizing: border-box;
  padding: 16px 65px;
`

export default function Section ({ currency, startPrice, obligatoryExtrasPrice, securityDeposit, commissionPercentage, commissionValue, dateTo, dateFrom, discountPercentage, price })
{
  const { lang } = useContext(LangContext);

  return (
    <Wrapper>
      <Left>
        <ObligatoryPayments>
          <Title>
            {Translations[lang].obligatoryPayment}
          </Title>
          <Payments>
            <Payment>
              <Name>
                {Translations[lang].yachtPrice}
              </Name>
              {startPrice} {currency}
            </Payment>
            <Payment>
              <Name>
                {Translations[lang].charterPrice}
              </Name>
              x {currency}
            </Payment>
            <Payment>
              <Name>
                {Translations[lang].sudoPayments}
              </Name>
              {obligatoryExtrasPrice} {currency}
            </Payment>
            <Payment>
              <Name>
                {Translations[lang].securityDeposit}
              </Name>
              {securityDeposit} {currency}
            </Payment>
          </Payments>
        </ObligatoryPayments>
        <SmallRow>
          <div>
            {Translations[lang].commission} (%)
          </div>
          {commissionPercentage}%
        </SmallRow>
        <SmallRow>
          <div>
            {Translations[lang].commission} ({currency})
          </div>
          {commissionValue} {currency}
        </SmallRow>
      </Left>
      <Right>
        <Dates>
          <Date>
            <img src="/ico/calendar.svg" />
            <DateBox>
              <Label>
                {Translations[lang].dateFrom}
              </Label>
              <Stamp>
                {dateFrom.split(' ').at(0)}
              </Stamp>
            </DateBox>
          </Date>
          <Date>
            <img src="/ico/calendar.svg" />
            <DateBox>
              <Label>
                {Translations[lang].dateTo}
              </Label>
              <Stamp>
                {dateTo.split(' ').at(0)}
              </Stamp>
            </DateBox>
          </Date>
        </Dates>
        <Summary>
          <MidRow>
            {Translations[lang].securityDeposit}: <bdi>{formatNumberByCountry(securityDeposit, lang, 2)} {currency}</bdi>
          </MidRow>
          <MidRow>
            {Translations[lang].discount}: <bdi>{discountPercentage} %</bdi>
          </MidRow>
          <Payment>
            {Translations[lang].price}: <bdi>{formatNumberByCountry(price, lang)} {currency}</bdi>
          </Payment>
          <BookingButton>
            {Translations[lang].bookingButton}
          </BookingButton>
        </Summary>
      </Right>
    </Wrapper>
  )
}
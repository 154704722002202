import styled from "styled-components"
import { LangContext } from "../../../App"
import { useContext } from "react"
import { Translations } from "./Lang"

const Wrapper = styled.div`

`

const Row = styled.div`
  box-sizing: border-box;
  padding: 9px 16px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  font-size: 16px;
  font-weight: 400;
  color: #133b51;

  &&:nth-child(2n + 1) {
    background-color: #ddf5f8;
  }
`

const Label = styled.div`
  font-family: 'Gilroy-ExtraBold';
`

const Value = styled.div`
  font-family: 'Gilroy-Light';
  text-align: right;
`

const fitValue = function (value)
{
  const __max_value_len = 26;
  return value.length > __max_value_len
    ? value.substr(0, __max_value_len - 3) + "..."
    : value;
}

export default function Column ({ details })
{
  const { lang } = useContext(LangContext);

  return (
    <Wrapper>
      {details.map((detail, key) => (
        <Row key={key}>
          <Label>
            {Translations[lang].details[detail.name]}:
          </Label>
          <Value title={detail.value}>
            {fitValue(detail.value)}
          </Value>
        </Row>
      ))}
    </Wrapper>
  )
}
import styled from "styled-components";
import {Translations} from "./Lang";
import {useContext} from "react";
import {LangContext} from "../../App";

const Wrapper = styled.div`
	margin-top: 47px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 54px;

	@media (max-width: 767px) {
		margin-top: 19px;
		box-sizing: border-box;
		padding: 0px 15px;
		gap: 30px;
    grid-template-columns: 1fr;
	}
`

const Image = styled.div`
	background-image: url(/skippers/who-is.webp);
	background-size: cover;
	height: 439px;
	background-position: center center;
	border-radius: 10px;

	@media (max-width: 767px) {
		height: 250px;
	}
`

const Left = styled.div`
	font-family: 'Gilroy-Light';
	font-weight: 400;
	color: #133b51;
`

const Title = styled.div`
	font-size: 41px;
	> span {
		font-family: 'Gilroy-ExtraBold';
	}

	@media (max-width: 767px) {
		font-size: 24px;
	}
`

const Descr = styled.div`
	margin-top: 27px;
	font-size: 20px;
	
	@media (max-width: 767px) {
		margin-top: 15px;
		font-size: 20px;
	}
`

const Items = styled.div`
	margin-top: 47px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	
	@media (max-width: 767px) {
		margin-top: 30px;
	}
`

const Item = styled.div`
	font-family: 'Gilroy-ExtraBold';
	font-size: 24px;
	
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 11px;	
	
	> img {
		width: 70px;	
		box-shadow: 5px 5px 48px 0px rgba(0, 0, 0, 0.1);
		border-radius: 100px;
	}

	@media (max-width: 767px) {
		font-size: 16px;
	}
`

export default function WhatIsSkipper ()
{
	const { lang } = useContext(LangContext);

	const iconsList = [
		"/ico/experience.svg",
		"/ico/relax.svg",
		"/ico/safety.svg"
	];

  return (
    <Wrapper>
      <Left>
        <Title>
	        {Translations[lang].whatIsSkipper.title}
        </Title>
	      <Descr>
		      {Translations[lang].whatIsSkipper.descr}
	      </Descr>
	      <Items>
		      {Translations[lang].whatIsSkipper.list.map((item, key) => (
		        <Item key={key}>
		          <img src={iconsList[key]} />
			        {item}
		        </Item>
		      ))}
	      </Items>
      </Left>
      <Image/>
    </Wrapper>
  )
}
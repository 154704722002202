import styled from "styled-components";
import {Translations} from "./Lang";
import {useContext} from "react";
import {LangContext} from "../../App";

const Wrapper = styled.div`
	margin-top: 66px;
	color: #133b51;
	font-family: 'Gilroy-Light';
	font-weight: 400;
	text-align: center;

	@media (max-width: 767px) {
		margin-top: 30px;
		text-align: left;
		box-sizing: border-box;
		padding: 0px 15px;
	}
`

const Title = styled.div`
  font-family: 'Gilroy-ExtraBold';
	font-size: 41px;

	@media (max-width: 767px) {
		font-size: 32px;
		line-height: 35px;
	}
`

const Descr = styled.div`
	margin-top: 46px;
	width: 910px;
	font-size: 20px;

	@media (max-width: 767px) {
		margin-top: 30px;
		font-size: 16px;
		width: 100%;
	}
`

const Items = styled.div`
	margin-top: 34px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 76px;

	@media (max-width: 767px) {
		margin-top: 27px;
		grid-template-columns: 1fr;
		gap: 19px;
	}
`

const Item = styled.div`
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.2);
`

const Image = styled.div`
	background-image: url(${params => params.$src});
	background-size: cover;
	background-position: center center;
	height: 191px;
	border-radius: 10px 10px 0px 0px;
`

const Content = styled.div`
	box-sizing: border-box;
	padding: 12px 12px 30px 12px;
	font-size: 16px;

	@media (max-width: 767px) {
		text-align: center;
	}
`

const Heading = styled.div`
	font-family: 'Gilroy-ExtraBold';
	text-transform: uppercase;
	margin-bottom: 12px;
`


export default function Preferences ()
{
	const { lang } = useContext(LangContext);

	const imageList = [
		"/skippers/turist.webp",
		"/skippers/relax.webp",
		"/skippers/sail.webp"
	];

	return (
		<Wrapper>
			<Title>
				{Translations[lang].preferences.title}
			</Title>
			<Descr>
				{Translations[lang].preferences.descr}
			</Descr>
			<Items>
				{Translations[lang].preferences.list.map((item, key) => (
					<Item key={key}>
						<Image $src={imageList[key]} />
						<Content>
							<Heading>
								{item.title}
							</Heading>
							{item.descr}
						</Content>
					</Item>
				))}
			</Items>
		</Wrapper>
	)
}
import styled from "styled-components";
import {Translations} from "./Lang";
import {useContext} from "react";
import {LangContext} from "../../App";

const Wrapper = styled.div`
	margin-top: 55px;
	display: grid;
	grid-template-columns: 0.666fr 0.333fr;
	gap: 31px;

	@media (max-width: 767px) {
		margin-top: 30px;
    grid-template-columns: 1fr;
    box-sizing: border-box;
    padding: 0px 15px;
	}
`

const Left = styled.div`
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	background-image: url(/skippers/pricing.webp);
	background-size: cover;
	background-position: center center;

  font-weight: 400;
  color: #fff;

	@media (max-width: 767px) {
		box-sizing: border-box;
		padding: 42px 23px 20px 23px;
	}
`

const Right = styled.div`
	display: flex;
	flex-direction: column;
	gap: 18px;
`

const Card = styled.div`
	background-image: url(${params => params.$bg});
	background-size: cover;
	background-position: center center;
	border-radius: 10px;
`

const CardSuperImportantOverlay = styled.div`
	width: 70%;
	background-color: #1a87c5CC;
	box-sizing: border-box;
	padding: 20px;
	border-radius: 10px 0px 0px 10px;
`
 const CardTitle = styled.div`
	font-family: 'Gilroy-ExtraBold';
	font-size: 30px;
	color: #fff;
	width: 222px;
`

const CardButton = styled.a`
	text-decoration: none;
	font-family: 'Gilroy-ExtraBold';
	font-size: 20px;
	text-transform: uppercase;
	color: #fff;
	box-sizing: border-box;
	border: 2px solid #fff;
	padding: 14px 20px 10px 20px;
	border-radius: 5px;
	margin-top: 15px;
	display: block;
	width: fit-content;
`

const Title = styled.div`
	font-family: 'Gilroy-ExtraBold';
	font-size: 41px;
	width: 458px;
	line-height: 45px;

	@media (max-width: 767px) {
		font-size: 27px;
		line-height: 30px;
		width: 100%;
	}
`

const Descr = styled.div`
	margin-top: 24px;
	font-family: 'Gilroy-Light';
	font-size: 20px;
	width: 616px;
	
	@media (max-width: 767px) {
		margin-top: 15px;
		font-size: 14px;
		width: 286px;
	}
`

const Subtitle = styled.div`
	margin-top: 45px;
	font-family: 'Gilroy-ExtraBold';
	font-size: 20px;

	@media (max-width: 767px) {
		margin-top: 30px;
		font-size: 14px;
		width: 200px;
	}
`

const Table = styled.div`
	margin-top: 24px;
	background-color: rgba(255, 255, 255, 0.95);
	width: 626px;
	border-radius: 10px;
	
	font-family: 'Gilroy-ExtraBold';
	font-size: 16px;
	font-weight: 400;
	color: #133b51;

	@media (max-width: 767px) {
		width: 100%;
		font-size: 14px;
	}
`

const Row = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	
	&:first-child {
		border-bottom: 1px solid #32c8d2;
	}
`

const Column = styled.div`
	text-align: center;
	box-sizing: border-box;
	padding: 12px;
	
	&:not(:last-child) {
    border-right: 1px solid #32c8d2;
	}
`

export default function Pricing ()
{
	const { lang } = useContext(LangContext);

	const linkList = [
		"",
		""
	];

	const imageList = [
		"/skippers/reggatas.webp",
		"/skippers/cruises.webp",
	]

	return (
		<Wrapper>
			<Left>
				<Title>
					{Translations[lang].pricing.title}
				</Title>
				<Descr>
					{Translations[lang].pricing.descr}
				</Descr>
				<Table>
					<Row>
						<Column />
						<Column>{Translations[lang].pricing.table.offer1title}</Column>
						<Column>{Translations[lang].pricing.table.offer2title}</Column>
					</Row>
					<Row>
						<Column>{Translations[lang].pricing.table.skipper}</Column>
						<Column>{Translations[lang].pricing.table.offer1value}</Column>
						<Column>{Translations[lang].pricing.table.offer2value}</Column>
					</Row>
				</Table>
				<Subtitle>
					{Translations[lang].pricing.subtitle}
				</Subtitle>
			</Left>
			<Right>
				{Translations[lang].pricing.cardList.map((item, key) => (
					<Card key={key} $bg={imageList[key]}>
						<CardSuperImportantOverlay>
							<CardTitle>
								{item.title}
							</CardTitle>
							<CardButton href={linkList[key]}>
								{item.button}
							</CardButton>
						</CardSuperImportantOverlay>
					</Card>
				))}
			</Right>
		</Wrapper>
	)
}
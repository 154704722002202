import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../../../App"

const Wrapper = styled.div`
  margin-top: 80px;
`

const Title = styled.div`
  text-align: center;
  font-family: 'Gilroy-Light';
  font-size: 32px;
  font-weight: 400;
  color: #1a87c5;

  > span {
    font-family: 'Gilroy-ExtraBold';
  }
`

const Items = styled.div`
  margin-top: 35px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;
`

const Item = styled.a`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.15);
`

const Image = styled.div`
  width: 100%;
  height: 270px;
  border-radius: 10px 10px 0px 0px;

  background-image: url(/sub/incentive/special-events/${params => params.$id }.webp);
  background-size: cover;
  background-position: center center;
`

const Name = styled.div`
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px 15px;

  text-align: center;
  font-family: 'Gilroy-ExtraBold';
  font-size: 28px;
  font-weight: 400;
  color: #1a87c5;
`

export default function SpecialEventsSection ()
{
  const { lang } = useContext(LangContext);

  return (
    <Wrapper>
      <Title>
        {Translations[lang].specialEvents.title}
      </Title>
      <Items>
      {Translations[lang].specialEvents.items.map((item, key) => (
        <Item href={`/incentive/eventy-specjalne?oferta=${key + 1}`}>
          <Image $id={key} key={key} />
          <Name>
            {item}
          </Name>
        </Item>
      ))}
      </Items>
    </Wrapper>
  )
}
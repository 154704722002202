import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

import { useContext, useEffect, useState } from 'react';
import { FilterModelsContext } from '../ModelsPage';
import styled from 'styled-components';

const Wrapper = styled.div`
  #slider {
    margin-top: 8px;
  }
  #slider .range-slider__range {
    background-color: #32C8D2;
    height: 9px;
  }
  #slider .range-slider__thumb {
    background: #fff;
    border: 1px solid #32C8D2;
    transition: transform 0.3s;
    width: 16px;
    height: 16px;
  }
`

export default function Range ({ min, max, name })
{
  const { filters, setFilters } = useContext(FilterModelsContext);
  const [value, setValue] = useState([min, parseInt(max / 2)]);

  const onUpdateFilters = function ()
  {
    setFilters({
      ...filters, 
      [`min${name}`]: value[0], 
      [`max${name}`]: value[1],
      page: 1
    });
  }

  useEffect(() => {
    setValue([ filters[`min${name}`], filters[`max${name}`] ]);
  }, []);

  return (
    <Wrapper>
      <RangeSlider
        id="slider"
        step = {1}
        min={min}
        max={max}
        value={value}
        onInput={(e) => setValue(e)}
        onThumbDragEnd={onUpdateFilters}
        onRangeDragEnd={onUpdateFilters}
      />
    </Wrapper>
  )
}

export const Translations = {
  pl: {
    contact: 'Kontakt',
    gallery: <>Galeria przeżyć z <span>Forsail</span></>
  },
  en: {
    contact: 'Kontakt',
    gallery: <>Galeria przeżyć z <span>Forsail</span></>
  },
  de: {
    contact: 'Kontakt',
    gallery: <>Galeria przeżyć z <span>Forsail</span></>
  }
}
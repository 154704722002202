import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../App"

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 30px 0px;
  font-family: 'Gilroy-Light';
  font-weight: 400;
  color: #133b51;
  gap: 25px;
`

const Descr = styled.div`
  width: 618px;
  text-align: center;
  font-size: 20px;
`

const Button = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 15px 37px;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  color: #133b51;
  border: 2px solid #133b51;
  border-radius: 10px;
`

export default function NotFound ()
{
  const { lang } = useContext(LangContext);
  return (
    <Wrapper>
      <Descr>
        {Translations[lang].notFound}
      </Descr>
      <Descr>
        {Translations[lang].needHelp}
      </Descr>
      <Button href="/kontakt">
        {Translations[lang].contactUs}
      </Button>
    </Wrapper>
  )
}
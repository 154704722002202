import styled from "styled-components"
import IncentiveMenu from "../../../../Components/Menu/Factory/IncentiveMenu"
import Footer from "../../../../Components/Footer/Footer"
import IncentiveTopSection from "../Components/IncentiveTopSection"
import { Translations } from "./Lang"
import { useContext, useEffect } from "react"
import { LangContext } from "../../../../App"
import ReadMore from "../Components/ReadMore"
import Newsletter from "../../../../Components/Newsletter/Newsletter"
import ContactSection from "../../../../Components/ContactSection/ContactSection"

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Events = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 70px 32px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    box-sizing: border-box;
    padding: 0px 15px;
    gap: 25px;
  }
`

const Event = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.15);
  height: fit-content;
`

const Image = styled.div`
  background-image: url(${params => params.$bg});
  background-size: cover;
  background-position: center;
  height: 276px;
  border-radius: 10px 10px 0px 0px;

  @media (max-width: 767px) {
    height: 250px;
  }
`

const Body = styled.div`
  box-sizing: border-box;
  padding: 28px 52px;
  @media (max-width: 767px) {
    padding: 25px 30px;
  }
`

const Title = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-weight: 400;
  font-size: 32px;
  color: #1a87c5;
  text-align: center;
`

const Props = styled.div`
  margin-top: 27px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-bottom: 35px;

  @media (max-width: 767px) {
    margin-top: 17px;
    margin-bottom: 17px;
    gap: 6px;
  }
`

const Prop = styled.div`
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 16px;
  color: #133b51;
  text-align: center;
  > span {
    font-family: 'Gilroy-ExtraBold';
  }
`

const Descr = styled.div`
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #133b51;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 22px;
  }
`

const List = styled.ul`
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #133b51;

  @media (max-width: 767px) {
    font-size: 16px;
    padding-inline-start: 20px;
  }
`

const Item = styled.li``

export default function SpecialEventsPage ()
{
  const { lang } = useContext(LangContext);

  /** jest tragedia, TODO: przerobić funkcyjnie WyMYsŁ */
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.has('oferta')) {
      const oferta = document.querySelector(`#oferta-${params.get('oferta')}`);
      console.log(oferta)
      if (oferta !== null) {
        oferta.scrollIntoView({behavior: 'smooth'});
      }
    }
  }, []);

  return (
    <>
      <IncentiveMenu
        title={Translations[lang].title}
      />
      <Wrapper>
        <IncentiveTopSection 
          descr={Translations[lang].descr}
        />

        <Events>
          {Translations[lang].events.map((event, key) => (
            <Event key={key} id={`oferta-${key + 1}`}>
              <Image $bg={`/sub/incentive/special-events/${key}.webp`}/>
              <Body>
                <Title>
                  {event.title}
                </Title>
                <Props>
                  <Prop>
                    {Translations[lang].duration}: <span>{event.duration}</span>
                  </Prop>
                  <Prop>
                    {Translations[lang].people}: <span>{event.people}</span>
                  </Prop>
                  <Prop>
                    {Translations[lang].transport}: <span>{event.transport}</span>
                  </Prop>
                </Props>

                <ReadMore minHeight={0} openOnStart={key < 2} >
                  <Descr>
                    {event.descr}
                  </Descr>
                  <List>
                    {event.list.map((item, subkey) => (
                      <Item key={subkey}>
                        {item}
                      </Item>
                    ))}
                  </List>
                </ReadMore>

              </Body>
            </Event>
          ))}
        </Events>

        <Newsletter top="70px" />
        <ContactSection top="75px" />
      </Wrapper>
      <Footer />
    </>
  )
}
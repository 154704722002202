export const Translations = {
  pl: {
    seasons: {
      title: "Żagle od wczesnych lat",
      descr: 'Półkolonie żeglarskie nad Zalewem Zegrzyńskim oraz półkolonie narciarskie i snowboardowe dla dzieci i młodzieży. Różne kategorie wiekowe. Posiadamy unikatowy program, wykwalifikowaną i doświadczoną kadrę, ponad 16 lat doświadczenia w organizowaniu wypoczynku pod żaglami i na nartach.',
      list: [
        'Półkolonie sportowo-żeglarskie',
        'Kolonie żeglarskie',
        'Obozy żeglarskie',
      ],
      btn: 'SPRAWDŹ OFERTĘ'
    },
  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;
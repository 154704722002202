import { useContext } from "react";
import { Link, Title } from "../Abstract/MenuComponents";
import { Translations } from "./Lang";
import { LangContext } from "../../../App";

import Menu from "../Abstract/Menu";
import { IconBlock, IconBlockImage, IconBlockLink } from "../Components/MenuStripe";

export default function PageMenu ({ title, backgroundOverwrite })
{
  const { lang } = useContext(LangContext);

  return (
    <Menu 
      backgroundOverwrite={backgroundOverwrite}
      navigation={<>
        <Link href="/katalog-jachtow">{Translations[lang].home.yachtsCatalogue}</Link>
        <Link href="/nasze-destynacje">{Translations[lang].home.ourDestinations}</Link>
        <Link href="/o-nas">{Translations[lang].home.aboutUs}</Link>
        <Link href="/blog">{Translations[lang].home.blog}</Link>
      </>}

      middleSection={
        <Title>{title}</Title>
      }

      stripeItems={
        <>
          <IconBlock>
            <IconBlockImage src="/ico/phone.svg" />
            <IconBlockLink href="tel:+48668676019">
              +48 668 676 019
            </IconBlockLink>
          </IconBlock>
          <IconBlock>
            <IconBlockImage src="/ico/phone.svg" />
            <IconBlockLink href="tel:+48887234362">
              +48 887 234 362
            </IconBlockLink>
          </IconBlock>
        </>
      }

    />
  )
}
import { useContext } from "react"
import styled from "styled-components";
import { LangContext } from "../../../App";
import { Translations } from "./Lang";

const Wrapper = styled.div`
  margin-top: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  font-family: 'Gilroy-Light';
  font-size: 16px;
  font-weight: 400;
  color: #133b51;

  @media (max-width: 767px) {
    margin-top: 0px;
  }
`

const ArrowWrapper = styled.a`
  text-decoration: none;
  opacity: ${params => params.$opacity};
  ${params => !params.$isDisabled && 'cursor: pointer;'}
  > img {
    width: 6px;
    transform: rotate(${params => params.$rot}deg);
  }

`

export default function Pagination ({ last_page, page, setPage })
{
  const { lang } = useContext(LangContext);

  const Arrow = function ({ isForward })
  {
    const isDisabled = 
      (isForward && page >= last_page) ||
      (!isForward && page == 1);

    const onClick = function (e)
    {
      if (isDisabled) {
        return;
      }

      const navToPage = isForward
        ? page + 1
        : page - 1

      setPage(navToPage);

      const url = new URL(window.location);
      url.searchParams.set('yachtPage', page);
      window.history.pushState({}, '', url);

      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    return (
      <ArrowWrapper 
        $isDisabled={isDisabled}
        $rot={isForward ? 180 : 0} 
        $opacity={isDisabled ? 0.3 : 1.0}
        onClick={onClick}
      >
        <img src="/ico/arrow-page.svg" />
      </ArrowWrapper>
    )
  }

  return (
    <Wrapper>
      <Arrow isForward={false} />
      {page} {Translations[lang].pagination.of} {last_page}
      <Arrow isForward={true} />
    </Wrapper>
  )
}
import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../../../App"

import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import plLocale from 'i18n-iso-countries/langs/pl.json';
import deLocale from 'i18n-iso-countries/langs/de.json';

const Wrapper = styled.div`
  margin-top: 85px;
  display: flex;
  gap: 25px;

  @media (max-width: 767px) {
    margin-top: 37px;
    gap: 25px;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const Left = styled.div`
  width: 50%;
  box-sizing: border-box;
  padding-top: 8px;

  background-image: url(/watermark.png);
  background-size: 507px;
  background-position: bottom right;
  background-repeat: no-repeat;

  @media (max-width: 767px) {
    width: 100%;
    background-size: 350px;
  }
`

const Title = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 32px;
  font-weight: 400;
  color: #133b51;
  > span {
    font-family: 'Gilroy-ExtraBold';
  }

  @media (max-width: 767px) {
    font-size: 24px;
  }
`
const Descr = styled.div`
  margin-top: 22px;
  width: 585px;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  color: #133b51;

  @media (max-width: 767px) {
    font-size: 16px;
    width: 100%;
  }
`

const Button = styled.a`
  margin-top: 35px;
  text-decoration: none;
  font-family: 'Gilroy-ExtraBold';
  font-size: 16px;
  font-weight: 400;
  color: #1a87c5;
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 13px 37px;
  border: 2px solid #1a87c5;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
`

const Right = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  gap: 25px;

  @media (max-width: 767px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`

/**
 * --------------------------------------------------------------------------------
 */

const Card = styled.a`
  text-decoration: none;
  width: 291px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.2);
`

const CardThumb = styled.div`
  height: 182px;
  background-image: url(${props => props.$img});
  background-size: cover;
  background-position: center center;
  border-radius: 10px 10px 0px 0px;
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  box-sizing: border-box;
  padding: 12px 0px 17px 0px;
  align-items: center;
`

const CardTitle = styled.div`
  font-family: 'Manrope';
  font-weight: 800;
  font-size: 14px;
  color: #133b51;
  text-transform: uppercase;
  text-align: center;
  height: 30px;
`

const CardProps = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
`

const CardProp = styled.div`
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 14px;
  color: #133b51;

  > span { 
    font-family: 'Gilroy-ExtraBold';
  }
`

const CardButton = styled.button`
  border-radius: 5px;
  background-color: #32c8d2;
  box-sizing: border-box;
  padding: 11px 36px;
  border: none;
  font-family: 'Manrope';
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
`


export default function ManagementCurses ()
{
  const { lang } = useContext(LangContext);

  countries.registerLocale(plLocale);
  countries.registerLocale(enLocale);
  countries.registerLocale(deLocale);

  return (
    <Wrapper>
      <Left>
        <Title>
          {Translations[lang].managementCurses.title}
        </Title>
        <Descr>
          {Translations[lang].managementCurses.descr}
        </Descr>
        <Button href="/incentive/szkolenia-menadzerskie">
          {Translations[lang].managementCurses.button}
        </Button>
      </Left>
      <Right>
        {Translations[lang].managementCurses.offers.map((offer, key) => (
          <Card key={key} href="/">
            <CardThumb $img={`/sub/incentive/of${key + 1}.webp`} />
            <CardContent>
              <CardTitle>
                {offer.title}
              </CardTitle>
              <CardProps>
                <CardProp>
                  {Translations[lang].managementCurses.localization}: <span> {countries.getName(offer.countryCode, lang)} </span>
                </CardProp>
              </CardProps>
              <CardButton>
                {Translations[lang].managementCurses.checkOutButton}
              </CardButton>
            </CardContent>
          </Card>
        ))}
      </Right>
    </Wrapper>
  )
}
import styled from "styled-components"
import { Translations } from "./Lang"

import { Translations as MenuTranslations } from "../../Components/Menu/Lang"
import { useContext } from "react"
import { LangContext } from "../../App"
import CountryInput from "./Filters/CountryInput"
import YachtCharterInput from "./Filters/YachtCharterInput"
import HeadsInput from "./Filters/HeadsInput"
import CabinsInput from "./Filters/CabinsInput"
import CallendarInput from "./Filters/CallendarInput"
import DaysInput from "./Filters/DaysInput"
import WcInput from "./Filters/WcInput"
import LengthInput from "./Filters/LengthInput"
import KindsInput from "./Filters/KindsInput"
import EquipmentInput from "./Filters/EquipmentInput"

const Bounding = styled.div`
  @media (max-width: 767px) {
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;

  border-radius: 10px;
  box-shadow: 4px 4px 30px 0px rgba(170, 170, 170, 0.15);
  background-color: #f8f8f8;
  box-sizing: border-box;
  padding: 20px 15px;

  height: fit-content;

  font-weight: 400;
  font-family: 'Gilroy-ExtraBold';
  border: 1px solid #32c8d2;
  color: #133b51;
`

const Title = styled.div`
  font-size: 20px;
  text-align: center;
`

const Button = styled.button`
  margin-top: 20px;
  width: fit-content;
  outline: none;
  border: none;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background-color: #32c8d2;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 17px 45px;
`

export default function Filters ()
{
  const { lang } = useContext(LangContext);

  return (
    <Bounding>
      <Wrapper>
        <Title>
          {Translations[lang].searchTitle}
        </Title>
        <KindsInput />
        <CountryInput />
        <CallendarInput />
        <DaysInput />
        <HeadsInput />
        <CabinsInput />
        <WcInput />
        <LengthInput />
        <YachtCharterInput />
        {/* <Button>
          {Translations[lang].search}
        </Button> */}

        {Translations[lang].equipment.map((group, key) => (
          <EquipmentInput group={group} key={key}/>
        ))}

      </Wrapper>
    </Bounding>
  )
}
import styled from "styled-components"

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;

  tr > td {
    padding: ${params => params.$hPadding}px 0px;
  }

	@media (max-width: 767px) {
		tr > td {
			padding: 16px 9px;
		}
  }


`

export const Head = styled.tr`
  font-family: 'Gilroy-ExtraBold';
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  color: #000;
  text-align: center;
  > td {
    padding: 29px 0px;
    box-sizing: border-box;
  }

	@media (max-width: 767px) {
		font-size: 14px;
		> td {
			padding: 0px;
		}
	}
`

export const Row = styled.tr`

  font-family: 'Gilroy-Light';
  font-size: 20px;
  color: #143a51;
  font-weight: 400;

	@media (max-width: 767px) {
		font-size: 14px;
	}

	> td {
    box-sizing: border-box;
  }

  &:nth-child(2n + 1) > td {
    background-color: #f4f4f4;
  }

  &:first-child > td:first-child {
    border-radius: 10px 0px 0px 0px;
  }

  &:first-child > td:last-child {
    border-radius: 0px 10px 0px 0px;
  }

  &:last-child > td:first-child {
    border-radius: 0px 0px 0px 10px;
  }

  &:last-child > td:last-child {
    border-radius: 0px 0px 10px 0px;
  }

	@media (max-width: 767px) {
		> td {
			border-radius: 0px !important;
		}
  }

`

export const Column = styled.td`
  text-align: center;
`

export const Left = styled.div`
  box-sizing: border-box;
  padding-left: 45px;
  text-align: left;

	@media (max-width: 767px) {
		padding-left: 18px;
	}
`
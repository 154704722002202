
export const Translations = {
	pl: {
		title: "Skipperzy",
		top: {
			title: <><span>Lorem ipsum dolor sit amet</span>, consectetur adipiscing elit, sed do eiusmod tempor. </>,
			descr: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"
		},
		whatIsSkipper: {
			title: <>Kim jest <span>Skipper?</span></> ,
			descr: <>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. <br/><br/> Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</>,
			list: [
				"Doświadczenie", "Relaks", "Bezpieczeństwo"
			]
		},
		preferences: {
			title: "Nasi skiepperzy dostosują się do się do Twoich preferencji",
			descr: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in.",
			list: [
				{
					title: "Turystyczne\n",
					descr: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in.\n"
				},
				{
					title: "Rekreacyjne\n",
					descr: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in.\n"
				},
				{
					title: "Żeglarskie\n",
					descr: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,"
				},
			]
		},
		pricing: {
			title: "Ile kosztuje wynajęcie skippera?\n",
			descr: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.\n",
			subtitle: "Lorem ipsum dolor sit amet, consectetur!\n",
			cardList: [
				{
					title: "Organizacja regat żeglarskich\n",
					button: "Regaty Firmowe\n"
				},
				{
					title: "Organizacja rejsów firmowych\n",
					button: "Rejsy Firmowe\n"
				}
			],
			table: {
				offer1title: "7 DNI",
				offer2title: "14 DNI",
				skipper: "SKIPPER",
				offer1value: "1000 EUR",
				offer2value: "1800 EUR",
			}
		},
		faq: {
			title: <>Pytania dotyczące <span>Skipperów</span></>,
			accordion: [
				{
					title: "to",
					descr: "do"
				},
			]
		}
	}
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;

export const Translations = {
  pl: {
    price: 'cena',
    discounts: 'Zniżki',
    faq: [
      {
        title: "Gdzie odbywają się zajęcia?",
        body: "W 2024 roku zajęcia w ramach półkolonii sportowo-żeglarskich odbywać się będą w nowoczesnym i komfortowym ośrodku Górka Szczęśliwicka. "
      },
      {
        title: "Co należy zabrać ze sobą na półkolonie?",
        body: "Zalecamy, aby wyposażyć dziecko w drugie śniadanie, bidon z wodą, ubranie przeciwdeszczowe, ubranie i buty na zmianę, czapkę z daszkiem, krem z filtrem, okulary przeciwsłoneczne i legitymację szkolną. "
      },
      {
        title: "Czy dziecko podczas półkolonii jest ubezpieczone?",
        body: "Tak, w cenę półkolonii wliczony jest koszt ubezpieczenia od następstw nieszczęśliwych wypadków. "
      },
      {
        title: "Jakie dokumenty trzeba wypełnić przed półkoloniami?",
        body: "Warunkiem uczestnictwa w półkoloniach jest zawarcie umowy, zaakceptowanie regulaminu oraz wypełnienie karty kwalifikacyjnej – wszystkie te dokumenty zostaną przesłane do Państwa po wypełnieniu formularza zgłoszeniowego na naszej stronie internetowej. Karta kwalifikacyjna w formie papierowej musi zostać do nas dostarczona pierwszego dnia turnusu."
      }
    ],
    campIcons: [
      "Ponad 20-letnie doświadczenie w organizowaniu zajęć i kursów dla dzieci i młodzieży", 
      "Wieloletnie doświadczenie w półkoloniach letnich i zimowych", 
      "Wykfalifikowana kadra: kierownik, wychowawcy, instruktorzy", 
      "Najwyższe standardy bezpieczeństwa", 
      "Organizator turystyki nr 737", 
      "Organizator pikników dla dzieci", 
      "Polisa ubezpieczeniowa OC", 
      "Gwarancja organizatora turystyki", 
      "Zgłoszenie i nadzór kuratorium oświaty"
    ],
    polkolonieSportowe: {
      price: "1290 zł",
      includedPrice: 'Cena turnusu zawiera',
      deadlines: {
        includedList: [
          "wyżywienie: obiad, podwieczorek (5 dni)",
          "całodzienna opieka wychowawców i instruktorów",
          "zajęcia programowe",
          "2 wycieczki autokarowe nad Jezioro Zegrzyńskie",
          "żeglowanie na jachcie ze sternikiem",
          "upominek",
          "ubezpieczenie NNW"
        ],
        discountsList: [
          "50 zł dla stałych klientów – prosimy o kontakt z naszym biurem w celu uzyskania kodu rabatowego",
          "50 zł za osobę przy rezerwacji więcej niż jednego miejsca"
        ],
      },

      program: {
        title: "RAMOWY PLAN DNIA PÓŁKOLONII SPORTOWO-ŻEGLARSKICH ",
        listTitle: "W ramach zajęć programowych na Uczestników naszych Półkolonii czekają: ",
        list: [
          "kąpiele na basenie otwartym OSiR Ochota",
          "gry sportowe:  siatkówka, koszykówka, badminton",
          "gra terenowa z mapą i kompasem „Poszukiwanie skarbu”",
          "2 wycieczki autokarowe nad Jezioro Zegrzyńskie ",
          "praktyczna nauka żeglowania na jachcie ze sternikiem",
          "zajęcia z ratownikami WOPR „Bezpieczni nad wodą”",
          "zabawa tematyczna „Rejs dookoła świata”",
          "warsztaty plastyczne i eksperymenty „Wiatr i Woda”"
        ],
      },

      place: {
        title: "Miejsce POŁKOLONII ",
        descr: "Zbiórka poranna jaki i odbiór dzieci ma miejsce w siedzibie FORSAIL & Ski Club – ul. Drawska 22, Całoroczny Stok Narciarski Górka Szczęśliwicka.  ",
      },
      faq: [
        {
          title: "Gdzie odbywają się zajęcia?",
          body: "W 2024 roku zajęcia w ramach półkolonii sportowo-żeglarskich odbywać się będą w nowoczesnym i komfortowym ośrodku Górka Szczęśliwicka."
        },
        {
          title: "Co należy zabrać ze sobą na półkolonie?",
          body: "Zalecamy, aby wyposażyć dziecko w drugie śniadanie, bidon z wodą, ubranie przeciwdeszczowe, ubranie i buty na zmianę, czapkę z daszkiem, krem z filtrem, okulary przeciwsłoneczne i legitymację szkolną."
        },
        {
          title: "Czy dziecko podczas półkolonii jest ubezpieczone?",
          body: "Tak, w cenę półkolonii wliczony jest koszt ubezpieczenia od następstw nieszczęśliwych wypadków."
        },
        {
          title: "Jakie dokumenty trzeba wypełnić przed półkoloniami?",
          body: "Warunkiem uczestnictwa w półkoloniach jest zawarcie umowy, zaakceptowanie regulaminu oraz wypełnienie karty kwalifikacyjnej – wszystkie te dokumenty zostaną przesłane do Państwa po wypełnieniu formularza zgłoszeniowego na naszej stronie internetowej. Karta kwalifikacyjna w formie papierowej musi zostać do nas dostarczona pierwszego dnia turnusu."
        }
      ],
    },

    polkolonieZeglarskie: {
      price: "1590 zł",
      includedPrice: 'Cena turnusu zawiera',
      deadlines: {
        includedList: [
          "5 dni zajęć żeglarskich: budowa jachtu, teoria żeglowania, nauka węzłów",
          "codzienne żeglowanie jachtem ze sternikiem",
          "warsztaty z pierwszej pomocy z ratownikiem WOPR",
          "wyżywienie obiad i podwieczorek",
          "opieka wychowawców i instruktorów",
          "dojazd z Warszawy nad Jezioro Zegrzyńskie",
          "upominek żeglarski",
          "ubezpieczenie NNW"
        ],
        discountsList: [
          "50 zł dla stałych klientów – prosimy o kontakt z naszym biurem w celu uzyskania kodu rabatowego",
          "50 zł za osobę przy rezerwacji więcej niż jednego miejsca"
        ],
      },

      program: {
        title: "RAMOWY PLAN DNIA PÓŁKOLONII ŻEGLARSKICH",
        listTitle: "W ramach zajęć programowych na Uczestników naszych Półkolonii żeglarskich czekają:",
        list: [
          "przygotowanie do szkolenia na patent żeglarza jachtowego",
          "zajęcia żeglarskie: teoria żeglowania, budowa jachtu, kierunki wiatru",
          "praktyczna nauka żeglowania na jachcie ze sternikiem",
          "nauka wiązania węzłów żeglarskich",
          "zajęcia z ratownikami WOPR „Bezpieczni nad wodą”",
          "gra nawigacyjna z mapą i kompasem „Poszukiwane skarbu”",
          "nauka i śpiewanie szant przy gitarze",
        ],
      },

      place: {
        title: "Miejsce POŁKOLONII ",
        descr: "Zbiórka poranna jaki i odbiór dzieci ma miejsce w siedzibie FORSAIL & Ski Club – ul. Drawska 22, Całoroczny Stok Narciarski Górka Szczęśliwicka.  ",
      },
      faq: [
        {
          title: "Gdzie odbywają się półkolonie?",
          body: "Nasze półkolonie odbywają się nad Jeziorem Zegrzyńskim. Zbiórka poranna, jaki i odbiór dzieci ma miejsce w siedzibie FORSAIL & Ski Club – ul. Drawska 22, Całoroczny Stok Narciarski Górka Szczęśliwicka."
        },
        {
          title: "Co w sytuacji, gdy dziecko przyjmuje na stałe leki?",
          body: "Jeśli Twoje dziecko na stałe przyjmuje leki, bardzo ważne jest, aby dostarczyć do nas wszystkie lekarstwa wraz z przyklejoną do opakowania kartką zawierającą imię i nazwisko dziecka oraz dokładny sposób dawkowania leku."
        },
        {
          title: "Jak zarezerwować miejsce na półkoloniach?",
          body: "Aby zarezerwować miejsce na półkoloniach, należy wpłacić 300 zł zaliczki. Resztę kwoty należy wpłacić najpóźniej do 30 dni przed rozpoczęciem turnusu."
        },
        {
          title: "Jaki jest numer konta do wpłat?",
          body: "Wpłat prosimy dokonywać na numer konta: 18 1090 1870 0000 0001 1579 5868. W tytule przelewu prosimy wpisać imię i nazwisko dziecka oraz termin turnusu."
        }
      ],
      belowTableList: [
        "Warunkiem rezerwacji miejsca na wybrany turnus jest wpłata zaliczki w wysokości 300 zł, oraz dopłata pełnej kwoty najpóźniej na 30 dni przed rozpoczęciem turnusu.",
        "W przypadku przelewu tradycyjnego prosimy o wpłatę na numer konta: 18 1090 1870 0000 0001 1579 5868, tytuł przelewy: imię i nazwisko dziecka oraz termin turnusu."
      ],
      belowTableList: [
        "Warunkiem rezerwacji miejsca na wybrany turnus jest wpłata zaliczki w wysokości 300 zł, oraz dopłata pełnej kwoty najpóźniej na 30 dni przed rozpoczęciem turnusu.",
        "W przypadku przelewu tradycyjnego prosimy o wpłatę na numer konta: 18 1090 1870 0000 0001 1579 5868, tytuł przelewy: imię i nazwisko dziecka oraz termin turnusu."
      ],
    },

    obozyZeglarskie: {
      price: "3630 zł",
      includedPrice: 'Cena obozu zawiera',
      notincludedPrice: "Cena obozu nie zawiera",
      extraInfo: "MOŻLIWOŚĆ ZDOBYCIA UPRAWNIEŃ MOTOROWODNYCH",
      deadlines: {
        includedList: [
          "zakwaterowanie na jachtach kabinowych Antila 27 lub podobnych",
          "pełne wyżywienie w trakcie rejsu – 3 posiłki (przygotowywane przez uczestników)",
          "opiekę wychowawcy przez cały czas trwania rejsu",
          "upominek – koszulkę T-shirt",
          "ubezpieczenie NNW",
          "składkę na TFG i TFP"
        ],
        discountsList: [
          "50 zł dla stałych klientów – prosimy o kontakt z naszym biurem w celu uzyskania kodu rabatowego",
          "50 zł za osobę przy rezerwacji więcej niż jednego miejsca"
        ],
        notincludedList: [
          "transportu Warszawa – Piaski – Warszawa: 380 zł (płatne dodatkowo)",
          "opłat za sanitariaty w portach i przystaniach  (WC ok. 5 zł, natrysk ok. 20-25 zł)"
        ],
        extraInfoList: [
          "szkolenie motorowodne: 600 zł",
          "egzamin na sternika motorowodnego (płatne na miejscu gotówką): 125 zł"
        ]
      },

      program: {
        title: "PROGRAM OBOZU ŻEGLARSKIEGO",
        listTitle: "Obóz żeglarski to nie tylko przyjemność, ale i nauka. Uczestników czeka wiele różnych atrakcji, m.in.:",
        list: [
          "zwiedzanie najciekawszych miejsc na Szlaku WJM",
          "postoje na kąpiele, pływanie, wędkowanie (dla posiadających zezwolenia)",
          "gry i zabawy sportowe",
          "gra terenowa „Poszukiwanie skarbu”",
          "regaty żeglarskie",
          "chrzest żeglarski"
        ],
        programInfo: <>A wieczory mazurskie będą wypełnione zapachem ogniska i pieczonej kiełbaski, radością wspólnego
          szantowania oraz opowieściami morskimi kapitanów.<br/><br/>
          W trakcie rejsu, uczestnicy pod okiem instruktora przygotowują posiłki w kambuzie jachtowych z produktów
          dostarczonych przez organizatora. Jeśli wymagana jest odpowiednia dieta, prosimy o wcześniejsze
          zgłoszenie.<br/><br/>
          Program rejsu ma charakter orientacyjny – o szczegółowym programie decyduje kierownik obozu, biorąc za każdym
          razem pod uwagę bezpieczeństwo uczestników, atrakcyjność imprezy i warunki atmosferyczne.<br/><br/>
          *Plan dnia może ulec zmianom (np. ze względu na warunki atmosferyczne).</>
      },

      place: {
        title: "Miejsce",
        descr: <>Za dodatkową opłatą zapewniamy zapewniamy transport autokarowy z Warszawy do portu rozpoczęcia obozu i z powrotem, komfortowymi i bezpiecznymi autokarami. Podczas przejazdu uczestnicy będą pod opieką wychowawców.<br />
          Wybrani przez nas przewoźnicy posiadają wszystkie wymagane prawem zezwolenia i aktualne badania techniczne pojazdów, gwarantujące bezpieczeństwo przewożonych osób.<br />
          <br />
          Koszt transportu jednej osoby na trasie Warszawa – Piaski – Warszawa wynosi 380 zł.</>
      },
      faq: [
        {
          title: "Gdzie odbywa się obóz żeglarski?",
          body: "Obóz żeglarski odbywa się na Szlaku Wielkich Jezior Mazurskich, a początek i koniec rejsu ma miejsce w Ruciane Nida."
        },
        {
          title: "Czy uczestnicy mają zapewnione wyżywienie podczas obozu?",
          body: "Podczas rejsu uczestnicy otrzymują trzy posiłki dziennie – śniadanie, obiad i kolację. Wszystkie posiłki przygotowywane są w kambuzie jachtowym przez samych uczestników pod nadzorem instruktora ze świeżych produktów dostarczanych codziennie przez organizatora. "
        },
        {
          title: "Na jakich jachtach organizowane są rejsy?",
          body: "Rejsy organizowane są na jachtach Antila 27 (lub podobnych), bezpiecznych i komfortowych. Więcej informacji znajdziesz w zakładce Katalog jachtów."
        },
        {
          title: "Ile osób bierze udział w rejsie?",
          body: "Załoga jachtu liczy maksymalnie 7 osób, do których dołącza jeden instruktor."
        }
      ],
      belowTableList: [
        "Warunkiem rezerwacji miejsca na wybrany turnus jest wpłata zaliczki w wysokości 300 zł, oraz dopłata pełnej kwoty najpóźniej na 30 dni przed rozpoczęciem turnusu.",
        "W przypadku przelewu tradycyjnego prosimy o wpłatę na numer konta: 18 1090 1870 0000 0001 1579 5868, tytuł przelewy: imię i nazwisko dziecka oraz termin turnusu."
      ],
    },

    obozySzkoleniowe: {
      price: "3630 zł",
      includedPrice: 'Cena obozu zawiera',
      notincludedPrice: "Cena obozu nie zawiera",
      extraInfo: "MOŻLIWOŚĆ ZDOBYCIA UPRAWNIEŃ MOTOROWODNYCH",
      warning: "Uwaga",
      deadlines: {
        includedList: [
          "zakwaterowanie na jachtach kabinowych Antila 27 lub podobnych",
          "pełne wyżywienie w trakcie rejsu – 3 posiłki (przygotowywane przez uczestników)",
          "realizację programu szkoleniowego na st. żeglarza jachtowego wg wytycznych PZŻ",
          "opiekę instruktora przez cały czas trwania rejsu",
          "upominek – koszulkę T-shirt",
          "ubezpieczenie NNW",
          "składkę na TFG i TFP"
        ],
        discountsList: [
          "50 zł dla stałych klientów – prosimy o kontakt z naszym biurem w celu uzyskania kodu rabatowego",
          "50 zł za osobę przy rezerwacji więcej niż jednego miejsca"
        ],
        notincludedList: [
         "transportu Warszawa – Piaski – Warszawa: 380 zł. (płatne dodatkowo)",
         "opłat za egzamin* (dla uczniów i studentów do 26 roku życia – 125 zł, dla dorosłych – 250 zł), opłaty za patent PZŻ* (dzieci i młodzież – 25 zł, dorośli – 50 zł)",
         "opłat za sanitariaty w portach i przystaniach  (WC ok. 5 zł, natrysk 20-25 zł)"
        ],
        extraInfoList: [
          "szkolenie motorowodne: 600 zł",
          "egzamin na sternika motorowodnego (płatne na miejscu gotówką): 125 zł"
        ],
        warningText: "Warunkiem przystąpienia do egzaminu na żeglarza jachtowego oraz sternika motorowodnego jest ukończenie 14 lat w dniu egzaminu 12.07.2024. Do egzaminu mogą przystąpić osoby urodzone nie później niż 12.07.2010."
      },

      program: {
        title: "PROGRAM OBOZU SZKOLENIOWEGO",
        listTitle: "CZĘŚĆ TEORETYCZNA:",
        list: [
          "Przepisy",
          "Budowa jachtu",
          "Teoria żeglowania i manewrowania",
          "Locja",
          "Ratownictwo",
          "Meteorologia",
          "Etyka i etykieta żeglarska, dbałość o środowisko",
        ],
        infoTitle: "Część praktyczna",
        programInfo: [
          "Manewry pod żaglami",
          "Manewry na silniku",
          "Manewry portowe",
          "Kładzenie i stawianie masztu",
          "Podstawowe umiejętności obsługi instalacji na jachcie",
          "Prace bosmańskie"
        ],
        extraInfo: "Rejs szkoleniowy to nie tylko nauka ale także przyjemność. Oprócz nauki uczestników czeka wiele różnych atrakcji, m.in.:",
        extraInfoList: [
          "zwiedzanie najciekawszych miejsc na Szlaku WJM",
          "postoje na kąpiele, pływanie, wędkowanie (dla posiadających zezwolenia)",
          "gry i zabawy sportowe",
          "gra terenowa „Poszukiwanie skarbu”",
          "regaty żeglarskie",
          "chrzest żeglarski"
        ],
        extraInfoDescr: <>A wieczory mazurskie będą wypełnione zapachem ogniska i pieczonej kiełbaski, radością
          wspólnego szantowania oraz opowieściami morskimi kapitanów.<br/><br/>
          W trakcie rejsu, uczestnicy pod okiem instruktora przygotowują posiłki w kambuzie jachtowych z produktów
          dostarczonych przez organizatora. Jeśli wymagana jest odpowiednia dieta, prosimy o wcześniejsze
          zgłoszenie.<br/><br/>
          Program rejsu ma charakter orientacyjny – o szczegółowym programie decyduje kierownik obozu, biorąc za każdym
          razem pod uwagę bezpieczeństwo uczestników, atrakcyjność imprezy i warunki atmosferyczne.<br/><br/>
          *Plan dnia, a w szczególności kolejność bloków może ulec zmianom (np. ze względu na warunki atmosferyczne).</>
      },

      place: {
        title: "Miejsce",
        descr: <>Za dodatkową opłatą zapewniamy zapewniamy transport autokarowy z Warszawy do portu rozpoczęcia obozu i z powrotem, komfortowymi i bezpiecznymi autokarami. Podczas przejazdu uczestnicy będą pod opieką wychowawców.<br />
          Wybrani przez nas przewoźnicy posiadają wszystkie wymagane prawem zezwolenia i aktualne badania techniczne pojazdów, gwarantujące bezpieczeństwo przewożonych osób.<br />
          <br />
          Koszt transportu jednej osoby na trasie Warszawa – Piaski – Warszawa wynosi 380 zł.</>
      },
      faq: [
        {
          title: "Dla kogo przeznaczony jest obóz szkoleniowy?",
          body: "Nasz obóz szkoleniowy na patent żeglarza jachtowego przeznaczony jest dla dzieci w wieku 14-18 lat."
        },
        {
          title: "Jakie są warunki przystąpienia do egzaminu?",
          body: "Kandydaci na żeglarza jachtowego mogą przystąpić do egzaminu po ukończeniu 14. roku życia najpóźniej w dniu egzaminu. Wymagana jest również pisemna zgoda rodziców/opiekunów na uprawianie żeglarstwa. "
        },
        {
          title: "Czy po obozie uczestnicy otrzymują patent żeglarza jachtowego?",
          body: "Nasze rejsy odbywają się w pełni według wytycznych PZŻ i Ministerstwa Sportu. W ostatnim dniu odbywa się zaś egzamin, którego zaliczenie uprawnia do otrzymania patentu."
        },
        {
          title: "Czy udział w egzaminie jest obowiązkowy?",
          body: "Nie, uczestnicy naszego kursu mogą, ale nie muszą przystępować do egzaminu. Przystąpienie do egzaminu oraz udział w manewrach szkoleniowych jest dobrowolny."
        }
      ],
      belowTableList: [
        "Warunkiem rezerwacji miejsca na wybrany turnus jest wpłata zaliczki w wysokości 300 zł, oraz dopłata pełnej kwoty najpóźniej na 30 dni przed rozpoczęciem turnusu.",
        "W przypadku przelewu tradycyjnego prosimy o wpłatę na numer konta: 18 1090 1870 0000 0001 1579 5868, tytuł przelewy: imię i nazwisko dziecka oraz termin turnusu."
      ],
    },
  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;
export const Translations = {
  pl: {
    topTitle: "Marzysz, by zostać Sternikiem Morskim?",
    topDescr: "Zapisz się na nasz kompleksowy kurs dla początkujących adeptów żeglugi! Gwarantujemy profesjonalną obsługę i pełne przygotowanie do egzaminu!",
    offer: {
      title: "Szkolenie jachtowe sternik morski",
      descr: (
        <>
          Szkolenie Sternik Morski to kompleksowy kurs, który przygotowuje do
          prowadzenia jachtów morskich i oceanicznych. Obejmuje teorię oraz
          praktykę, które są niezbędne do bezpiecznego i efektywnego żeglowania
          na wodach morskich. Kurs zawiera: naukę nawigacji, techniki
          żeglowania, procedury awaryjne, pierwszą pomoc, prawo morskie i wiele
          innych przydatnych na morzu umiejętności. Szkolenie kończy się
          otrzymaniem certyfikatu.
        </>
      ),
      list: [
        "Doświadczonych sterników",
        "Bezpieczne i nowoczesne jachty",
        "Indywidualne podejście do uczestników",
        "Wyżywienie w miejscu szkolenia (śniadanie, obiad) – opcjonalnie",
        "Miłą, żeglarską atmosferę",
        "Zniżki na czartery i rejsy dla uczestników",
      ],
    },
    timeTable: ["A", "B", "C", "D", "E"],
    tabs: {
      deadlinesAndPayments: {
        title: "TERMINY I OPŁATY",
        guarantee: {
          title: "GWARANCJA BEZPIECZNEJ REZERWACJI",
          descr:
            "Warunkiem rezerwacji miejsca na kurs jachtowego sternika morskiego / rejs stażowy jest wpłata zaliczki w wysokości 700 zł, oraz dopłata pełnej kwoty najpóźniej na 30 dni przed rozpoczęciem kursu. W przypadku konieczności odwołania kursu w związku z sytuacją epidemiologiczną COVID-19, gwarantujemy zwrot zaliczki.",
        },
        priceIncludes: {
          title: "CENA KURSU ZAWIERA",
          list: [
            "miejsce na jachcie w kabinie 2. osobowej",
            "szkolenie teoretyczne i praktyczne / udział w rejsie stażowym",
            "ubezpieczenie KL+NNW",
            "sprzątanie po rejsie",
          ],
        },
        priceDoesntInclude: {
          title: "CENA KURSU NIE ZAWIERA",
          list: [
            "transportu na miejsce zaokrętowania na jacht",
            "wyżywienia uczestników i instruktora (opłacane z kasy pokładowej*)",
            "Opłat portowych (opłacane z kasy pokładowej*)",
            "paliwa do jachtu (opłacane z kasy pokładowej*)",
            "materiałów dydaktycznych (książka Żeglarz jachtowy i jachtowy sternik morski, ok. 70 zł)",
            "kosztów egzaminu i wydania patentu jachtowego sternika morskiego (350 zł + 50 zł)",
            "na miejscu pobierana jest kaucja w wysokości 200 euro",
            "*kasa pokładowa: składka całej załogi na wspólne koszty (instruktor nie uczestniczy w składce). Przybliżona wysokość: 200 euro/uczestnika.",
          ],
        },
        salesAndDiscounts: {
          title: (
            <>
              RABATY I ZNIŻKI <span>(maksymalna zniżka wynosi 100zł)</span>
            </>
          ),
          list: [
            "50 zł za stałego klienta (jeśli jesteś Stałym Klientem skontaktuj się z nami w celu otrzymania kodu rabatowego)",
            "50 zł za rezerwację więcej niż jednego miejsca",
          ],
        },
        salesSmallText: (
          <>
            W przypadku przelewu tradycyjnego prosimy o wpłatę na numer konta:
            18 1090 1870 0000 0001 1579 5868,{" "}
            <b>tytuł przelewu: imię i nazwisko oraz termin szkolenia.</b>
          </>
        ),
      },
      examAndRequirements: {
        title: "EGZAMIN I WYMAGANIA",
        basicRequirements: {
          title:
            "Wymagania dla przystępujących do egzaminu na stopień Jachtowego Sternika Morskiego ",
          list: [
            "ukończony 18 rok życia,",
            "wniesienie opłaty egzaminacyjnej (płatne na miejscu gotówką przed egzaminem),",
            "przedłożenie do wglądu komisji egzaminacyjnej oryginału legitymacji uczniowskiej lub studenckiej, upoważniającej do skorzystania ze zniżki 50% na wydanie patentu (dotyczy uczniów i studentów w wieku poniżej 26 lat),",
            "przedłożenie do wglądu komisji egzaminacyjnej oryginałów dokumentów (opinie z rejsów lub karty rejsów) potwierdzających odbycie co najmniej dwóch rejsów po wodach morskich w łącznym czasie co najmniej 200 godzin żeglugi (godziny pływania podczas szkolenia zaliczają się do stażu – uczestnikowi szkolenia zostanie wystawiona opinia z rejsu ze stażem w wymiarze kaksymalnie do około 40 godzin).",
          ],
          descr:
            "Od kandydatów na jachtowego sternika morskiego nie jest wymagane posiadanie stopnia żeglarza jachtowego.",
        },
        examConsistsOf: {
          title: "Egzamin na Jachtowego Sternika Morskiego składa się z: ",
          list: [
            "części praktycznej (m.in. pływanie na żaglach, manewry portowe, manewr „człowiek za burtą”)",
            "części teoretycznej (należy poprawnie odpowiedzieć na min. 65 z 75 pytań oraz bezbłędnie rozwiązać zadanie nawigacyjne).",
          ],
        },
        examPrice: {
          title: "KOSZT EGZAMINU",
          descr: (
            <>
              <b>350,00 zł</b> płatne gotówką na miejscu przed egzaminem
              <br />
              (50% zniżki na egzamin i patent dla osób uczących się poniżej 26.
              roku życia)
            </>
          ),
        },
        patentRelease: {
          title: "WYDANIE PATENTU",
          descr:
            "W celu uzyskania patentu jachtowego sternika morskiego po egzaminie należy złożyć do Biura PZŻ następujące dokumenty:",
          list: [
            "wypełniony formularz wniosku o wydanie patentu wygenerowany za pomocą generatora wniosków na stronie PZŻ – https://patenty.pya.org.pl/,",
            "zdjęcie o wymiarach 3,5 cm x 4,5 cm /fotografia nie może być zeskanowana/,",
            "oryginał zaświadczenia o zdaniu egzaminu wystawionego przez komisję egzaminacyjną,",
            "kopie dokumentów potwierdzających odbycie co najmniej dwóch rejsów po wodach morskich w łącznym czasie co najmniej 200 godzin żeglugi (opinie z rejsów, karty rejsów),",
            "kopia dokumentu upoważniającego do skorzystania ze zniżki 50% za wydanie patentu – dotyczy uczniów i studentów do 26 lat z ważną legitymacją,",
            "kopia dowodu osobistego lub aktu małżeństwa – dotyczy osób, które zmieniły nazwisko,",
            "kopia dowodu opłaty za wydanie patentu jachtowego sternika morskiego zgodnie z poniższą tabelą.",
          ],
        },
        patentPrice: {
          title: "KOSZT PATENTU",
          descr: (
            <>
              <b>50,00 zł</b> płatne on-line podczas generowania wniosku o
              wydanie patentu na stronie PZŻ.
              <br /> (50% zniżki na egzamin i patent dla osób uczących się
              poniżej 26. roku życia)
            </>
          ),
        },
      },
      yacht: {
        title: "JACHT",
      },
      place: {
        title: "MIEJSCE",
        place: {
          title: "MARINA",
          descr: "Szkolenie rozpoczynamy i kończymy egzaminem w Drage.",
        },
        arrival: {
          title: "DOJAZD",
          descr:
            "Dojazd do Chorwacji w miejsce zaokrętowania jest we własnym zakresie. Są następujące możliwości:",
          list: [
            "Przelot samolotem – możemy pomóc w zakupie biletów lotniczych do najbliższego lotniska oraz w organizacji transferu z lotniska do mariny.",
            "Dojazd autokarem – doradzimy gdzie kupić bilety autokarowe",
            "Dojazd samochodem",
          ],
        },
      },
      faq: {
        title: "FAQ",
      },
      filesToDownload: {
        title: "PLIKI DO POBRANIA",
        files: [
          {
            title: "Ogólne Warunki Uczestnictwa",
            url: "#",
          },
          {
            title: "Opinia z rejsu",
            url: "#",
          },
          {
            title: "Karta Rejsu",
            url: "#",
          },
          {
            title: "Polityka Prywatności",
            url: "#",
          },
          {
            title: "Wpis do Rejestru Organizatorów Turystyk",
            url: "#",
          },
          {
            title: "Gwarancja Organizatora Turystyki",
            url: "#",
          },
          {
            title: "OWU NNW RP Signal Iduna",
            url: "#",
          },
          {
            title: "Ubezpieczenie Kosztów Imprezy Bezpieczne Rezerwacje",
            url: "#",
          },
        ],
      },
    },
  },
};

Translations.en = Translations.pl;
Translations.de = Translations.pl;


import { useContext } from "react"
import { Translations as MenuTranslations } from "../../../Components/Menu/Lang"
import { LangContext } from "../../../App"
import SelectRow from "./SelectRow";
import { convertTranslationToOptions } from "../../../utils/translation-to-options";
import Range from "./Range";
import RowInput from "./RowInput";
import { OffersFilterContext } from "../OfertyPage";

export default function LengthInput ()
{
  const { lang } = useContext(LangContext);
  const { filters } = useContext(OffersFilterContext);

  return (
    <RowInput 
      label={MenuTranslations[lang].slLength}
      subtitle={`${filters.minLength}-${filters.maxLength}`}
      htInput={<Range min={1} max={50} name="Length" />}
    />
  )
}
import { Accordion, AccordionItem as Item} from '@szhsin/react-accordion';
import styled from 'styled-components';
import { __renderable_equipment } from './Equipment';

const ItemWithChevron = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={
      <>
        {header}
        <img className="chevron-down" src="/ico/accordion-arrow.svg" alt="Rozwiń" />
      </>
    }
  />
);

export const AccordionItem = styled(ItemWithChevron)`
  margin-bottom: 22px;
  .szh-accordion__item {
    &-btn {
      border: none;
      cursor: pointer;
      border-radius: 4px;
      display: flex;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      padding: 9px 16px;
      font-family: Gilroy-ExtraBold;
      font-size: 16px;
      line-height: 24.5px;
      font-weight: 400;
      color: #133b51;
      text-transform: uppercase;
      background-color: #ddf5f8;
      &:hover {
        --background-color: #f3f3f3;
      }
    }

    &-content {
      font-family: Gilroy-Light;
      font-size: 16px;
      font-weight: 400;
      line-height: 24.5px;
      color: #133b51;
      transition: height 0.3s cubic-bezier(0, 0, 0, 1);
    }

    &-panel {
      box-sizing: border-box;
      padding: 13px 16px;
      background-color: #ddf5f8;
      border-radius: 0px 0px 4px 4px;
    }

    & b { /* FIXME: Why doesnt it work? */
      font-family: Gilroy-ExtraBold !important;
    }
  }

  .chevron-down {
    margin-left: auto;
    transition: transform 0.3s cubic-bezier(0, 0, 0, 1);
    width: 15px;
  }

  &.szh-accordion__item--expanded {
    .szh-accordion__item {
      &-btn {
        font-family: Gilroy-ExtraBold;
      }
    }
    .chevron-down {
      transform: rotate(180deg);
    }
  }
`;

const Wrapper = styled.div`
  margin-top: 23px;
`

export default function AdditionalEquipment ({equipment})
{
  const itemsString = equipment
    .filter(n => !__renderable_equipment.includes(n.id))
    .map(n => n.name).join(" | ");

  return (
    <Wrapper>
      <Accordion transition transitionTimeout={300}>
        <AccordionItem header="Wyposażenie Dodatkowe">
          {itemsString}
        </AccordionItem>
      </Accordion>
    </Wrapper>
  )
}
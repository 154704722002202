import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 60%;
  background-color: #fff;
  flex-direction: column;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.15);
  padding: 30px 40px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 20px 15px;
  }
`

const Title = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 24px;
  font-weight: 400;
  color: #133b51;
  margin-bottom: 20px;
`

const Content = styled.div`
  width: 100%;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  color: #133b51;
  
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 25px;
  }
`

const List = styled.ul`
  width: 100%;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  color: #133b51;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`

const Item = styled.li`

`

export default function Card ({ title, content })
{
  return (
    <Wrapper>
      <Title>
        {title}
      </Title>

      {typeof content === 'string' && (
        <Content>
          {content}
        </Content>
      )}

      {typeof content !== 'string' && (
        <List>
          {content.map((item, key) => (
            <Item key={key}>
              {item}
            </Item>
          ))}
        </List>
      )}

    </Wrapper>
  );
}
import { useContext, useState } from "react";
import { Form, Input, InputButton, Row } from "./AuthPage";
import { LangContext } from "../../App";
import { Translations } from "./Lang";
import axios from "axios";
import __server from "../../Api";
import styled from "styled-components";
import { ScaleLoader } from "react-spinners";

const SpinnerBox = styled.div`
  width: 360px;
  height: 264px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Gilroy-Light';
  font-size: 22px;
  font-weight: 400;
  color: #133b51;
  text-align: center;
`

export default function Reset ({})
{
  const { lang } = useContext(LangContext);

  const [mail, setMail] = useState('');
  const [passw1, setPassw1] = useState('');
  const [passw2, setPassw2] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [isDone, setDone] = useState(false);

  const onPasswordReset = function (e) 
  {
    e.preventDefault();

    if (passw1 !== passw2) {
      alert("Hasła muszą być jednakowe");
      return;
    }

    setLoading(true);
    axios.post(`${__server}/user/password`, {
      mail: mail, passw: passw1
    }).then((resp) => {
      setDone(true);
      alert("OK");
    }).catch((err) => {
      setLoading(false);
      alert("BŁĄD");
    })
  }

  return isLoading ? (
    <SpinnerBox>
      {isDone ? <>
        {Translations[lang].reset.done}
      </> : <>
        <ScaleLoader color="#133b51" />
      </>}
    </SpinnerBox>
  ) : (
    <Form onSubmit={onPasswordReset}>
      <Row>
        <Input type="email" placeholder={Translations[lang].mail} value={mail} onChange={(e) => setMail(e.target.value)} required/>
      </Row>
      <Row>
        <Input type="password" placeholder={Translations[lang].passw} value={passw1} onChange={(e) => setPassw1(e.target.value)} required/>
      </Row>
      <Row>
        <Input type="password" placeholder={Translations[lang].register.repeat} onChange={(e) => setPassw2(e.target.value)} required/>
      </Row>
      <Row>
        <InputButton type="submit" value={Translations[lang].reset.btn} />
      </Row>
    </Form>
  )
}
import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext, useEffect, useState } from "react"
import { LangContext } from "../../../../App"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Content = styled.div`
  width: 100%;
  max-height: ${params => params.$isMore ? 'fit-content' : params.$minHeight};
  overflow: hidden;

  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 16px;
  color: #133b51;

  > b {
    font-family: 'Gilroy-ExtraBold';
  }
`

const Button = styled.button`
  margin-top: 28px;
  font-family: 'Gilroy-ExtraBold';
  font-size: 20px;
  font-weight: 400;
  color: #1a87c5;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`

const Icon = styled.img`
  margin-left: 11px;
  width: 15px;
  transform: rotate(${params => params.$isMore ? '180deg' : '0deg'});
`

export default function ReadMore ({ children, minHeight, openOnStart = false })
{
  const { lang } = useContext(LangContext);
  const [isMore, setIsMore] = useState(openOnStart);

  return (
    <Wrapper>
      <Content $isMore={isMore} $minHeight={minHeight}>
        {children}
      </Content>
      <Button onClick={(e) => setIsMore(!isMore)}> 
        {Translations[lang].readMore[isMore ? 'lessButton' : 'moreButton']}
        <Icon src="/ico/read-more-arrow.svg" $isMore={isMore} />
      </Button>
    </Wrapper>
  )
}
import { useParams } from "react-router-dom"
import LiteMenu from "../../../Components/Menu/Factory/LiteMenu";
import Footer from "../../../Components/Footer/Footer";
import styled from "styled-components";
import { unslugify } from "../../../utils/slugger";
import { Translations } from "./Lang";
import { useContext, useEffect, useState } from "react";
import { LangContext } from "../../../App";
import axios from "axios";
import __server from "../../../Api";
import Yacht from "./Yacht";
import { ScaleLoader } from "react-spinners";
import Pagination from "./Pagination";
import PromotedModelsVerticalSection from "../../../Components/PromotedModels/PromotedModelsVerticalSection";

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  margin-top: 45px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    width: 100%;
    flex-direction: column;
  }
`

const Left = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;
  width: 300px;
  @media (max-width: 767px) {
    width: 100%;
  }
`

const Right = styled.div`
  width: 980px;
  @media (max-width: 767px) {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 15px;
    margin-top: 30px;
  }
`

const Top = styled.div`
  color: #133b51;
  font-weight: 400;
`

const Title = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 32px;
  color: #133b51;

  > span {
    font-family: 'Gilroy-ExtraBold';
  }
`

const Descr = styled.div`
  margin-top: 10px;
  font-family: 'Gilroy-Light';
  font-size: 16px;
`

const Results = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const LoadingWrapper = styled.div`
  margin-top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Summary = styled.div`
  margin-top: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Variants = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 20px;
  color: #133b51;

  > span {
    color: #878b93;
  }
`

const BackButton = styled.a`
  text-decoration: none;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  color: #133b51;

  > img {
    width: 40px;
    margin-right: 14px;
  }

  @media (max-width: 767px) {
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

export default function YachtsPage ()
{
  const { lang } = useContext(LangContext);
  const { slug } = useParams();

  const [data, setData] = useState(null);
  const [model, setModel] = useState(null);
  const [page, setPage] = useState(null);


  /** TODO: remove yachtPage from search params */
  const backHref = `/katalog-jachtow${window.location.search}`;

  const parseSearchParams = function ()
  {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has('yachtPage')) {
      setPage(1);
      return;
    }

    setPage(parseInt(searchParams.get('yachtPage')));
  }

  const fetchModel = function ()
  {
    axios.get(`${__server}/front/model/${unslugify(slug)}`).then((resp) => {
      setModel(resp.data);
    }).catch((err) => {
      console.error(err);
    });
  }

  const fetchYachts = function ()
  {
    if (page == null) {
      return <></>;
    }

    setData(null);
    axios.get(`${__server}/front/yacht?model=${unslugify(slug)}&page=${page}`).then((resp) => {
      setData(resp.data);
    }).catch((err) => {
      console.error(err);
    });
  }

  useEffect(() => {
    parseSearchParams();
    fetchModel();
  }, []);

  useEffect(() => { 
    fetchYachts();
  }, [page]);


  const RenderResults = function ()
  {
    if (model == null || data == null) {
      return <></>;
    }

    return (
      <>
        <Summary>
          <Variants>
            <span>{Translations[lang].yachts.found}:</span> {data.total} {Translations[lang].yachts.variants}
          </Variants>
          <Pagination last_page={data.last_page} page={page} setPage={setPage} />
        </Summary>
        <Results>
          {data.data.map((item, key) => (
            <Yacht {...item} modelInfo={model} key={key} />
          ))}
        </Results>
      </>
    );
  }

  return (
    <>
      <LiteMenu />
      <Wrapper>
        <Left>
          <BackButton href={backHref}>
            <img src="/ico/arrow-back.svg" alt=""/>
            {Translations[lang].yachts.backButton}
          </BackButton>
					<PromotedModelsVerticalSection />
        </Left>
        <Right>
          <Top>
            <Title>
              {Translations[lang].yachts.title}
            </Title>
            <Descr>
              {Translations[lang].yachts.descr}
            </Descr>
          </Top>
          {(model == null || data == null) && <LoadingWrapper>
            <ScaleLoader color="#133b51" />
          </LoadingWrapper>}
          <RenderResults />
        </Right>
      </Wrapper>
      <Footer />
    </>
  )
}
import styled from "styled-components"
import Top from "../../Components/OfferPage/Top"
import CampMenu from "../../../../Components/Menu/Factory/CampMenu"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../../../App"
import Footer from "../../../../Components/Footer/Footer"
import Gallery from "../../Components/OfferPage/Gallery"
import ContactSection from "../../../../Components/ContactSection/ContactSection"
import CampTabs from "../../Components/OfferPage/Camp/CampTabs"
import { BookingOption, BookingTable } from "../../Components/BookingTable"
import TimeTable, { TimeSlice } from "../../Components/OfferPage/TimeTable"
import DownloadBox, { Downloader } from "../../Components/OfferPage/DownloadBox"

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  
  @media (max-width: 767px) {
    width: 100%;
  }
`

export default function ObozSzkoleniowyPage ()
{
  const { lang } = useContext(LangContext);

  return (
    <>
      <CampMenu
        title={Translations[lang].title}
        descr={Translations[lang].descr}
      />
      <Wrapper>
        <Top name="camp-polkolonie-sportowe" galSize={3} pageTranslations={Translations} />

        <CampTabs name="obozySzkoleniowe">
          <BookingTable>
            <BookingOption time="30.06-13.07" price="3630zł" isAvailable={false} />
          </BookingTable>
          <TimeTable>
            <TimeSlice time="Dzień 1" activity={Translations[lang].offer.timeTable[0]}/>
            <TimeSlice time="Dzień 2-12" activity={Translations[lang].offer.timeTable[1]}/>
            <TimeSlice time="Dzień 13" activity={Translations[lang].offer.timeTable[2]}/>
            <TimeSlice time="Dzień 14" activity={Translations[lang].offer.timeTable[3]}/>
          </TimeTable>
          <DownloadBox>
            {Translations[lang].offer.downloads.map((download, key) => (
              <Downloader key={key} title={download.title} url={download.url} />
            ))}
          </DownloadBox>
        </CampTabs>
        <Gallery name="camp-obozy-szkoleniowe" />
        <ContactSection />
      </Wrapper>
      <Footer />
    </>
  )
}
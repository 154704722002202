
export const Translations = {
  pl: {
    bookingTable: {
      timeMargin: 'termin',
      price: 'cena',
      status: 'status',
      booking: 'rezerwacja',
      available: 'dostępne',
      unavailable: 'niedostępne',
      bookOnline: 'Rezerwuj online',
      title: "Rezerwacje online",
      subtitle: "Terminy i dostępność miejsc"
    }
  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;
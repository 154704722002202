import styled from "styled-components";

const Wrapper = styled.div`
  display: none;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 15px 10px 15px;

  @media (max-width: 767px) {
    display: block;
  }
`

const Box = styled.div`
  width: 100%;
  height: 104px;
  box-sizing: border-box;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const Item = styled.a`
  text-decoration: none;

  &:nth-child(2n) {
    align-self: flex-end;
  }
  
  > img {
    width: 73px;
  }
`


export default function MobileSlider ()
{
  return (
    <Wrapper>
      <Box>
        <Item href="/incentive">
          <img src="/menu/incentive.svg" />
        </Item>
        <Item href="/academy">
          <img src="/menu/academy.svg" />
        </Item>
        <Item href="/yacht-charter">
          <img src="/menu/yacht-charter.svg" />
        </Item>
        <Item href="/camp">
          <img src="/menu/camp.svg" />
        </Item>
      </Box>
    </Wrapper>
  );
}
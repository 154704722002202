import {Translations} from "./Lang";
import { styled } from 'styled-components';
import {useContext} from "react";
import {LangContext} from "../../App";

const Top = styled.div`
  color: #133b51;
  font-weight: 400;

	@media (max-width: 767px) {
		box-sizing: border-box;
		padding: 0px 15px;
	}
`

const Title = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 32px;
  color: #133b51;

  > span {
    font-family: 'Gilroy-ExtraBold';
  }
		
	@media (max-width: 767px) {
		font-size: 24px;
	}
`

const Descr = styled.div`
  margin-top: 10px;
  font-family: 'Gilroy-Light';
  font-size: 16px;
`

export default function ShortDescription ()
{
	const { lang } = useContext(LangContext);

	return (
		<Top>
			<Title>
				{Translations[lang].results.title}
			</Title>
			<Descr>
				{Translations[lang].results.descr}
			</Descr>
		</Top>
	)
}
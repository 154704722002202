
export const Translations = {
  pl: {
    title: <>WYJAZDY <span>FIRMOWE</span></>,
    descr: <>
      Rejsy firmowe to doskonała forma integracji i motywacji pracowników. Elegancja, komfort oraz możliwość odkrywania uroków lądu od zupełnie innej, czasem jedynej możliwej strony – od strony wody to nie tylko wspaniały relaks ale również doskonała okazja do mniej formalnych, ale częstokroć bardziej produktywnych, kameralnych spotkań biznesowych. <br/> <br/>
      Proponujemy rejsy luksusowymi jachtami po ciepłych morzach <b>Grecji, Chorwacji, Karaibów, Seszeli oraz naszych polskich Mazurach.</b>
      </>,
    companyCruises: 'REJSY DLA FIRM',
    departure: 'TERMIN',
    duration: 'CZAS WYJAZDU',
    checkOut: 'SPRAWDŹ'
  }
}


Translations.en = Translations.pl;
Translations.de = Translations.pl;
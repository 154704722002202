import { useContext } from "react";
import styled from "styled-components";
import { FilterContext } from "./BlogPage";

const FiltersBox = styled.div`
  padding: 0px 52px;
  box-sizing: border-box;
  display: flex;
  jusityf-content: flex-start;
  align-items: center;
  gap: 16px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 15px;
  }
`;

const FilterIconBox = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 20px;
  font-weight: 400;
  color: #133851;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  
  > img {
    width: 25px
  }

  @media (max-width: 767px) {
    > img {
      width: 17px;
    }
    
    font-size: 16px;
    gap: 6px;
  }
`

export const FilterTag = styled.div`
  font-family: 'Mada';
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  padding: 8px 27px;
  box-sizing: border-box;
  background-color: ${param => param.color};
  border-radius: 0px 2px 2px 0px;
  cursor: pointer;
  height: fit-content;

  @media (max-width: 767px) {
    font-size: 12px;
    padding: 5px 10px;
  }
`

const MobileInlineFilters = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    gap: 7px;
  }
`

export const filterList = [
  {tag: 'incentive', color: '#1a87c5'}, 
  {tag: 'yachtcharter', color: '#32c8d2'}, 
  {tag: 'academy', color: '#94073a'}, 
  {tag: 'camp', color: '#f2cf1c'}
];

export default function Filters ({})
{
  const {showTags, setShowTags} = useContext(FilterContext);

  return (
    <FiltersBox>
      <FilterIconBox>
        <img src="/ico/filter.svg" />
        Filtruj
      </FilterIconBox>
      <MobileInlineFilters>
        {filterList.map(({tag, color}, key) =>
          <FilterTag
            key={key}
            color={showTags.includes(tag) ? color : '#1338517F'}
            onClick={(e) => {
              const newShowTags = [...showTags];
              if (showTags.includes(tag)) {
                if (showTags.length == 1) {
                  return; /* jeden musi zostać włączony */
                }
                newShowTags.splice(showTags.indexOf(tag), 1);
              } else {
                newShowTags.push(tag);
              }
              setShowTags(newShowTags);
            }}
          >
            #{tag}
          </FilterTag>
        )}
      </MobileInlineFilters>
    </FiltersBox>
  )
}
export const Translations = {
  pl: {
    menu: {
      title: "Cała naprzód z pasją przez życie.",
      descr:
        "Niezależnie od tego, czy marzysz o spokojnych rejsach w otoczeniu natury, czy ekscytujących wyprawach do tętniących życiem portów, z nami przeżyjesz niezapomniane chwile na wodzie!",
    },
    top: {
      title: (
        <>
          Nasze <span>Destynacje</span>
        </>
      ),
      descr: (
        <>
          <span>Hiszpania, Francja, Grecja czy Chorwacja?</span> Z nami wyruszysz w niezapomnianą
          podróż po najpiękniejszych wybrzeżach Europy! Zanurz się w świecie pełnym przygód, komfortu
          i niezapomnianych widoków.
          <br />
          <br />
          Niezależnie od tego, czy marzysz o spokojnych rejsach <span>w otoczeniu natury</span>, czy
          ekscytujących wyprawach do tętniących życiem portów, z nami przeżyjesz <span>niezapomniane
          chwile na wodzie!</span>
        </>
      ),
    },
    country: {
      backButton: "wróć do destyncacji",
      detailsButton: "SZCZEGÓŁY",
    },
  },
};

Translations.en = Translations.pl;
Translations.de = Translations.pl;

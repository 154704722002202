import { useContext } from "react"
import { FilterModelsContext } from "./ModelsPage";
import styled from "styled-components";
import { Translations } from "./Lang";
import { LangContext } from "../../App";
import Kinds from "./Filters/Kinds";
import TbxCompany from "./Filters/TbxCompany";
import TbxModel from "./Filters/TbxModel";
import Range from "./Filters/Range";

const Wrapper = styled.div`
  width: 300px;
  border-radius: 10px;
  border: 1px solid #32c8d2;
  box-sizing: border-box;
  padding: 15px;
  font-family: 'Gilroy-ExtraBold';
  font-weight: 400;
  color: #133b51;
  height: fit-content;

  @media (max-width: 767px) {
    width: 100%;
    border: none;
  }
`

const Title = styled.div`
  display: flex;
  gap: 9px;
  font-size: 20px;

  > img {
    width: 17px;
  }
`

const Label = styled.div`
  font-size: 16px;
  margin-top: 15px;

  > span {
    font-family: 'Gilroy-Light';
  }
`

const Boxed = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Quantity = styled.div`
  margin-top: 15px;
  font-family: 'Gilroy-Light';
  font-size: 15px;
  font-weight: 400;
  color: rgba(19, 59, 81, 0.5);

`

export default function Filters ()
{
  const { filters, setFilters } = useContext(FilterModelsContext);
  const { lang } = useContext(LangContext);

  return (
    <Wrapper>
      <Title>
        <img src="/ico/hopper.svg" />
        {Translations[lang].filters.title}
      </Title>
      <Label>
        {Translations[lang].filters.kind}
      </Label>
      <Kinds />
      <Label>
        {Translations[lang].filters.model}
      </Label>
      <TbxModel />
      <Boxed>
        <Label>
          {Translations[lang].filters.cabins}
        </Label>
        <Quantity>
          {filters.minCabins}-{filters.maxCabins}
        </Quantity>
      </Boxed>
      <Range name="Cabins" min={0} max={18} />
      <Boxed>
        <Label>
          {Translations[lang].filters.length}
        </Label>
        <Quantity>
          {filters.minLength}-{filters.maxLength}
        </Quantity>
      </Boxed>
      <Range name="Length" min={0} max={60} />
    </Wrapper>
  )
}
import { useContext } from 'react';
import Select from 'react-select';
import { SearchFilterContext } from '../Abstract/MenuSearcher';

export default function SelectSearch({name, options, ...props})
{
  const { filters, setFilters } = useContext(SearchFilterContext);

  return(
    <>
      <Select
        styles={{
          indicatorSeparator: (styles) => ({display:'none'}),
          control: baseStyles => ({
            ...baseStyles,
            border: 0,
            boxShadow: 'none',
            width: "100%",
            fontFamily: "Gilroy-Light",
            fontSize: "16px",
            fontWeight: "400",
            cursor: "pointer",
            padding: "0px",
            minHeight: "0px",
            marginTop: "-2px"
          }),
          option: baseStyles => ({
            ...baseStyles,
            color: 'black'
          }),
          singleValue: baseStyles => ({
            ...baseStyles,
            color: '#878b93',
            padding: "0px"
          }),
          dropdownIndicator: base => ({
            ...base,
            padding: "0px",
            margin: "0px",
          }),
          valueContainer: (base) => ({
            ...base,
            padding: "0px",
            margin: "0px"
          }),
          input: (base) => ({
            ...base,
            padding: "0px",
            margin: "0px",
          }),
          menuList: (baseStyles, state) => ({
            ...baseStyles,
            fontFamily: 'Gilroy-Light',
            color: '#133b51',
            zIndex: 999
          })
        }}
        noOptionsMessage={() => "Brak wyników"}
        {...props}
        options={options}

        value={options.filter((n) => n.value === filters[name])}
        onChange={(e) => setFilters({...filters, [name]: e.value}) }
      />
    </>
  );
}
import { useParams } from "react-router-dom"
import LiteMenu from "../../Components/Menu/Factory/LiteMenu";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import __server from "../../Api";
import Footer from "../../Components/Footer/Footer";
import styled from "styled-components";
import Yacht from "../Yacht/View/Yacht";
import { Translations } from "./Lang";
import { LangContext } from "../../App";
import Top from "./Top";
import Section from "./Section";
import { ScaleLoader } from "react-spinners";

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
`

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`

export default function OfertaPage ()
{
  const { id } = useParams();

  const [data, setData] = useState(null);

  useEffect(() => {
    axios.get(`${__server}/front/search/${id}${window.location.search}`).then((resp) => {
      setData(resp.data);
      console.log(resp.data);

    }).catch((err) => {
      console.error(err);
    })

  }, []);

  return (
    <>
      <LiteMenu />
      <Wrapper>
        {data === null && <LoaderWrapper><ScaleLoader color="#133b51"/></LoaderWrapper>}
        {data !== null && <Yacht 
          yachtId={data.yachtId} 
          extraProp={(
            <Section {...data} />
          )} 
          overButtonProp={(
            <Top {...data} />
          )}
          disableBelowButtonsProp={true} 
        />}
      </Wrapper>
      <Footer />
    </>
  )
}
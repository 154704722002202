
export const Translations = {
  pl: {
    title: <>Ciekawostki i <span>Aktualności</span></>,
    showMore: 'Czytaj więcej'
  },
  en: {
    title: <>Ciekawostki i <span>Aktualności</span></>,
    showMore: 'Czytaj więcej'
  },
  de: {
    title: <>Ciekawostki i <span>Aktualności</span></>,
    showMore: 'Czytaj więcej'
  }
}
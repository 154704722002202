
export const Translations = {
	pl: {
		offer1: {
			title: "PÓŁKOLONIE SPORTOWO ŻEGLARSKIE ",
			props: [
				<>GÓRKA SZCZĘŚLIWICKA <span>3 dni</span></>,
				<>JEZIORO ZEGRZYŃSKIE <span>2 dni</span></>
			],
			age: <>WIEK: <span>7-13 lat</span></>
		},
		offer2: {
			title: "PÓŁKOLONIE ŻEGLARSKIE ",
			props: [
				<>JEZIORO ZEGRZYŃSKIE <span>5 dni</span> </>
			],
			age: <>WIEK: <span>10-14 lat</span> </>
		},
		offer3: {
			title: "OBÓZ SZKOLENIOWY NA PATENT ŻEGLARZA JACHTOWEGO",
			props: [
				<>30.06-13.07.2024</>
			],
			age: <><span>Mazury</span> </>
		},
		offer4: {
			title: "ŻEGLARSKI OBÓZ STAŻOWY",
			props: [
				<>30.06-13.07.2024</>
			],
			age: <><span>Mazury</span> </>
		},
		details: "SZCZEGÓŁY"
	}
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;
import styled from "styled-components"
import AcademyMenu from "../../../../Components/Menu/Factory/AcademyMenu"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../../../App"
import Top from "../../Components/OfferPage/Top"
import Footer from "../../../../Components/Footer/Footer"
import ContactSection from "../../../../Components/ContactSection/ContactSection"
import Tabs, { Tab } from "../../Components/OfferPage/Tabs"
import Article from "../../Components/Article"
import DownloadBox, { Downloader } from "../../Components/OfferPage/DownloadBox"
import AcademyFaq from "../../Components/OfferPage/Academy/AcademyFaq"
import TimeTable, { TimeSlice } from "../../Components/OfferPage/TimeTable"
import { BookingOption, BookingTable } from "../../Components/BookingTable"
import AcademyYacht from "../../Components/OfferPage/Academy/AcademyYacht"
import AcademyCarousel from "../../Components/OfferPage/Academy/AcademyCaroulsel"
import GoogleMaps from "../../../../Components/GoogleMaps/GoogleMaps"

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;

  @media (max-width: 767px) {
    width: 100%;
  }
`

export default function SzkolenieSternikMorskiPage ()
{
  const { lang } = useContext(LangContext);

  const bookingTable = (
    <BookingTable>
      <BookingOption time="15-19.07.2024" price="1290zł" isAvailable={false} />
      <BookingOption time="15-19.07.2024" price="1290zł" />
      <BookingOption time="15-19.07.2024" price="1290zł" />
    </BookingTable>
  );

  return (
    <>
      <AcademyMenu
        title={Translations[lang].topTitle}
        descr={Translations[lang].topDescr}
        subtitle="Academy"
      />
      <Wrapper>
        <Top name="academy-sternik-morski" galSize={3} pageTranslations={Translations} />

        <Tabs>
          <Tab title={Translations[lang].tabs.deadlinesAndPayments.title}>
            <Article 
              title={Translations[lang].tabs.deadlinesAndPayments.guarantee.title}
              contents={Translations[lang].tabs.deadlinesAndPayments.guarantee.descr}
            />
            <Article 
              title={Translations[lang].tabs.deadlinesAndPayments.priceIncludes.title}
              contents={Translations[lang].tabs.deadlinesAndPayments.priceIncludes.list}
            />
            <Article 
              title={Translations[lang].tabs.deadlinesAndPayments.priceDoesntInclude.title}
              contents={Translations[lang].tabs.deadlinesAndPayments.priceDoesntInclude.list}
            />
            <div>
              <Article 
                title={Translations[lang].tabs.deadlinesAndPayments.salesAndDiscounts.title}
                contents={Translations[lang].tabs.deadlinesAndPayments.salesAndDiscounts.list}
              />
              <Article 
                title=""
                contents={Translations[lang].tabs.deadlinesAndPayments.salesSmallText}
              />
            </div>
            {bookingTable}
          </Tab>
          <Tab title={Translations[lang].tabs.examAndRequirements.title}>
            <div>
              <Article 
                title={Translations[lang].tabs.examAndRequirements.basicRequirements.title}
                contents={Translations[lang].tabs.examAndRequirements.basicRequirements.list}
              />
              <Article 
                title=""
                contents={Translations[lang].tabs.examAndRequirements.basicRequirements.descr}
              />
            </div>
            <Article 
              title={Translations[lang].tabs.examAndRequirements.examConsistsOf.title}
              contents={Translations[lang].tabs.examAndRequirements.examConsistsOf.list}
            />
            <Article 
              title={Translations[lang].tabs.examAndRequirements.examPrice.title}
              contents={Translations[lang].tabs.examAndRequirements.examPrice.descr}
            />
            <div>
              <Article 
                title={Translations[lang].tabs.examAndRequirements.patentRelease.title}
                contents={Translations[lang].tabs.examAndRequirements.patentRelease.descr}
              />
              <Article 
                title=""
                contents={Translations[lang].tabs.examAndRequirements.patentRelease.list}
              />
            </div>
            <Article 
              title={Translations[lang].tabs.examAndRequirements.patentPrice.title}
              contents={Translations[lang].tabs.examAndRequirements.patentPrice.descr}
            />
            {bookingTable}
          </Tab>
          <Tab title={Translations[lang].tabs.yacht.title}>
            <AcademyYacht 
              typeOfYacht="BAVARIA 46 LUB PODOBNY"
              length="14,2 M"
              width="4,3 M"
              submerge="2,1 M"
              cabins="4"
              toilets="3"
              grota="44 M2"
              foka="47 M2"
              fins="2"
            />
            <AcademyCarousel name="sternik-morski" galSize={3} />
          </Tab>
          <Tab title={Translations[lang].tabs.place.title}>
            <Article 
              title={Translations[lang].tabs.place.place.title}
              contents={Translations[lang].tabs.place.place.descr}
            />

            <div>
              <Article 
                title={Translations[lang].tabs.place.arrival.title}
                contents={Translations[lang].tabs.place.arrival.descr}
              />
              <Article 
                title=""
                contents={Translations[lang].tabs.place.arrival.list}
              />
            </div>

            <GoogleMaps lat={43.8881} lon={15.5346} hei={437} />
          </Tab>
          <Tab title={Translations[lang].tabs.faq.title}>
            <AcademyFaq />
            {bookingTable}
          </Tab>
          <Tab title={Translations[lang].tabs.filesToDownload.title}>
            <DownloadBox>
              {Translations[lang].tabs.filesToDownload.files.map((download, key) => (
                <Downloader key={key} title={download.title} url={download.url} />
              ))}
            </DownloadBox>
            {bookingTable}
          </Tab>
        </Tabs>


      </Wrapper>

      <ContactSection top="50px" />
      <Footer />
    </>
  )
}
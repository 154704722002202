import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext, ThemeContext } from "../../../../App"

const SeasonsWrapper = styled.div`
  display: flex;
  gap: 74px;
  margin-top: 73px;
  @media (max-width: 767px) {
    margin-top: 15px;
    flex-direction: column-reverse;
    box-sizing: border-box;
    padding: 0px 15px;
    gap: 40px;
  }
`

const LeftImage = styled.div`
  width: 435px;
  height: 512px;
  border-radius: 10px;
  background-image: url(/home/summer.webp);
  background-size: cover;
  background-position: center bottom;

  @media (max-width: 767px) {
    width: 100%;
    height: 300px;
  }
`

const Right = styled.div`
  width: calc(1320px - 435px - 74px);
  background-image: url(/sub/camp/top.webp);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 850px;

  @media (max-width: 767px) {
    width: 100%;
    background-size: 350px;
  }
`

const Title = styled.div`
  margin-top: 10px;
  font-weight: 400;
  font-size: 32px;
  font-family: 'Gilroy-ExtraBold';
  color: ${params => params.$theme.primaryColor};

  @media (max-width: 767px) {
    margin-top: 0px;
    font-size: 24px;
  }
`

const Descr = styled.div`
  margin-top: 21px;
  width: 660px;
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 20px;
  color: #133b51;

  @media (max-width: 767px) {
    margin-top: 15px;
    font-size: 16px;
    width: 100%;
  }
`

const IconList = styled.div`
  margin-top: 43px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 767px) {
    gap: 14px;
    margin-top: 30px;
  }
`

const IconListItem = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`

const IconListIcon = styled.div`
  width: 57px;
  height: 57px;
  border-radius: 100vw;
  background-color: #fff;
  box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.1);
  background-image: url(${params => params.$icon});
  background-repeat: no-repeat;
  background-size: 28px;
  background-position: center center;

  @media (max-width: 767px) {
    width: 48px;
    height: 48px;
    background-size: 24px;
  }
`

const IconListText = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 20px;
  font-weight: 400;
  color: #133b51;
  width: 610px;

  @media (max-width: 767px) {
    width: fit-content;
    font-size: 16px;
  }
`

const Btn = styled.a`
  margin-top: 55px;
  display: block;
  text-decoration: none;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #f2cf1c;
  box-sizing: border-box;
  padding: 15px 32px;
  border-radius: 10px;
  border: 2px solid #f2cf1c;
  width: fit-content;

  @media (max-width: 767px) {
    margin-top: 21px;
  }
`

export default function TopSection ()
{
  const { lang } = useContext(LangContext);

  const iconsList = [
    '/home/ico0.svg',
    '/home/ico2.svg'
  ]

  const theme = useContext(ThemeContext);

  return (
    <SeasonsWrapper>
      <LeftImage />
      <Right>
        <Title $theme={theme}>
          {Translations[lang].seasons.title}
        </Title>
        <Descr>
          {Translations[lang].seasons.descr}
        </Descr>
        <IconList>
          {iconsList.map((ico, key) => (
            <IconListItem key={key}>
              <IconListIcon $icon={ico} />
              <IconListText>
                {Translations[lang].seasons.list[key]}
              </IconListText>
            </IconListItem>
          ))}
        </IconList>
        <Btn>
          {Translations[lang].seasons.btn}
        </Btn>
      </Right>
    </SeasonsWrapper>
  )
}
import { useContext } from "react"
import styled from "styled-components"
import { LangContext } from "../../App"

import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import plLocale from 'i18n-iso-countries/langs/pl.json';
import deLocale from 'i18n-iso-countries/langs/de.json';

const Wrapper = styled.a`
  text-decoration: none;
  position: ${params => params.$position};
  width: 293px;
  height: 91px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.2);
  translate: ${params => params.$translate};
  box-sizing: border-box;
  padding: 7px 14px;
  display: flex;
  gap: 29px;

  @media (max-width: 767px) {
    width: 161px;
    height: 50px;
    gap: 15px;
    padding: 4px 8px;
  }
`

const CountryTooltipThumb = styled.div`
  width: 76px;
  height: 76px;
  border-radius: 100vw;
  background-size: cover;
  background-position: center center;
  background-image: url(${params => `${params.$thumb}`});

  @media (max-width: 767px) {
    width: 42px;
    height: 42px;
  }
`

const CountryTooltipInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;

  @media (max-width: 767px) {
    gap: 4px;
  }
`

const CountryTooltipTitle = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-weight: 400;
  font-size: 24px;
  color: #133b51;

  @media (max-width: 767px) {
    font-size: 13px;
  }
`

const CountryTooltipProps = styled.div`
  display: flex;
  gap: 14px;

  @media (max-width: 767px) {
    gap: 7px;
  }
`

const CountryTooltipProp = styled.div`
  display: flex;
  gap: 8px;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  color: #133b51;
  
  > img {
    width: 23px;
  }

  @media (max-width: 767px) {
    gap: 4px;
    font-size: 11px;
    
    > img {
      width: 11px;
    }
  }
`

const Title = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 32px;
  font-weight: 400;
  color: #133b51;

  > span {
    font-family: 'Gilroy-ExtraBold';
  }
`


export default function CountryTooltip ({ position = 'absolute', translate = '0px', name, hearthCount, chatCount, thumb})
{
  const { lang } = useContext(LangContext);

  countries.registerLocale(plLocale);
  countries.registerLocale(enLocale);
  countries.registerLocale(deLocale);

  return (
    <Wrapper $translate={translate} $position={position}>
      <CountryTooltipThumb $thumb={thumb} />
      <CountryTooltipInfo>
        <CountryTooltipTitle>{countries.getName(name, lang)}</CountryTooltipTitle>
        <CountryTooltipProps>
          <CountryTooltipProp>
            <img src="/about/heart.svg" />
            {hearthCount}
          </CountryTooltipProp>
          <CountryTooltipProp>
            <img src="/about/comments.svg" />
            {chatCount}
          </CountryTooltipProp>
        </CountryTooltipProps>
      </CountryTooltipInfo>
    </Wrapper>
  )
}
import { useContext } from "react"
import styled from "styled-components"
import { LangContext } from "../../App"
import { Translations } from "../AboutUs/Lang"

const Wrapper = styled.div`
  margin-top: 50px;
  display: flex;
  gap: 73px;

	@media (max-width: 767px) {
		flex-direction: column;
		gap: 18px;
		box-sizing: border-box;
		padding: 0px 15px;
	}
`

const Image = styled.div`
  border-radius: 10px;
  background-image: url(/about/img1.webp);
  background-position: center center;
  background-size: cover;
  width: 435px;
  height: 592px;

	@media (max-width: 767px) {
		height: 350px;
		width: 100%;
	}
`

const Right = styled.div`
  box-sizing: border-box;
  padding-top: 8px;
  width: calc(1320px - 35px - 435px);
  background-image: url(/watermark.png);
  background-repeat: no-repeat;
  background-size: 550px;
  background-position: bottom right;

	@media (max-width: 767px) {
		padding: 0px;
		width: 100%;
		background-size: contain;
    background-position: center center;
	}
`

const Title = styled.div`
	margin-top: 15px;
	font-family: 'Gilroy-Light';
	font-size: 32px;
	font-weight: 400;
	color: #133b51;

	> span {
			font-family: 'Gilroy-ExtraBold';
	}

	@media (max-width: 767px) {
		text-align: center;
		font-size: 24px;
	}
`

const Descr = styled.div`
  margin-top: 19px;
  box-sizing: border-box;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  color: #133b51;
  width: 710px;

	@media (max-width: 767px) {
		width: 100%;
		font-size: 16px;
	}
`

const IconList = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 13px;

	@media (max-width: 767px) {
		gap: 30px;
	}
`

const IconListItem = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`

const IconListIcon = styled.div`
  width: 57px;
  height: 57px;
  border-radius: 100vw;
  background-color: #fff;
  box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.1);
  background-image: url(${params => params.$icon});
  background-repeat: no-repeat;
  background-size: 28px;
  background-position: center center;

	@media (max-width: 767px) {
		min-width: 43px;
		width: 43px;
		height: 43px;
	}
`

const IconListText = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 20px;
  font-weight: 400;
  color: #133b51;
  width: 610px;

	@media (max-width: 767px) {
		width: 100%;
		font-size: 16px;
	}
`

const BtnWrapper = styled.div`
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;

	@media (max-width: 767px) {
		margin-top: 40px;
		justify-content: center;
	}
`

const Btn = styled.a`
	text-decoration: none;
	display: block;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #133b51;
  box-sizing: border-box;
  padding: 15px 43px;
  border-radius: 10px;
  border: 2px solid #133b51;
  width: fit-content;
	height: fit-content;
`

export default function MeetForsail ({})
{

  const { lang } = useContext(LangContext);

  const iconsList = [
    '/home/ico3.svg',
    '/home/ico4.svg',
    '/home/ico5.svg'
  ];

  return (
    <Wrapper>
      <Image />
      <Right>
        <Title>
          {Translations[lang].meetForsail}
        </Title>
        <Descr>
          {Translations[lang].meetDescr}
        </Descr>
        <IconList>
          {iconsList.map((icon, key) => (
            <IconListItem key={key}>
              <IconListIcon $icon={icon} key={key} />
              <IconListText>
                {Translations[lang].iconList1[key]}
              </IconListText>
            </IconListItem>
          ))}
        </IconList>
        <BtnWrapper>
          <Btn href="/o-nas">
            {Translations[lang].readMore}
          </Btn>
        </BtnWrapper>
      </Right>
    </Wrapper>
  )
}
export const Translations = {
  pl: {
    contactForm: {
      formTitle: "FORMULARZ KONTAKTOWY",
      name: "Imię",
      surname: "Nazwisko",
      mail: "Wpisz swój e-mail",
      subject: "Temat Twojego zgłoszenia",
      message: "Twoja Wiadomość",
      rodo: "Wyrażam zgodę na przetwarzanie danych, które są wpisane w formularzu kontaktowym, będą przetwarzane tylko w celu udzielania odpowiedzi na przesłane zapytane zgodnie z Regulaminem i RODO.",
      send: "WYŚLIJ",
    },
    contactSection: {
      title: "Chcesz dowiedzieć się czegoś więcej?",
      description:
        "Masz pytania? Chcesz zarezerwować rejs? Skontaktuj się z nami, a my odezwiemy się do Ciebie najszybciej, jak to możliwe!",
      contactUs: "SKONTAKTUJ SIĘ",
      followUs: "Obserwuj nas po więcej:",
    },
  },
};

Translations.en = Translations.pl;
Translations.de = Translations.pl;

/**
 * TODO: Zła nazwa, powinno być z angielska
 */

import { styled } from 'styled-components';
import Footer from '../../Components/Footer/Footer';
import { createContext, useEffect, useState } from 'react';
import Results from './Results';
import Filters from './Filters';
import PageMenu from '../../Components/Menu/Factory/PageMenu';

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;

  display: grid;
  grid-template-columns: 0.25fr 0.75fr;
  gap: 50px;

  @media (max-width: 767px) {
    width: 100%;
    display: block;
  }
`


export const OffersFilterContext = createContext();

export default function OfertyPage ()
{
  const [filters, setFilters] = useState({
    start: null,
    days: null,
    yachtCharter: null,
    country: null,
    heads: null,

    minCabins: 0,
    maxCabins: 18,
    minWc: 0,
    maxWc: 23,
    minLength: 1,
    maxLength: 50,
    kind: [],
    equipment: []
  });


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const newFilters = {...filters};

    for (const key of Object.keys(filters)) {
      if (params.has(key)) {
        newFilters[key] = params.get(key);
      }
    }

    if (typeof newFilters.kind == 'string') {
      newFilters.kind = newFilters.kind.split(',');
    }

    if (typeof newFilters.equipment == 'string') {
      newFilters.equipment = newFilters.equipment.split(',').map(n => parseInt(n));
    }

    setFilters(newFilters);
  }, []);


  return (
    <OffersFilterContext.Provider value={{filters, setFilters}}>
      <PageMenu title="Oferty" />
      <Wrapper>
        <Filters />
        <Results />
      </Wrapper>
      <Footer />
    </OffersFilterContext.Provider>
  )
}
import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext } from "react"
import {LangContext} from "../../../../App"
import CountryTooltip from "../../../../Components/About/CountryTooltip"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 90px;
  align-items: center;

  @media (max-width: 767px) {
    margin-top: 55px;
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const Title = styled.div`
  text-align: center;
  font-family: 'Gilroy-Light';
  font-size: 32px;
  font-weight: 400;
  color: #1a87c5;
  > span {
    font-family: 'Gilroy-ExtraBold';
  }

  @media (max-width: 767px) {
    font-size: 24px;
  }
`

const Centered = styled.div`
  width: 965px;
  text-align: center;
  margin-top: 6px;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Descr = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  color: #133b51;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`

const FiftyFifty = styled.div`
  margin-top: 33px;
  width: 100%;
  display: flex;
  gap: 50px;
  align-items: center;

  @media (max-width: 767px) {
    gap: 0px;
  }
`

const Image = styled.div`
  width: 50%;
  height: 516px;
  border-radius: 10px;
  background-image: url(/sub/incentive/booking.webp);
  background-size: cover;
  background-position: center left;

  @media (max-width: 767px) {
    translate: 15px;
    height: 430px;
    width: 55%;
    background-position: center center;
  }
`

const Lefted = styled.div`
  width: 527px;
  @media (max-width: 767px) {
    display: none;
  }
`

const Right = styled.div`
  width: 50%;

  @media (max-width: 767px) {
    width: 45%;
    display: flex;
    justify-content: center;
  }
`

const LocationGrid = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 34px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 18px;
    translate: -15px;
    margin-top: 0px;
  }
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 28px;
  margin-top: 43px;

  font-family: 'Gilroy-ExtraBold';
  font-weight: 400;
  font-size: 20px;
  color: #1a87c5;

  @media (max-width: 767px) {
    margin-top: 20px;
    font-size: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
`

const Button = styled.a`
  text-decoration: none;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background-color: #1a87c5;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 11px 36px;
`

const HideButtonsOnDesktop = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`

const HideButtonsOnMobile = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`

export default function CompanyChartersSection ()
{
  const { lang } = useContext(LangContext);

  const OutbundledButtons = function ()
  {
    return (
      <Buttons>
        <div>
          {Translations[lang].andManyOther}
        </div>
        <Button href="/incentive/czartery-dla-firm">
          {Translations[lang].checkOutButton}
        </Button>
      </Buttons>
    )
  }

  const countryTooltips = [
    {
      name: 'POL',
      hearth: 120,
      chat: 12,
    },
    {
      name: 'HRV',
      hearth: 120,
      chat: 12,
    },
    {
      name: 'GRC',
      hearth: 120,
      chat: 12,
    },
    {
      name: 'ITA',
      hearth: 120,
      chat: 12,
    },
    {
      name: 'CRI',
      hearth: 120,
      chat: 12,
    },
    {
      name: 'SYC',
      hearth: 120,
      chat: 12,
    }
  ];

  return (
    <Wrapper>
      <Title>
        {Translations[lang].title}
      </Title>
      <Centered>
        <Descr>
          {Translations[lang].descr}
        </Descr>
      </Centered>
      <FiftyFifty>
        <Image />
        <Right>
          <Lefted>
            <Descr>
              {Translations[lang].subtitle}
            </Descr>
          </Lefted>
          <LocationGrid>
            {countryTooltips.map((tooltip, key) => (
              <CountryTooltip
                key={key}
                position="block"
                hearthCount={tooltip.hearth}
                chatCount={tooltip.chat}
                name={tooltip.name}
                thumb={`/sub/incentive/company-directions/${key}.webp`}
              />
            ))}
          </LocationGrid>
          <HideButtonsOnMobile>
            <OutbundledButtons/>
          </HideButtonsOnMobile>
        </Right>
      </FiftyFifty>
      < HideButtonsOnDesktop>
        <OutbundledButtons />
      </ HideButtonsOnDesktop>
    </Wrapper>
  )
}
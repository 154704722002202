import { useContext } from "react"
import styled from "styled-components"
import { LangContext } from "../../App"
import { Translations } from "./Lang"
import Login from "./Login"
import Register from "./Register"
import Reset from "./Reset"
import Activate from "./Activate"
import AuthMenu from "../../Components/Menu/Factory/AuthMenu";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #fbfdff;
  display: flex;

	@media (max-width: 767px) {
		flex-direction: column;
		align-items: center;
		width: 100%;
		
		background-image: url(/auth/bg.webp);
		background-size: cover;
		background-position: center center;
		box-sizing: border-box;
		padding: 0px 23px;
	}
`

const Left = styled.div`
  width: 50%;
  background-image: url(/auth/bg.webp);
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: flex-end;
  align-items: center;

	@media (max-width: 767px) {
		width: 100%;
		background: none;
		justify-content: center;
		align-items: flex-start;
	}
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 200px;

	@media (max-width: 767px) {
		margin-top: 30px;
		padding-bottom: 0px;
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
`

const Button = styled.a`
  text-decoration: none;
  display: block;
  width: 200px;
  box-sizing: border-box;
  padding: 30px 0px 30px 44px;
  background-color: ${params => params.$active ? '#fff' : 'transparent'};
  border-radius: 80px 0px 0px 80px;
  font-family: 'Gilroy-ExtraBold';
  font-size: 24px;
  font-weight: 400;
  color: ${params => params.$active ? '#133b51' : '#fff'};

	@media (max-width: 767px) {
		color: ${params => params.$active ? '#133b51' : '#fff'};
		background-color: ${params => params.$active ? 'rgba(255, 255, 255, 0.9)' : 'transparent'};
		border-radius: 5px;
		padding: 22px 38px;
		width: 100%;
		font-size: 20px;
	}
`

const Right = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

	@media (max-width: 767px) {
		width: 100%;
		
		background-color: rgba(255, 255, 255, 0.9);
		border-radius: 5px;
		box-sizing: border-box;
		padding: 20px 13px 22px 13px;
		margin-top: 8px;
	}
`

const Descr = styled.div`
  margin-top: 31px;
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 20px;
  color: #133b51;
  width: 300px;
  text-align: center;

	@media (max-width: 767px) {
		margin-top: 12px;
		font-size: 16px;
		width: 100%;
	}
`

/**
 * ----------------------------------------
 */

export const Form = styled.form`
  margin-top: 30px;
  width: 360px;
  display: flex;
  gap: 19px;
  flex-direction: column;

	@media (max-width: 767px) {
		width: 100%;
		gap: 12px;
		margin-top: 15px;
	}
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`

export const Input = styled.input`
  width: 100%;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 300;
  color: #133b51;
  box-sizing: border-box;
  padding: 14px 10px;
  border-radius: 5px;
  border: 1px solid #133b51;
  background-color: transparent;
  outline: none;
  ${params => params.width != null ? 'width: ' + params.width : ''}

  &:hover {
    border-color: hsl(0, 0%, 70%);
  }
  &:focus {
    border-color: #2684ff;
    box-shadow: 0px 0px 0px 1px #2684ff;
  }
  &::placeholder {
    color: #878b93;
  }

	@media (max-width: 767px) {
		background-color: #fff;
	}
`

export const LoginBtn = styled.button`
  border-radius: 5px;
  background-color: #133b51;
  font-family: 'Manrope';
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  box-sizing: border-box;
  padding: 11px 20px;
  outline: none;
  border: none;
`

export const InputButton = styled.input`
  border-radius: 5px;
  background-color: #133b51;
  font-family: 'Manrope';
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  box-sizing: border-box;
  padding: 16px;
  outline: none;
  border: none;
  width: 100%;
  cursor: pointer;
`

export const Link = styled.a`
  text-decoration: none;
  font-family: 'Gilroy-ExtraBold';
  font-size: 14px;
  font-weight: 400;
  color: #133b51;
`

export const Label = styled.label`
  font-family: 'Gilroy-Light';
  font-size: 10px;
  font-weight: 400;
  color: #133b51;
`

export const AuthMode = {
  Login: 0,
  Register: 1,
  Reset: 2,
  Activate: 3
}

export const Error = function ({ message })
{
  const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    box-sizing: border-box;
    padding: 0px 10px 0px 10px;
  `
  const Icon = styled.img`
    width: 11px;
  `
  const Message = styled.div`
    color: #f40f0f;
    font-family: 'Gilroy-Light';
    font-size: 14px;
    font-weight: 400;
  `
  return (
    <Wrapper>
      <Icon src="/auth/error.svg" />
      <Message>
        {message}
      </Message>
    </Wrapper>
  )
}

const Logo = styled.img`
	width: 245px;

	@media (max-width: 767px) {
		width: 179px;
	}
`

export default function AuthPage ({ authMode })
{

  const { lang } = useContext(LangContext);

  const modes = {}

  modes[AuthMode.Login] = <Login />
  modes[AuthMode.Register] = <Register />
  modes[AuthMode.Reset] = <Reset />
  modes[AuthMode.Activate] = <Activate />

  const langs = ['login', 'register', 'reset', 'activate'];

  return (
  <>
	  <Wrapper>
		  <AuthMenu />
		  <Left>
			  <Menu>
				  <Button href="/logowanie" $active={authMode == AuthMode.Login}>
					  {Translations[lang].signin}
				  </Button>
				  <Button href="/rejestracja" $active={authMode == AuthMode.Register}>
					  {Translations[lang].signup}
				  </Button>
			  </Menu>
		  </Left>
		  <Right>
			  <a href="/">
				  <Logo src="/logo_dark.svg" />
			  </a>
			  <Descr>
				  {Translations[lang][langs[authMode]].descr}
			  </Descr>
			  {modes[authMode]}

		  </Right>
	  </Wrapper>
  </>
  )
}

export const Translations = {
  pl: {
    /** Company Charter */
    title: <>Czartery <span>DLA FIRM</span></>,
    descr: "Utrzymywanie odpowiednich relacji z klientami było i będzie zawsze najważniejszym celem każdego przedsiębiorstwa. Możliwość wykorzystania luksusowego jachtu łączy przyjemne z pożytecznym.  ",
    subtitle: "Luksusowy jacht, jako miejsce na spotkanie biznesowe daje praktycznie nieograniczone możliwości: ",
    andManyOther: "i wiele innych destynacji!  ",
    checkOutButton: "SPRAWDŹ",

    readMore: {
      moreButton: "Czytaj więcej",
      lessButton: "Czytaj mniej"
    }

  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;
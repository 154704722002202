
import { styled } from 'styled-components';
import { Translations } from './Lang';
import { useContext } from 'react';
import { LangContext } from '../../../../App';

const Wrapper = styled.div`
  margin-top: 86px;
  box-sizing: border-box;
  padding: 101px 13px 20px 53px;
  background-image: url(/sub/incentive/ships.webp);
  border-radius: 10px;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    border-radius: 0px;
    margin-top: 40px;
    padding: 30px 15px 44px 15px;
    background-position: center left 30%;
  }
`

const Title = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 32px;
  font-weight: 400;
  color: #1a87c5;
  > span {
    font-family: 'Gilroy-ExtraBold';
  }

  @media (max-width: 767px) {
    font-size: 24px;
  }
`
const Descr = styled.div`
  margin-top: 22px;
  width: 495px;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  color: #133b51;

  @media (max-width: 767px) {
    margin-top: 33px;
    font-size: 16px;
    width: 294px;
  }
`

const Button = styled.a`
  margin-top: 25px;
  align-self: flex-end;
  text-decoration: none;
  font-family: 'Gilroy-ExtraBold';
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 13px 46px;
  border: 2px solid #fff;
  text-transform: uppercase;

  @media (max-width: 767px) {
    align-self: center;
    color: #133B51;
    border-color: #133B51;
  }
`

export default function Regattas ()
{
  const { lang } = useContext(LangContext);

  return (
    <Wrapper>
      <Title>
        {Translations[lang].regattas.title}
      </Title>
      <Descr>
        {Translations[lang].regattas.descr}
      </Descr>
      <Button href="/incentive/regaty-firmowe">
        {Translations[lang].regattas.button}
      </Button>
    </Wrapper>
  )
}
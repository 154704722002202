import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../../../../App"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px 0px;
  margin-top: 30px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
    gap: 50px 35px;
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Icon = styled.div`
  height: 100px;
  width: 100%;
  background-image: url(/sub/camp/ico${params => params.$icon}.svg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  @media (max-width: 767px) {
    height: 63px;
  }
`

const Title = styled.div`
  width: 300px;
  margin-top: 20px;
  font-family: 'Gilroy-ExtraBold';
  font-size: 20px;
  font-weight: 400;
  color: #143a51;
  line-height: 28px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 22px;
    width: 100%;
  }
`

export default function CampIcons ()
{
  const { lang } = useContext(LangContext);

  return (
    <Wrapper>
      {Translations[lang].campIcons.map((label, key) => (
        <Box key={key}>
          <Icon $icon={key}/>
          <Title>
            {label}
          </Title>
        </Box>
      ))}
    </Wrapper>
  )
}
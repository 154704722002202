import { useContext, useEffect, useState } from "react"
import { FilterModelsContext } from "./ModelsPage"
import axios from "axios";
import __server from "../../Api";
import styled from "styled-components";
import { Translations } from "./Lang";
import { LangContext } from "../../App";
import Model from "./Model";
import Pagination from "./Pagination";
import { ScaleLoader } from "react-spinners";
import ShortDescription from "./ShortDescription";

const Wrapper = styled.div`
  width: 980px;

  @media (max-width: 767px) {
    width: 100%;
  }
`


const Summary = styled.div`
  margin-top: 30px;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  > span {
    color: #878b93;
  }

  @media (max-width: 767px) {
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const Results = styled.div`
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 35px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 24px;
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 500px;
`

const TopSectionDesktop = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`

export default function Models ()
{
  const { lang } = useContext(LangContext);
  const { filters, setFilters } = useContext(FilterModelsContext);

  const [data, setData] = useState(null);

  useEffect(() => {
    fetchModelsFromAPI();
  }, [filters]);


  const fetchModelsFromAPI = function ()
  {
    setData(null);
    /**
     * Zapytanie do api jest dość skomplikowane i zależy od
     * parametów get
     */

    const searchParams = new URLSearchParams();

    searchParams.append('page', filters.page);
    searchParams.append('minCabins', filters.minCabins);
    searchParams.append('maxCabins', filters.maxCabins);
    searchParams.append('minLength', filters.minLength);
    searchParams.append('maxLength', filters.maxLength);

    if (filters.model != null) {
      searchParams.append('model', filters.model);
    }

    for (const kind of filters.kind) {
      searchParams.append('kind[]', kind);
    }

    const searchQuery = searchParams.toString();
    axios.get(`${__server}/front/model/search?${searchQuery}`).then((resp) => {
      setData(resp.data);
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
    });
  }

  const RenderResults = function ()
  {
    return (
      <>
        <Summary>
          <span>{Translations[lang].results.found}:</span> {data.total} {Translations[lang].results.results}
        </Summary>
        <Results>
          {data.data && data.data.map((res, key) => (
            <Model {...res} key={key} />
          ))}
        </Results>
        <Pagination {...data} />
      </>
    )
  }

  const RenderLoading = function ()
  {
    return (
      <LoadingWrapper>
        <ScaleLoader color="#133b51"/>
      </LoadingWrapper>
    )
  }

  return (
    <Wrapper>
      <TopSectionDesktop>
        <ShortDescription />
      </TopSectionDesktop>
      {data === null && <RenderLoading />}
      {data !== null && <RenderResults />}
    </Wrapper>
  )
}
import React, { useContext } from "react"
import styled from "styled-components"
import { ThemeContext } from "../../../../App";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 17px 19px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 210px;
  border-radius: 10px;
  border: 2px solid ${params => params.$theme.primaryColor};
  box-sizing: border-box;
  padding: 18px 15px;
  justify-content: space-between;

  @media (max-width: 767px) {
    height: 155px;
  }
`

const Title = styled.div`
  text-align: center;
  font-family: 'Manrope';
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  text-transform: uppercase;
  color: #143a51;

  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 17px;
  }
`

const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 135px;
  height: 106px;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  border-radius: 20px;

  > svg > path {
    fill: ${params => params.$theme.primaryColor};
  }

  @media (max-width: 767px) {
    width: 74px;
    height: 58px;
    > svg {
      width: 30px;
    }
  }
`

export function Downloader ({ title, url })
{
  const theme = useContext(ThemeContext);

  return (
    <> 
      <Title>
        {title}
      </Title>
      <Button href={url} $theme={theme}>
        <svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path id="Vector" d="M10.2294 51.2409L0.707107 41.7186L4.30354 38.1221L12.1888 46.0074L12.3353 46.1538H12.5424H40.4576H40.6647L40.8112 46.0074L48.6965 38.1221L52.2929 41.7186L42.7706 51.2409H10.2294ZM29.8993 26.5765L36.4606 20.0123L40.057 23.6087L26.4382 37.2274L12.8194 23.6087L16.4159 20.0122L23.102 26.6983L23.9556 27.5519V26.3448V0.5H29.0457V26.223V27.4305L29.8993 26.5765Z" fill="#F2CF1C" stroke="white"/>
        </svg>
      </Button>
    </>
  )
}

export default function DownloadBox ({ children })
{
  const theme = useContext(ThemeContext);
  const downloaderList = React.Children.toArray(children);

  return (
    <Wrapper>
      {downloaderList.map((downloader, key) => <Box $theme={theme} key={key}>{downloader}</Box>)}
    </Wrapper>
  )
}
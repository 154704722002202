import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import React from "react";

export default function Carousel({
  children,
  visibleSlidesMobile,
  visibleSlidesDesktop,
  activeDotColor,
  allowTouchMobile = true
}) {
  return (
    <Swiper
      spaceBetween={20}
      breakpoints={{
        300: {
          slidesPerView: visibleSlidesMobile,
          allowTouchMove: allowTouchMobile,
        },
        768: {
          slidesPerView: visibleSlidesDesktop,
        },
      }}
      style={{
        "--swiper-pagination-color": activeDotColor || "#133B51",
        "--swiper-pagination-bullet-inactive-color": "#D4D4D4",
        "--swiper-pagination-bullet-inactive-opacity": "1",
        "--swiper-pagination-bullet-size": "14px",
        "--swiper-pagination-bullet-horizontal-gap": "11px",
        "--swiper-pagination-bottom": "5px",
        paddingBottom: "55px",
      }}
      pagination={{ clickable: true }}
      modules={[Pagination]}
    >
      {React.Children.map(children, (child) => (
        <SwiperSlide>{child}</SwiperSlide>
      ))}
    </Swiper>
  );
}

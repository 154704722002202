import Popup, {Descr, Subtitle, Title} from "./Popup";
import {useContext} from "react";
import {LangContext} from "../../../App";
import {Translations} from "./Lang";
import styled from "styled-components";

const List = styled.ul`
	margin-top: 40px;
	font-family: 'Gilroy-Light';
	font-size: 20px;
	line-height: 28px;
	color: #133b51;
`
export default function ReservationTermsPopup ({ isOpen, setIsOpen })
{
	const { lang } = useContext(LangContext);

	return (
		<Popup
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			body={<>
				<Title>
					{Translations[lang].popup.terms.title}
				</Title>
				<Subtitle>
					{Translations[lang].popup.terms.subtitle}
				</Subtitle>
				<List>
					{Translations[lang].popup.terms.list.map((item, key) => (
						<li key={key}>
							{item}
						</li>
					))}
				</List>
			</>}
		/>
	)
}
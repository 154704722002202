import React, { useContext, useState } from "react"
import styled from "styled-components";
import { ThemeContext } from "../../../../App";

export const Tab = styled.div`
  display: flex;
  flex-direction: column;
`

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  margin-top: 60px;

  @media (max-width: 767px) {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

/**
 * Górne przełączniki tabsów:
 */

const Buttons = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 9px;
  }
`

const Button = styled.div`
  width: 100%;
  display: flex;
  height: 84px;
  border-radius: 10px 10px 0px 0px;
  border: ${params => params.$isActive 
    ? '3px solid ' + params.$theme.primaryColor
    : 'none'};
  border-bottom: ${params => params.$isActive
    ? 'none'
    : '3px solid ' + params.$theme.primaryColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 11px 10px 0px 10px;

  @media (max-width: 767px) {
    width: fit-content;
    background-color: ${params => params.$isActive ? 'transparent' : '#f4f4f4'};
    border-radius: 10px;
    border: 2px solid transparent;
    border-color: ${params => params.$isActive ? params.$theme.primaryColor : 'transparent'};
    padding: 16px 21px;
    height: fit-content;
  }
`

const ButtonContent = styled.div`
  border-radius: 10px 10px 0px 0px;
  background-color: ${params => params.$isActive
    ? 'transparent'
    : '#f4f4f4'};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Manrope';
  font-weight: 700;
  text-transform: uppercase;
  color: #143a51;
  font-size: ${params => params.$isActive
    ? '24px'
    : '20px'};
  cursor: pointer;
  box-sizing: border-box;
  padding: 0px 25px;
  min-width: max-content;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 18px;
    padding: 0px;
  }
`

const TabWrapper = styled.div`
  width: 100%;
  border: 3px solid ${params => params.$theme.primaryColor};
  border-top: none;
  box-sizing: border-box;
  padding: 50px 40px;
  border-radius: 0px 0px 10px 10px;

  @media (max-width: 767px) {
    margin-top: 30px;
    border: 3px solid ${params => params.$theme.primaryColor};
    border-radius: 10px;
    padding: 20px 0px;
  }
`

export default function Tabs ({ children })
{
  const theme = useContext(ThemeContext);
  const tabList = React.Children.toArray(children);
  const [openTab, setOpenTab] = useState(tabList[0].props.title);

  return (
    <Wrapper>
      <Buttons>
        {tabList.map((tab, key) => (
          <Button key={key} $isActive={tab.props.title === openTab} $theme={theme}>
            <ButtonContent $isActive={tab.props.title === openTab} onClick={(e) => setOpenTab(tab.props.title)}>
              {tab.props.title}
            </ButtonContent>
          </Button>
        ))}
      </Buttons>
      <TabWrapper $theme={theme}>
        {tabList.map((tab, key) => openTab === tab.props.title && <div key={key}>{tab}</div> )}
      </TabWrapper>
    </Wrapper>
  )
}

export const mainTheme = {
  name: 'main',
  primaryColor: '#133b51',
  menuBarColor: 'rgba(19, 59, 81, 0.4)',
  menuDefaultGradient: 'linear-gradient(0deg, rgba(19, 59, 81, 0.30) 0%, rgba(19, 59, 81, 0.30) 100%)',
  menuButtonPrimary: '#fff',
  menuButtonSecondary: '#32C8D2',
  newsletterGradient: 'rgba(11, 126, 190, 0.9)',
  mobileMenuColor: '#fff',
  mobileMenuBackground: '#08608f',
}

export const campTheme = {
  name: 'camp',
  primaryColor: '#f2cf1c',
  menuBarColor: 'rgba(242, 207, 28, 0.7)',
  menuDefaultGradient: 'linear-gradient(0deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.02) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.20) 20.37%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(0deg, rgba(242, 207, 28, 0.08) 0%, rgba(242, 207, 28, 0.08) 100%);',
  menuButtonPrimary: '#f2cf1c',
  menuButtonSecondary: '#f2cf1c',
  newsletterGradient: 'linear-gradient(0deg, rgba(242, 207, 28, 0.8), rgba(242, 207, 28, 0.8)), linear-gradient(90deg, rgba(255, 219, 31, 0.7) 0%, rgba(242, 207, 28, 0) 100%)',
  newsletterBtnBackground: '#FFFFFF',
  newsletterTextColor: '#133B51',
  mobileMenuColor: '#f2cf1c',
  mobileMenuBackground: '#ccbf47',
}

export const academyTheme = {
  name: 'academy',
  primaryColor: '#94073A',
  menuBarColor: 'rgba(148, 7, 58, 0.5)',
  menuDefaultGradient: 'linear-gradient(0deg, rgba(223, 181, 196, 0.20) 0%, rgba(223, 181, 196, 0.20) 100%), linear-gradient(188deg, rgba(14, 69, 100, 0.30) 28.53%, rgba(26, 135, 197, 0.00) 55.98%), linear-gradient(0deg, rgba(26, 135, 197, 0.15) 0%, rgba(26, 135, 197, 0.15) 100%);',
  menuButtonPrimary: '#94073A',
  menuButtonSecondary: '#94073A',
  newsletterGradient: 'linear-gradient(0deg, rgba(148, 7, 58, 0.6), rgba(148, 7, 58, 0.6)), linear-gradient(90deg, rgba(148, 7, 58, 0.6) 0%, rgba(148, 7, 58, 0) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))',
  mobileMenuColor: '#94073A',
  mobileMenuBackground: '#90567b',
}

export const incentiveTheme = {
  name: 'incentive',
  primaryColor: '#1a87c5',
  menuBarColor: 'rgba(26, 135, 197, 0.2)',
  menuDefaultGradient: 'linear-gradient(0deg, rgba(223, 181, 196, 0.20) 0%, rgba(223, 181, 196, 0.20) 100%), linear-gradient(188deg, rgba(14, 69, 100, 0.30) 28.53%, rgba(26, 135, 197, 0.00) 55.98%), linear-gradient(0deg, rgba(26, 135, 197, 0.15) 0%, rgba(26, 135, 197, 0.15) 100%);',
  menuButtonPrimary: '#fff',
  menuButtonSecondary: '#32C8D2',
  newsletterGradient: 'rgba(26, 135, 197, 0.8)',
  newsletterBtnBackground: '#133B51',
  mobileMenuColor: '#fff',
  mobileMenuBackground: '#6ca3c2',
}

export const yachCharterTheme = {...mainTheme};
yachCharterTheme.name = 'yacht-charter';
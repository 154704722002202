import { useContext } from "react";
import { Link, Title, Descr, Subtitle } from "../Abstract/MenuComponents";
import { Translations } from "../Lang";
import { LangContext } from "../../../App";

import Menu from "../Abstract/Menu";
import styled from "styled-components";
import { IconBlock, IconBlockImage, IconBlockLink } from "../Components/MenuStripe";

const BlogTitle = styled.div`
  width: 100%;
  margin-top: 89px;
  text-align: center;
  font-family: 'Gilroy-ExtraBold';
  font-size: 40px;
  font-weight: 400;
  color: #fff;

  @media (max-width: 767px) {
    font-size: 28px;
    text-align: left;
    margin-top: 0px;
    box-sizing: border-box;
    padding: 0px 15px 30px 15px;
    width: 250px;
  }
`

const MobileTitleCutoff = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`

export default function BlogMenu ({ title, backgroundImage })
{
  const { lang } = useContext(LangContext);

  return (
    <Menu 
      backgroundImage={backgroundImage}
      gradient="linear-gradient(180deg, rgba(217, 217, 217, 0.0) 10%, rgba(0, 0, 0, 0.72) 100%)"

      navigation={<>
        <Link href="/">Incentive</Link>
        <Link href="/">Academy</Link>
        <Link href="/">Camp</Link>
        <Link href="/">Yacht Charter</Link>
        <Link href="/o-nas">{Translations[lang].aboutUs}</Link>
        <Link href="/blog">{Translations[lang].blog}</Link>
      </>}

      middleSection={<>
        <MobileTitleCutoff>
          <Title>{Translations[lang].blog}</Title>
        </MobileTitleCutoff>
      </>}

      bottomSection={
        <BlogTitle>{title}</BlogTitle>
      }

      stripeItems={
        <>
          <IconBlock>
            <IconBlockImage src="/ico/phone.svg" />
            <IconBlockLink href="tel:+48668676019">
              +48 668 676 019
            </IconBlockLink>
          </IconBlock>
          <IconBlock>
            <IconBlockImage src="/ico/phone.svg" />
            <IconBlockLink href="tel:+48887234362">
              +48 887 234 362
            </IconBlockLink>
          </IconBlock>
        </>
      }
    />
  )
}
/**
 * Strona O nas
 */

import styled from 'styled-components';
import Footer from '../../Components/Footer/Footer';
import ContactSection from '../../Components/ContactSection/ContactSection';
import { Translations } from './Lang';
import { useContext, useEffect, useState } from 'react';
import { LangContext } from '../../App';

import DirectionsSection from '../../Components/About/DirectionsSection';
import Testimonials from '../../Components/Misc/Testimonials';
import PageMenu from '../../Components/Menu/Factory/PageMenu';

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  margin-top: 180px;

	@media (max-width: 767px) {
    width: 100%;
    margin-top: 30px;
  }
`

const Title = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 32px;
  font-weight: 400;
  color: #133b51;

  > span {
    font-family: 'Gilroy-ExtraBold';
  }

  margin-top: ${params => params.$top != null ? params.$top : 0}px;

  @media (max-width: 767px) {
    font-size: 24px;
    margin-top: 20px;
  }
`

const MeetForsail = styled.div`
  margin-top: ${params => params.$top};
  display: flex;
  gap: 35px;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 18px;
    box-sizing: border-box;
    padding: 0px 15px;
  }

`

const MeetForsailImage = styled.div`
  width: ${params => params.$wid};
  border-radius: 10px;
  background-image: url(${params => params.$bg});
  background-position: center center;
  background-size: cover;

  @media (max-width: 767px) {
    width: 100%;
    height: 250px;

    &:first-child {
      display: none;
    }
  }
`

const MeetForsailCard = styled.div`
  box-sizing: border-box;
  padding-top: 8px;
  width: calc(1320px - 35px - 435px);
  ${params => params.$watermark ? 'background-image: url(/watermark.png);' : ''};
  background-repeat: no-repeat;
  background-size: 550px;
  background-position: bottom right;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const MeetForsailDescr = styled.div`
  box-sizing: border-box;
  padding-left: 30px;
  padding-top: 19px;
  padding-bottom: 19px;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  color: #133b51;
  width: 710px;

  @media (max-width: 767px) {
    margin-top: 20px;
    width: 100%;
    padding: 0px;
    font-size: 16px;
    padding-bottom: 35px;
  }
`

const IconList = styled.div`
  box-sizing: border-box;
  padding-top: 30px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  gap: 13px;

  @media (max-width: 767px) {
    padding: 0px;
    margin-top: 20px;
  }
`

const IconListItem = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`

const IconListIcon = styled.div`
  width: 57px;
  height: 57px;
  border-radius: 100vw;
  background-color: #fff;
  box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.1);
  background-image: url(${params => params.$icon});
  background-repeat: no-repeat;
  background-size: 28px;
  background-position: center center;

  @media (max-width: 767px) {
    width: 43px;
    height: 43px;
    flex-shrink: 0;
  }
`

const IconListText = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 20px;
  font-weight: 400;
  color: #133b51;
  width: 610px;
  @media (max-width: 767px) {
    width: 100%;
    font-size: 16px;
  }
`

const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  gap: 57px;
  margin-top: 68px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 30px;
    box-sizing: border-box;
    padding: 0px 15px;
    margin-top: 30px;
  }
`

const Card = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 30px;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  color: #000;
`

const CardThumb = styled.div`
  width: 58px;
  height: 58px;
  border-radius: 100vw;
  background-color: #fff;
  background-image: url(${props => props.$ico});
  background-size: 30px;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.15);
`
/**
 *  ----------------------------------------
 */

const SafetyBounding = styled.div`
  @media (max-width: 767px) {
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const Safety = styled.div`
  margin-top: 171px;
  box-sizing: border-box;
  border-radius: 10px;
  background-image: url(/about/safety.webp);
  padding: 67px 42px;

  @media (max-width: 767px) {
    padding: 25px 20px;
    margin-top: 45px;
  }
`

const SafetyTitle = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 32px;
  font-weight: 400;
  color: #fff;

  > span {
    font-family: 'Gilroy-ExtraBold';
  }

  @media (max-width: 767px) {
    font-size: 26px;
  }
`

const SafetyDescr = styled.div`
  margin-top: 45px;
  font-family: 'Gilroy-Light';
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  width: 583px;

  @media (max-width: 767px) {
    width: 100%;
    font-size: 20px;
    margin-top: 5px;
  }
`

const SafetyThumbs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 602px;
  margin-left: calc(1320px - 602px);
  box-sizing: border-box;
  gap: 39px 57px;
  padding-right: 29px;
  alingn-self: flex-end;
  margin-top: -530px;

  @media (max-width: 767px) {
    margin: 0px;
    width: 100%;
    gap: 10px;
    box-sizing: border-box;
    padding: 0px 15px;
    margin-top: 30px;
  }
`

const SafetyThumbBox = styled.div`
  width: 258px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: -4px -4px 30px 0px rgba(0, 0, 0, 0.15);

  @media (max-width: 767px) {
    width: 100%;
  }
`

const SafetyThumb = styled.div`
  height: 161px;
  border-radius: 10px 10px 0px 0px;
  background-image: url(${params => params.$bg});
  background-size: cover;
  background-position: center center;
`

const SafetyInfo = styled.div`
  box-sizing: border-box;
  height: 129px;
  padding: 0px 25px;
  display: flex;
  align-items: center;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  color: #000;

  @media (max-width: 767px) {
    font-size: 16px;
    padding: 0px 15px;
    height: 100px;
  }
`
/**
 *  ----------------------------------------
 */

const WinterBar = styled.div`
  height: 1px;
  background-color: #133b51;
  opacity: 0.5;
  filter: drop-shadow(4px 4px 6.8px rgba(0, 0, 0, 0.5));
  margin-top: ${params => params.$top}px;

  @media (max-width: 767px) {
    width: calc(100% - 2 * 15px);
    margin: auto;
    margin-top: 30px;
  }
`

const Winter = styled.div`
  margin-top: 33px;
  display: flex;
  gap: 21px;

  @media (max-width: 767px) {
    margin-top: 20px;
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const WinterBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 57px;

  @media (max-width: 767px) {
    gap: 20px;
  }
`

const WinterImage = styled.div`
  width: 425px;
  height: 201px;
  border-radius: 10px;
  background-image: url(/about/img5.webp);
  background-size: cover;
  background-position: center center;

  @media (max-width: 767px) {
    display: none;
  }
`

const WinterProps = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 35px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`

const WinterProp = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 20px;
  color: #133b51;
  width: 100%;
`

const MobileDirectionsWrapper = styled.div`
  @media (max-width: 767px) {
    margin-top: -50px;
  }
`

const OrganizationBounding = styled.div`
  @media (max-width: 767px) {
    width: 300px;
    text-align: center;
    margin: auto;
  }
`

export default function AboutUsPage ({})
{
  const { lang } = useContext(LangContext);

  const iconsList1 = [
    '/about/ico1.svg',
    '/about/ico1.svg',
    '/about/ico1.svg'
  ]

  const iconsList2 = [
    '/about/ico1.svg',
    '/about/ico1.svg',
    '/about/ico1.svg'
  ]

  const cardsIcons = [
    '/about/ico1.svg',
    '/about/ico1.svg',
    '/about/ico1.svg',
    '/about/ico1.svg'
  ];

  const thumbsList = [
    '/about/img5.webp',
    '/about/img5.webp',
    '/about/img5.webp',
    '/about/img5.webp'
  ]

  const winterIcons = [
    '/about/ico1.svg',
    '/about/ico1.svg',
    '/about/ico1.svg'
  ]

  return (
    <>
      <PageMenu title={Translations[lang].pageTitle} />
      <Wrapper>
        <MeetForsail $top="-90px">
          <MeetForsailImage $bg="/about/img1.webp" $wid="435px" />
          <MeetForsailCard $watermark="true">
            <Title>
              {Translations[lang].meetForsail}
            </Title>
            <MeetForsailDescr>
              {Translations[lang].meetDescr}
            </MeetForsailDescr>
            <Title>
              {Translations[lang].iconList1Title}
            </Title>
            <IconList>
            {iconsList1.map((icon, key) => (
              <IconListItem key={key}>
                <IconListIcon $icon={icon} />
                <IconListText>
                  {Translations[lang].iconList1[key]}
                </IconListText>
              </IconListItem>
            ))}
            </IconList>
          </MeetForsailCard>
        </MeetForsail>
        <MeetForsail $top="50px">
          <MeetForsailCard>
            <Title>
              {Translations[lang].iconList2Title}
            </Title>
            <IconList>
            {iconsList2.map((icon, key) => (
              <IconListItem key={key}>
                <IconListIcon $icon={icon} />
                <IconListText>
                  {Translations[lang].iconList2[key]}
                </IconListText>
              </IconListItem>
            ))}
            </IconList>
          </MeetForsailCard>
          <MeetForsailImage $bg="/about/img2.webp" $wid="500px" />
        </MeetForsail>
   
        <MobileDirectionsWrapper>
          <DirectionsSection />
        </MobileDirectionsWrapper>


        <OrganizationBounding>
          <Title $top={77}>
            {Translations[lang].goodOrganization}
          </Title>
        </OrganizationBounding>

        <Cards>
          {cardsIcons.map((icon, key) => (
            <Card key={key}>
              <CardThumb $ico={icon} />
              {Translations[lang].goodOrganizationCards[key]}
            </Card>
          ))}
        </Cards>


        <SafetyBounding>
          <Safety>
            <SafetyTitle>
              {Translations[lang].safety}
            </SafetyTitle>
            <SafetyDescr>
              {Translations[lang].safetyDescr}
            </SafetyDescr>
          </Safety>
        </SafetyBounding>

        <SafetyThumbs>
          {thumbsList.map((thumb, key) => (
            <SafetyThumbBox key={key}>
              <SafetyThumb $bg={thumb} />
              <SafetyInfo>
                {Translations[lang].safetyCards[key]}
              </SafetyInfo>
            </SafetyThumbBox>
          ))}
        </SafetyThumbs>


        <WinterBar $top={60} />
        <Winter>
          <WinterImage />
          <WinterBox>
            <Title>{Translations[lang].winterToo}</Title>
            <WinterProps>
              {winterIcons.map((icon, key) => (
                <WinterProp key={key}>
                  <div>
                    <CardThumb $ico={icon} />
                  </div>
                  {Translations[lang].winterBlocks[key]}
                </WinterProp>
              ))}
            </WinterProps>
          </WinterBox>
        </Winter>
        <WinterBar $top={34} />
      </Wrapper>
      <Testimonials top="60px" />
      <ContactSection top="93px" />
      <Footer />
    </>
  )
}
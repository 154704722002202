import Popup, {Descr, Subtitle, Title} from "./Popup";
import {useContext} from "react";
import {LangContext} from "../../../App";
import {Translations} from "./Lang";
import styled from "styled-components";


const Items = styled.div`
	margin-top: 40px;
	display: flex;
	flex-direction: column;
	gap: 20px;
`

const ItemTitle = styled.div`
	font-family: 'Gilroy-ExtraBold';
	font-size: 22px;
	color: #133b51;
`

const List = styled.ul`
	margin-top: 10px;
	font-family: 'Gilroy-Light';
	font-size: 16px;
	color: #133b51;
`

export default function WhichPatentPopup ({ isOpen, setIsOpen })
{
	const { lang } = useContext(LangContext);

	return (
		<Popup
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			body={<>
				<Title>
					{Translations[lang].popup.patents.title}
				</Title>
				<Subtitle>
					{Translations[lang].popup.patents.subtitle}
				</Subtitle>
				<Items>
					{Translations[lang].popup.patents.items.map((item, key) => (
						<div key={key}>
							<ItemTitle>
								{item.title}
							</ItemTitle>
							<List>
								{item.list.map((element, subkey) => (
									<li key={subkey}>{element}</li>
								))}
							</List>
						</div>
					))}
				</Items>
			</>}
		/>
	)
}

export const Translations = {
  pl: {
    title: "Niezapomniane przygody na mazurskich wodach",
    descr: "Zapewniamy doskonałą zabawę i niezapomniane wspomnienia! Z nami poczujesz prawdziwy żeglarski klimat na własnej skórze!",
    offer: {
      title: "Stażowy obóz żeglarski na Mazurach",
      descr: <>Mazury – cud natury – nie bez powodu ten region Polski otrzymał takie miano. Nasz obóz żeglarski odbywa się wśród malowniczych krajobrazów mazurskich jezior pod opieką wykwalifikowanej kadry i w miłej atmosferze. 
      <br /> <br />
      Obóz ma na celu wprowadzić młodych adeptów do świata żeglarstwa i pokazać, jak wygląda codzienne życie na szerokich wodach. Z nami przeżyjesz niezapomniane przygody i zdobędziesz niezbędną wiedzę, spędzając aktywnie czas na łonie natury. 
      </>,
      list: [
        "Najwyższy poziom organizacyjny rejsów",
        "Bezpieczeństwo uczestników",
        "Komfortowe, nowoczesne i bezpieczne jachty Antila 27",
        "Rozwój cech charakteru: zaradność, pewność siebie, współpraca w grupie",
        "Niezapomniane przygody, relaks i żeglarski klimat\n"
      ],
      timeTable: [ 
        "Przyjazd w godzinach popołudniowych, zaokrętowanie, odprawa, kolacja",
        "Śniadanie, odprawa, żeglowanie, postój na lunch, kolacja",
        "Śniadanie, zakończenie obozu, powrót do domu"
      ],
      downloads: [
        {
          title: "Ogólne Warunki Uczestnictwa",
          url: "#"
        },
        {
          title: "Upoważnienie do Odbioru Dziecka",
          url: "#"
        },
        {
          title: "Regulamin Uczestnika Obozu",
          url: "#"
        },
        {
          title: "Co zabrać na obóz żeglarski",
          url: "#"
        },
        {
          title: "Karta Informacyjna RODO",
          url: "#"
        },
        {
          title: "Wpis do Rejestru Organizatorów Turystyki",
          url: "#"
        },
        {
          title: "Gwarancja Ubezpieczeniowa",
          url: "#"
        },
        {
          title: "Standardowy Formularz informacyjny",
          url: "#"
        },
        {
          title: "OWU NNW RP Signal Iduna",
          url: "#"
        },
        {
          title: "Ubezpieczenie Kosztów Imprezy Bezpieczne Rezerwacje",
          url: "#"
        },
      ]
    },
  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;
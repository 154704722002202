import styled from "styled-components";
import { Translations } from "./Lang";
import { useContext } from "react";
import { LangContext } from "../../App";

const Title = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 32px;
  font-weight: 400;
  color: #133b51;
  text-align: center;
  width: 385px;

  > span {
    font-family: 'Gilroy-ExtraBold';
  }

  @media (max-width: 767px) {
    font-size: 24px;
    width: 100%;

    box-sizing: border-box;
    padding: 0px 15px;
    margin-bottom: -30px;
  }
`;

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  margin-top: ${params => params.$top};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  @media (max-width: 767px) {
    width: 100%;
    gap: 0px;
    margin-bottom: -50px;
  }
`;

const Opinions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  @media (max-width: 767px) {
    width: fit-content;
    gap: 8px;
  }
`;

const Opinion = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  padding: 15px 16px;
  gap: 10px;
  font-family: 'Gilroy-Light';
  font-size: 16px;
  font-weight: 400;
  color: #133b51;

  @media (max-width: 767px) {
    justify-content: flex-end;
    width: 260px;
    font-size: 14px;
  }
`;

const Nick = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 16px;
  font-weight: 400;
  color: #133b51;

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const Carousel = styled.div`
  @media (max-width: 767px) {
    width: 100%;
    overflow: scroll hidden;
    box-sizing: border-box;
    padding: 60px 15px;
  }
`;

const Stars = styled.img`
  height: 18px;
  @media (max-width: 767px) {
    height: 14px;
  }
`;

const Pfp = styled.img`
  height: 64px;
  @media (max-width: 767px) {
    height: 48px;
  }
`;

const Descr = styled.div`
  height: 100%;
`

export default function Testimonials({ top }) 
{
  const { lang } = useContext(LangContext);

  const opinions = [
    {
      pfp: "/testimonials/pfp.png",
      opinion: "Ola była zachwycona kolonią żeglarską. Zajęcia były świetnie zorganizowane, a instruktorzy bardzo zaangażowani i cierpliwi. Cieszymy się, że mogła przeżyć takie wakacje!",
      nick: "Anna K.",
    },
    {
      pfp: "/testimonials/pfp.png",
      opinion: "Rejs integracyjny zorganizowany przez tę firmę był niesamowitym doświadczeniem dla naszego zespołu. Z pewnością skorzystamy z ich usług ponownie!",
      nick: "Firma AKAN",
    },
    {
      pfp: "/testimonials/pfp.png",
      opinion: "Dzięki Forsail udało mi się uzyskać stopień sternika morskiego. Program szkoleniowy był przejrzysty, zrozumiały i wartościowy, a zdobyta wiedza z pewnością przyda mi się w przyszłości! ",
      nick: "Piotr S.",
    },
  ];

  return (
    <Wrapper $top={top}>
      <Title>{Translations[lang].testimonials}</Title>
      <Carousel>
        <Opinions>
          {opinions.map((opinion, key) => (
            <Opinion key={key}>
              <Descr>
                {opinion.opinion}
              </Descr>
              <Stars src="/testimonials/stars.svg" />
              <Pfp src={opinion.pfp} />
              <Nick>{opinion.nick}</Nick>
            </Opinion>
          ))}
        </Opinions>
      </Carousel>
    </Wrapper>
  );
}

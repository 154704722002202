import { useContext } from "react"
import styled from "styled-components"
import { ThemeContext } from "../../../App"

const Title = styled.div`
  font-family: 'Manrope';
  font-weight: 800;
  font-size: 20px;
  text-transform: uppercase;
  color: ${params => params.$theme.primaryColor};

  > span {
    font-weight: 300;
    text-transform: lowercase;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
`

const Content = styled.div`
  margin-top: 14px;
  margin-bottom: 28px;
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #143a51;
  padding-left: 22px;
  box-sizing: border-box;

  > b {
    font-family: 'Gilroy-ExtraBold';
    font-weight: 900;
  }

  @media (max-width: 767px) {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 16px;
  }
`

const List = styled.ul`
  margin-top: 14px;
  margin-bottom: 28px;
  padding-inline-start: 40px;
  box-sizing: border-box;

  @media (max-width: 767px) {
    margin-top: 10px;
    margin-bottom: 15px;
    padding-inline-start: 30px;
  }
`

const Element = styled.li`
  padding-left: 7px;
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: #143a51;
  
  @media (max-width: 767px) {
    font-size: 16px;
    padding: 0px;
  }
`

const Wrapper = styled.div`
  @media (max-width: 767px) {
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

export default function Article ({title, contents}) 
{
  const theme = useContext(ThemeContext);

  const isPlainText = (contents) =>
    (typeof contents === "object" || typeof contents === "string") 
    && !(contents instanceof Array)

  if (title === null && contents === null) {
    return <></>;
  }

  return (
    <Wrapper>
      <Title $theme={theme}>
        {title}
      </Title>

      { isPlainText(contents) && <Content>{contents}</Content>}
      {contents instanceof Array && (
        <List>
          {contents.map((item, key) => <Element key={key}>{item}</Element>)}
        </List>
      )}
    </Wrapper>
  )
}

export const Translations = {
  pl: {
    filters: {
      title: 'Filtruj',
      kind: 'Typ jednostki',
      kinds: [
        "Jacht Żaglowy",
        "Katamaran",
        "Katamaran Motorowy",
        "Jacht Motorowy",
      ],
      company: "Armator",
      model: "Model",
      cabins: "Liczba kabin",
      length: <>Długość <span>(w metrach)</span></>
    },
    results: {
      title: <>Katalog <span>jachtów</span></>,
      descr: "Naszą flotę tworzą nowoczesne, bezpieczne i komfortowe jachty, które sprawiają, że podróż staje się prawdziwą przyjemnością. Regularny serwis i innowacyjne udogodnienia zapewniają pełną wygodę i bezwzględne bezpieczeństwo na wodzie! Elegancja, styl i tradycja – nasze jachty oferują niezapomniane wrażenia z każdego rejsu.",
      found: "Znaleziono",
      results: "wyników"
    },
    result: {
      year: 'Rocznik',
      cabins: 'Kabiny',
      len: 'Długość',
      checkout: 'SPRAWDŹ JACHTY'
    },
    pagination: {
      of: 'z'
    }
  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;
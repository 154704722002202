import { useContext, useEffect, useState } from "react";
import SelectRow from "./SelectRow";
import axios from "axios";
import __server from "../../../Api";
import { LangContext } from "../../../App";

import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import plLocale from 'i18n-iso-countries/langs/pl.json';
import deLocale from 'i18n-iso-countries/langs/de.json';

import { Translations as MenuTranslations } from "../../../Components/Menu/Lang"

export default function CountryInput ()
{
  const [options, setOptions] = useState([]);
  const { lang } = useContext(LangContext);

  countries.registerLocale(plLocale);
  countries.registerLocale(enLocale);
  countries.registerLocale(deLocale);

  useEffect(() => {
    axios.get(`${__server}/front/pages/countries`).then((resp) => {
      setOptions(resp.data.map((code) => {
        return {
          value: code,
          label: countries.getName(code, lang)
        }
      }))
    }).catch((err) => {
      console.error('[hero menu]', err);
    })
  }, []);

  return (
    <SelectRow
      name="country" 
      options={options} 
      label={MenuTranslations[lang].slCountry} 
      placeholder={MenuTranslations[lang].spCountry} 
      isClearable={true}
      isSearchable={true}
    />
  )
}
/**
 * Menu na podstronach
 */

import styled from "styled-components";
import {useContext, useState} from "react";
import { ThemeContext } from "../../../App";
import MenuStripe from "../Components/MenuStripe";
import MenuSlider from "../Components/MenuSlider";
import MobileMenu from "./MobileMenu";
import MenuButtons from "./MenuButtons";
import MobileSlider from "./MobileSlider";

const Header = styled.header`
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${props => props.$bg});
`

const GradientOverlay = styled.div`
  width: 100%;
  min-height: ${params => params.$minHeight}px;
  background: ${params => params.$gradient};
  
  @media (max-width: 767px) {
    min-height: 0px;
  }
`

const InnerMenu = styled.div`
  width: 1320px;
  margin: auto;
  box-sizing: border-box;
  padding-bottom: 26px;
  
  @media (max-width: 767px) {
    width: 100%;
    padding-bottom: 0px;
  }
`


const MenuBarWrapper = styled.div`
  width: 1320px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-top: 28px;
  
  @media (max-width: 767px) {
    width: 100%;
    padding: 20px 15px;
  }
`

const Logo = styled.img`
  width: 175px;
`

const LogoWrapper = styled.a`
  text-decoration: none;
`


const Nav = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 42px;
  @media (max-width: 767px) {
    display: none;
  }
`
const Buttons = styled.div`
  display: flex;
  gap: 17px;
`

const MiddleSectionWrapper = styled.div`
  padding-top: 85px;
  @media (max-width: 767px) {
    box-sizing: border-box;
    width: 100%;
    padding: 15px;
  }
`

const MobileMenuButton = styled.button`
  display: none;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0px;
  box-sizing: border-box;
  
  > svg {
    width: 26px;
    height: 13px;
  }
  
  > svg > path {
    stroke: ${params => params.$theme.mobileMenuColor};
  }

  @media (max-width: 767px) {
    display: block;
  }
`

export default function Menu ({ 
  backgroundOverwrite,
  middleSection,
  bottomSection,
  navigation,
  backgroundImage = '/menu/bg-default.webp',
  gradient,
  stripeItems,
  menuSliderTop = 270,
  disabledMiddleSection = false
})
{
  const theme = useContext(ThemeContext);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <>
      <Header $bg={backgroundOverwrite ? backgroundOverwrite : backgroundImage}>
        <GradientOverlay $gradient={gradient ?? theme.menuDefaultGradient} $minHeight={disabledMiddleSection ? 0 : 458}>
          <MenuStripe stripeItems={stripeItems} />
          <InnerMenu>
            <MenuBarWrapper>
              <LogoWrapper href="/">
                <Logo src="/menu/logo.svg" />
              </LogoWrapper>
              <Nav>
                {navigation}
                <Buttons>
                  <MenuButtons />
                </Buttons>
              </Nav>

              <MobileMenuButton $theme={theme} onClick={(e) => setIsMobileMenuOpen(true)}>
                <svg width="28" height="15" viewBox="0 0 28 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id="Vector" d="M27 7.5H6.41667M27 1H1M27 14H14.3611" stroke="#F2CF1C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </MobileMenuButton>
            </MenuBarWrapper>

            {!disabledMiddleSection && (
              <>
                <MiddleSectionWrapper>
                  {middleSection}
                </MiddleSectionWrapper>
                {bottomSection}
                <MobileSlider />
              </>
            )}
          </InnerMenu>
        </GradientOverlay>
      </Header>
      {!disabledMiddleSection && <MenuSlider top={menuSliderTop} /> }
      <MobileMenu navigation={navigation} isOpen={isMobileMenuOpen} setIsOpen={setIsMobileMenuOpen} />
    </>
  )
}
import { useContext } from "react";
import { Link, Title, Descr, Subtitle } from "../Abstract/MenuComponents";
import { Translations } from "./Lang";
import { LangContext } from "../../../App";

import Menu from "../Abstract/Menu";
import MenuSearcher from "../Abstract/MenuSearcher";
import styled from "styled-components";
import { IconBlock, IconBlockImage, IconBlockLink } from "../Components/MenuStripe";

const MiddleWrapper = styled.div`
  width: 450px;

  @media (max-width: 767px) {
    width: 340px;
  }
`

export default function HomePageMenu ({})
{
  const { lang } = useContext(LangContext);

  return (
    <Menu 
      backgroundOverwrite={"/home/bg.webp"}
      navigation={
        <>
          <Link href="/katalog-jachtow">{Translations[lang].home.yachtsCatalogue}</Link>
          <Link href="/nasze-destynacje">{Translations[lang].home.ourDestinations}</Link>
          <Link href="/o-nas">{Translations[lang].home.aboutUs}</Link>
          <Link href="/blog">{Translations[lang].home.blog}</Link>
        </>
      }

      middleSection={
        <MiddleWrapper>
          <Subtitle>Yacht Charter</Subtitle>
          <Title>{Translations[lang].home.title}</Title>
          <Descr>{Translations[lang].home.descr}</Descr>
        </MiddleWrapper>
      }

      bottomSection={
        <MenuSearcher />
      }

      stripeItems={
        <>
          <IconBlock>
            <IconBlockImage src="/ico/phone.svg" />
            <IconBlockLink href="tel:+48668676019">
              +48 668 676 019
            </IconBlockLink>
          </IconBlock>
          <IconBlock>
            <IconBlockImage src="/ico/phone.svg" />
            <IconBlockLink href="tel:+48887234362">
              +48 887 234 362
            </IconBlockLink>
          </IconBlock>
        </>
      }   

      menuSliderTop={370}
    />
  )
}
import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../../../../App"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 31px;
  box-sizing: border-box;
  padding-left: 43px;
`

const Title = styled.div`
  width: 260px;
  color: #94073a;
  font-family: 'Manrope';
  font-size: 20px;
  font-weight: 700;
`

const Prop = styled.div`
  color: #143a51;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;

`

const Row = styled.div`
  display: flex;
`

const BoldProp = styled.div`
  color: #143a51;
  font-family: 'Gilroy-ExtraBold';
  font-size: 20px;
  font-weight: 400;
`

export default function AcademyYacht ({
  typeOfYacht, length, width, submerge, cabins, toilets, grota, foka, fins
})
{
  const { lang } = useContext(LangContext);

  return (
    <Wrapper>
      <Row>
        <Title>
          {Translations[lang].yacht.typeOfYacht}
        </Title>
        <BoldProp>
          {typeOfYacht}
        </BoldProp>
      </Row>
      <Row>
        <Title>
          {Translations[lang].yacht.length}
        </Title>
        <Prop>
          {length}
        </Prop>
      </Row>
      <Row>
        <Title>
          {Translations[lang].yacht.width}
        </Title>
        <Prop>
          {width}
        </Prop>
      </Row>
      <Row>
        <Title>
          {Translations[lang].yacht.submerge}
        </Title>
        <Prop>
          {submerge}
        </Prop>
      </Row>
      <Row>
        <Title>
          {Translations[lang].yacht.cabins}
        </Title>
        <Prop>
          {cabins}
        </Prop>
      </Row>
      <Row>
        <Title>
          {Translations[lang].yacht.toilets}
        </Title>
        <Prop>
          {toilets}
        </Prop>
      </Row>
      <Row>
        <Title>
          {Translations[lang].yacht.grota}
        </Title>
        <Prop>
          {grota}
        </Prop>
      </Row>
      <Row>
        <Title>
          {Translations[lang].yacht.foka}
        </Title>
        <Prop>
          {foka}
        </Prop>
      </Row>
      <Row>
        <Title>
          {Translations[lang].yacht.fins}
        </Title>
        <Prop>
          {fins}
        </Prop>
      </Row>

    </Wrapper>
  )
}
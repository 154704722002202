import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../../../App"

const Wrapper = styled.div`
  margin-top: 49px;
  display: flex;
  flex-direction: column;
  gap: 47px;
  font-weight: 400;
  color: #133b51;

  background-image: url(/watermark.png);
  background-size: 550px;
  background-repeat: no-repeat;
  background-position: bottom right;
  box-sizing: border-box;
  padding-bottom: 49px;

  @media (max-width: 767px) {
    box-sizing: border-box;
    padding: 0px 15px;
    margin-top: 15px;
    gap: 15px;
  }
`

const Title = styled.div`
  text-align: center;
  font-family: 'Gilroy-ExtraBold';
  font-size: 32px;

  @media (max-width: 767px) {
    font-size: 24px;
    text-align: left;
  }
`

const Descr = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 16px;
  width: 939px;

  @media (max-width: 767px) {
    width: 100%;
    font-size: 14px;
  }
`

export default function Intro ()
{
  const { lang } = useContext(LangContext);

  return (
    <Wrapper>
      <Title>
        {Translations[lang].intro.title}
      </Title>
      <Descr>
        {Translations[lang].intro.descr}
      </Descr>

    </Wrapper>
  )
}
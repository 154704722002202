
export const Translations = {
  pl: {
    title: "Niezapomniane przygody i prawdziwy żeglarski klimat",
    descr: "Zapraszamy na pięć dni pełnych ciekawych zajęć i zabaw w otoczeniu przyrody, podczas których Twoje dziecko zdobędzie pierwsze żeglarskie doświadczenia!",
    offer: {
      title: "Półkolonie żeglarskie dla dzieci i młodzieży 10-14 lat",
      descr: <>Zajęcia przeznaczone są dla młodych adeptów żeglarstwa, którzy chcą aktywnie spędzić czas w bliskości natury i zdobyć podstawową wiedzę na temat żeglarstwa. Na każdego z uczestników czekają kreatywne zajęcia i zadania – tu nie ma miejsca na nudę. 
      <br /> <br />
      Półkolonie stanowią idealny wstęp i początek nauki do kursu na żeglarza jachtowego. Pomagamy również dzieciom rozwinąć swój charakter – zaradność, umiejętność pracy w zespole, pewność siebie. To cenny i wartościowy czas dla Twojego dziecka!
      </>,
      list: [
        "Aktywnie spędzony czas w bliskości natury",
        "Kreatywne zajęcia i zadania",
        "Rozwijanie zainteresowań i pasji",
        "Przygotowanie do szkolenia na patent żeglarza jachtowego",
        "Rozwój cech charakteru: zaradność, pewność siebie, działanie w grupie, odpowiedzialność"
      ],
      timeTable: [ 
        "zbiórka dzieci w Ośrodku Górka Szczęśliwicka", 
        "zajęcia żeglarskie",
        "obiad", 
        "zajęcia żeglarskie",
        "podwieczorek", 
        "odbiór dzieci z Ośrodka Górka Szczęśliwicka"
      ],
      downloads: [
        {
          title: "Ogólne Warunki Uczestnictwa",
          url: "#"
        },
        {
          title: "Upoważnienie do Odbioru Dziecka",
          url: "#"
        },
        {
          title: "Regulamin Uczestnika Półkolonii",
          url: "#"
        },
        {
          title: "Co zabrać na półkolonie żeglarskie",
          url: "#"
        },
        {
          title: "Karta Informacyjna RODO",
          url: "#"
        },
        {
          title: "Wpis do Rejestru Organizatorów Turystyki",
          url: "#"
        },
        {
          title: "Gwarancja Ubezpieczeniowa",
          url: "#"
        },
        {
          title: "Standardowy Formularz informacyjny",
          url: "#"
        },
      ]
    },
  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;
import styled from "styled-components"
import { __gcloud } from "../../Api"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../App"

const Wrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding-bottom: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 767px) {
    padding-bottom: 30px;
  }
`

const Image = styled.div`
  background-image: url(${__gcloud}/${params => params.$src});
  background-size: cover;
  background-position: center center;
  border-radius: 10px 10px 0px 0px;
  height: 265px;
  width: 100%;

  @media (max-width: 767px) {
    height: 217px;
  }
`

const Title = styled.div`
  margin-top: 18px;
  font-family: 'Gilroy-ExtraBold';
  font-size: 36px;
  font-weight: 400;
  color: #133b51;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 30px;
  }
`

const Button = styled.button`
  font-family: 'Manrope';
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  background-color: #1a87c5;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px 30px;
  outline: none;
  margin-top: 25px;
  border: none;
  cursor: pointer;
  align-self: center;
  width: fit-content;

  @media (max-width: 767px) {
    margin-top: 12px;
    font-size: 14px;
  }
`



export default function City ({ id, name, image, setPopupItem })
{
  const { lang } = useContext(LangContext);

  const onClick = function (e)
  {
    const url = new URL(window.location);
    url.searchParams.set('miasto', id);
    window.history.pushState({}, '', url);
    setPopupItem(id);
  }

  return (
    <Wrapper onClick={onClick}>
      <Image $src={image} />
      <Title>
        {name}
      </Title>
      <Button>
        {Translations[lang].country.detailsButton}
      </Button>
    </Wrapper>
  )
}
import styled from "styled-components";
import Footer from "../../../../Components/Footer/Footer";
import IncentiveMenu from "../../../../Components/Menu/Factory/IncentiveMenu";
import ContactSection from "../../../../Components/ContactSection/ContactSection";
import Newsletter from "../../../../Components/Newsletter/Newsletter";
import IncentiveTopSection from "../Components/IncentiveTopSection";
import { useContext } from "react";
import { LangContext } from "../../../../App";
import { Translations } from "./Lang";
import CompanyChartersSection from "../Components/CompanyChartersSection";

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const InfoStripe = styled.div`
  margin-top: 40px;
  display: flex;

  border-radius: 10px;
  background-color: #fff;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.15);
  

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    &:last-child {
      flex-direction: column;
    }
  }
`

const Image = styled.div`
  width: 590px;
  height: 325px;
  background-image: url(${params => params.$src});
  background-size: cover;
  background-position: center center;
  border-radius: ${params => params.$radius};

  @media (max-width: 767px) {
    width: 100%;
    height: 200px;
    border-radius: 10px 10px 0px 0px;
  }
`

const Content = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding: 0px 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  color: #133b51;
  > b {
    font-family: 'Gilroy-ExtraBold';
  }

  @media (max-width: 767px) {
    font-size: 16px;
    padding: 23px;
  }
`

const List = styled.ul`
  margin-top: 25px;
  @media (max-width: 767px) {
    margin-top: 5px;
    padding-inline-start: 20px;
  }
`

const Item = styled.li`

`

const InfoWrapper = styled.div`
  @media (max-width: 767px) {
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

export default function CompanyChartersPage ()
{
  const { lang } = useContext(LangContext);

  const DynamicContent = function ({translation}) 
  {
    return (
      <Content>
        <div>
          {translation.descr}
        </div>
        <List>
          {translation.list.map((item, key) => (
            <Item key={key}>
              {item}
            </Item>
          ))}
        </List>
      </Content>
    )
  }

  return (
    <>
      <IncentiveMenu 
        title={Translations[lang].title}
      />
      <Wrapper>
        <IncentiveTopSection
          descr={Translations[lang].descr}
        />
        <CompanyChartersSection />

        <InfoWrapper>
          <InfoStripe>
            <DynamicContent translation={Translations[lang].upperCard}/>
            <Image $src="/sub/incentive/company-charters/0.webp" $radius="0px 10px 10px 0px"/>
          </InfoStripe>

          <InfoStripe>
            <Image $src="/sub/incentive/company-charters/1.webp" $radius="10px 0px 0px 10px"/>
            <DynamicContent translation={Translations[lang].lowerCard}/>
          </InfoStripe>
        </InfoWrapper>

        <Newsletter top="86px" />
        <ContactSection top="75px" />
      </Wrapper>
      <Footer />
    </>
  )
}
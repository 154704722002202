import { useContext } from "react";
import { Link } from "../Abstract/MenuComponents";
import { LangContext } from "../../../App";

import Menu from "../Abstract/Menu";
import styled from "styled-components";
import { Translations } from "./Lang";
import { IconBlock, IconBlockImage, IconBlockLink } from "../Components/MenuStripe";

const Subtitle = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 20px;
  font-weight: 400;
  color: #94073A;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`

const Title = styled.div`
  margin-top: 9px;
  font-family: 'Gilroy-ExtraBold';
  font-size: 48px;
  line-height: 48px;
  font-weight: 400;
  color: #fff;

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 35px;
    width: 221px;
  }
`

const Descr = styled.div`
  margin-top: 13px;
  font-family: 'Gilroy-Light';
  font-size: 24px;
  font-weight: 300;
  color: #fff;

  @media (max-width: 767px) {
    font-size: 16px;
    width: 264px;
  }
`

const MiddleWrapper = styled.div`
  width: 685px;
  padding-bottom: ${params => params.$paddingBottom}px;

  @media (max-width: 767px) {
   width: 100%; 
    padding-bottom: 20px;
  }
`

export default function AcademyMenu ({ subtitle, title, descr, paddingBottom = 66 })
{
  const { lang } = useContext(LangContext);

  return (
    <Menu 
      menuSliderTop={370}
      backgroundImage="/sub/academy/bg.webp"

      navigation={<>

        <Link href="/academy/szkolenie-jachtowy-sternik-morski">{Translations[lang].academy.yachtHelmsman}</Link>
        <Link href="/">{Translations[lang].academy.yachtSailor}</Link>
        <Link href="/">{Translations[lang].academy.additionalCurses}</Link>
        <Link href="/">{Translations[lang].academy.internCruises}</Link>
        <Link href="/">{Translations[lang].academy.testBase}</Link>
      </>}

      middleSection={<MiddleWrapper $paddingBottom={paddingBottom}>
        <Subtitle>{subtitle}</Subtitle>
        <Title>{title}</Title>
        <Descr>{descr}</Descr>
        </MiddleWrapper>
      }

      stripeItems={
        <>
          <IconBlock>
            <IconBlockImage src="/ico/phone.svg" />
            <IconBlockLink href="tel:+48668678019">
              +48 668 678 019
            </IconBlockLink>
          </IconBlock>
          <IconBlock>
            <IconBlockImage src="/ico/phone.svg" />
            <IconBlockLink href="tel:+48606780197">
              +48 606 780 197
            </IconBlockLink>
          </IconBlock>
          <IconBlock>
            <IconBlockImage src="/ico/envelope.svg" />
            <IconBlockLink href="mailto:szkolenia@forsail.pl">
              szkolenia@forsail.pl
            </IconBlockLink>
          </IconBlock>
        </>
      }
    />
  )
}


export const InstagramIcon = function ()
{
  return (<>
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
      <g id="instagram-svgrepo-com 1">
        <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M15.5 23.25C19.7802 23.25 23.25 19.7802 23.25 15.5C23.25 11.2198 19.7802 7.75 15.5 7.75C11.2198 7.75 7.75 11.2198 7.75 15.5C7.75 19.7802 11.2198 23.25 15.5 23.25ZM15.5 20.6667C18.3534 20.6667 20.6667 18.3534 20.6667 15.5C20.6667 12.6465 18.3534 10.3333 15.5 10.3333C12.6465 10.3333 10.3333 12.6465 10.3333 15.5C10.3333 18.3534 12.6465 20.6667 15.5 20.6667Z" fill="#133B51"/>
        <path id="Vector_2" d="M23.2499 6.45825C22.5365 6.45825 21.9583 7.03656 21.9583 7.74992C21.9583 8.46328 22.5365 9.04159 23.2499 9.04159C23.9633 9.04159 24.5416 8.46328 24.5416 7.74992C24.5416 7.03656 23.9633 6.45825 23.2499 6.45825Z" fill="#133B51"/>
        <path id="Vector_3" fillRule="evenodd" clipRule="evenodd" d="M2.13645 5.52333C1.29175 7.18114 1.29175 9.35135 1.29175 13.6917V17.3084C1.29175 21.6488 1.29175 23.8191 2.13645 25.4768C2.87946 26.9351 4.06506 28.1207 5.52333 28.8637C7.18114 29.7084 9.35135 29.7084 13.6917 29.7084H17.3084C21.6488 29.7084 23.8191 29.7084 25.4768 28.8637C26.9351 28.1207 28.1207 26.9351 28.8637 25.4768C29.7084 23.8191 29.7084 21.6488 29.7084 17.3084V13.6917C29.7084 9.35135 29.7084 7.18114 28.8637 5.52333C28.1207 4.06506 26.9351 2.87946 25.4768 2.13645C23.8191 1.29175 21.6488 1.29175 17.3084 1.29175H13.6917C9.35135 1.29175 7.18114 1.29175 5.52333 2.13645C4.06506 2.87946 2.87946 4.06506 2.13645 5.52333ZM17.3084 3.87508H13.6917C11.4789 3.87508 9.97466 3.8771 8.81194 3.97209C7.67935 4.06462 7.10017 4.23234 6.69613 4.43821C5.72396 4.93356 4.93356 5.72396 4.43821 6.69613C4.23234 7.10017 4.06462 7.67935 3.97209 8.81194C3.8771 9.97466 3.87508 11.4789 3.87508 13.6917V17.3084C3.87508 19.5213 3.8771 21.0254 3.97209 22.1882C4.06462 23.3209 4.23234 23.9 4.43821 24.3041C4.93356 25.2762 5.72396 26.0666 6.69613 26.5619C7.10017 26.7678 7.67935 26.9356 8.81194 27.0281C9.97466 27.123 11.4789 27.1251 13.6917 27.1251H17.3084C19.5213 27.1251 21.0254 27.123 22.1882 27.0281C23.3209 26.9356 23.9 26.7678 24.3041 26.5619C25.2762 26.0666 26.0666 25.2762 26.5619 24.3041C26.7678 23.9 26.9356 23.3209 27.0281 22.1882C27.123 21.0254 27.1251 19.5213 27.1251 17.3084V13.6917C27.1251 11.4789 27.123 9.97466 27.0281 8.81194C26.9356 7.67935 26.7678 7.10017 26.5619 6.69613C26.0666 5.72396 25.2762 4.93356 24.3041 4.43821C23.9 4.23234 23.3209 4.06462 22.1882 3.97209C21.0254 3.8771 19.5213 3.87508 17.3084 3.87508Z" fill="#133B51"/>
      </g>
    </svg>
  </>)
}

export const FacebookIcon = function ()
{
  return (<>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="33" viewBox="0 0 18 33" fill="none">
      <path id="Vector" d="M18 0.00600028L12.9993 0C8.14991 0 5.01731 3.186 5.01731 8.1225V11.865H0V18.6375H5.01731L5.01126 33H12.0313L12.0373 18.6375H17.7943L17.7897 11.8665H12.0373V8.691C12.0373 7.164 12.4018 6.3915 14.406 6.3915L17.9849 6.39L18 0.00600028Z" fill="#133B51"/>
    </svg>
  </>)
}

export const YoutubeIcon = function ()
{
  return (<>
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
      <g id="youtube-svgrepo-com 1">
        <path id="Vector" d="M13.3362 21.0984V11.9028L21.4201 16.5011L13.3362 21.0984ZM31.317 9.00805C30.952 7.6664 29.9207 6.62896 28.61 6.26184L28.5821 6.25565C25.2894 5.83696 21.481 5.59771 17.6158 5.59771C17.2229 5.59771 16.8311 5.59977 16.4402 5.60493L16.5 5.6039C16.168 5.59977 15.7771 5.59668 15.3842 5.59668C11.5191 5.59668 7.70862 5.83593 3.96828 6.29896L4.41584 6.25359C3.07831 6.62793 2.04706 7.66434 1.68921 8.97918L1.68303 9.00599C1.27053 11.1809 1.0354 13.6837 1.0354 16.2412C1.0354 16.332 1.0354 16.4227 1.03643 16.5125V16.498C1.03643 16.5743 1.0354 16.6651 1.0354 16.7558C1.0354 19.3133 1.27156 21.8151 1.72221 24.2417L1.68303 23.9901C2.04809 25.3317 3.07934 26.3691 4.39006 26.7363L4.4179 26.7425C7.71068 27.1611 11.5191 27.4004 15.3842 27.4004C15.7761 27.4004 16.168 27.3983 16.5598 27.3932L16.5 27.3942C16.8321 27.3983 17.224 27.4014 17.6158 27.4014C21.482 27.4014 25.2914 27.1622 29.0318 26.6991L28.5842 26.7445C29.9228 26.3712 30.954 25.3338 31.3129 24.0189L31.3191 23.9921C31.7306 21.8172 31.9657 19.3144 31.9657 16.7579C31.9657 16.6671 31.9657 16.5764 31.9647 16.4867V16.5001C31.9647 16.4238 31.9657 16.333 31.9657 16.2423C31.9657 13.6848 31.7295 11.183 31.2789 8.75643L31.317 9.00805Z" fill="#133B51"/>
      </g>
    </svg> 
  </>)
}

import { useContext } from "react"
import { Translations as MenuTranslations } from "../../../Components/Menu/Lang"
import { LangContext } from "../../../App"
import SelectRow from "./SelectRow";
import { convertTranslationToOptions } from "../../../utils/translation-to-options";

export default function YachtCharterInput ()
{
  const { lang } = useContext(LangContext);

  return (
    <SelectRow
      name="yachtCharter" 
      options={convertTranslationToOptions(MenuTranslations[lang].spTypes)} 
      label="Yacht Charter"
      placeholder={MenuTranslations[lang].spChoose} 
      isClearable={false}
      isSearchable={false}
    />
  )
}

import { styled } from 'styled-components';
import Faq, { AccordionItem } from '../../Faq';
import { Translations } from './Lang';
import { useContext } from 'react';

import { LangContext } from "../../../../../App";

const Wrapper = styled.div`
`

export default function AcademyFaq ({})
{
  const { lang } = useContext(LangContext);

  return (
    <Wrapper>
      <Faq>
        {Translations[lang].faq.map((item, key) => (
          <AccordionItem header={item.title} key={key}>
            {item.body}
          </AccordionItem>
        ))}
      </Faq>
    </Wrapper>
  )
}
import Select from 'react-select';
import styled from "styled-components";

export default function SelectInput ({ ...props })
{
const Wrapper = styled.div`
	width: 360px;
	@media (max-width: 767px) {
		width: 100%;
		background-color: #fff;
	}
`
  return (
  <Wrapper>
	  <Select
		  styles={{
			  indicatorSeparator: (styles) => ({display:'none'}),
			  control: baseStyles => ({
				  ...baseStyles,
				  width: "100%",
				  fontFamily: 'Poppins',
				  fontSize: "14px",
				  fontWeight: "300",
				  color: "#878b93",
				  boxSizing: "border-box",
				  padding: "10px 10px",
				  borderRadius: "5px",
				  border: "1px solid #133b51",
				  backgroundColor: "transparent",
				  outline: "none"
			  }),
			  option: baseStyles => ({
				  ...baseStyles,
				  color: 'black',
				  fontFamily: 'Poppins'
			  }),
			  singleValue: baseStyles => ({
				  ...baseStyles,
				  color: "#133b51"
			  }),
			  dropdownIndicator: base => ({
				  ...base,
				  margin: "0px",
				  padding: "0px"
			  }),
			  valueContainer: (base) => ({
				  ...base,
				  margin: "0px",
				  padding: "0px"
			  }),
			  input: (base) => ({
				  ...base,
			  })
		  }}
		  noOptionsMessage={() => "Brak wyników"}
		  {...props}
	  />
  </Wrapper>
  )
}
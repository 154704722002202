import styled from "styled-components";
import { Translations } from "./Lang";
import { useContext, useState } from "react";
import { LangContext } from "../../../../App";
import Carousel from "../../../../Components/Carousel/Carousel";
import Lightbox from 'react-awesome-lightbox';
import "react-awesome-lightbox/build/style.css";

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  margin-top: 66px;
  margin-bottom: 75px;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Title = styled.div`
  font-family: 'Manrope';
  font-size: 32px;
  font-weight: 700;
  text-transform: Uppercase;
  color: #133b51;
  box-sizing: border-box;
  padding-left: 35px;

  @media (max-width: 767px) {
    font-size: 24px;
  }
`;

const CarouselWrapper = styled.div`
  margin-top: 28px;
  padding: 0 15px;
`;

const Image = styled.div`
  width: 100%;
  height: 316px;
  background-image: url(${params => params.$img});
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  cursor: pointer;

  @media (max-width: 767px) {
    height: 170px;
  }
`;

export default function Gallery({ name }) {
  const { lang } = useContext(LangContext);
  const [lightBoxImage, setLightBoxImage] = useState(null);

  const galleryImages = Array(12).fill([]).map((_, i) => `/sub/top/gallery-images/g${i}.webp`);

  const handleImageClick = (image) => {
    setLightBoxImage(image);
  };

  return (
    <Wrapper>
      {lightBoxImage && <Lightbox image={lightBoxImage} onClose={() => setLightBoxImage(null)} />}
      <Title>
        {Translations[lang].gallery}
      </Title>
      <CarouselWrapper>
        <Carousel visibleSlidesMobile={1} visibleSlidesDesktop={3} activeDotColor="#F2CF1C">
          {galleryImages.map((image, key) => (
            <Image
              key={key}
              $img={image}
              onClick={() => handleImageClick(image)}
            />
          ))}
        </Carousel>
      </CarouselWrapper>
    </Wrapper>
  );
}

import { useContext } from "react";
import styled from "styled-components"
import { LangContext } from "../../../App";
import RowInput from "./RowInput";
import { OffersFilterContext } from "../OfertyPage";
import { Translations as MenuTranslations } from "../../../Components/Menu/Lang"
import { Translations } from "../Lang";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 9px;
`

const Kind = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Gilroy-Light';
`

const Checkbox = styled.input.attrs({type: 'checkbox'})`
  appearance: none;
  width: 16px;
  height: 16px;
  background-color: white;
  border: 1px solid #32C8D2;
  border-radius: 2px;
  display: inline-block;
  position: relative;

  &:checked {
    background-color: #32C8D2;
  }

  &:checked::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px rgba(50, 200, 210, 0.5);
  }
`

export default function EquipmentInput ({group})
{
  const { lang } = useContext(LangContext);
  const { filters, setFilters } = useContext(OffersFilterContext);

  const toggleEquipment = function (id)
  {
    const equipment = filters.kind instanceof Array 
      ? [...filters.equipment]
      : filters.equipment.split(',');


    if (equipment.includes(id)) {
      setFilters({...filters, equipment: equipment.filter(n => n != id)});
      return;
    }

    equipment.push(id);
    setFilters({...filters, equipment: equipment});
  }


  return (
    <RowInput
      label={group.group}
      htInput={
        <Wrapper>
          {group.types.map((equipment, key) => (
            <Kind key={key}>
              <Checkbox checked={filters.equipment.includes(equipment.value)} onChange={(e) => toggleEquipment(equipment.value)} />
              {equipment.label}
            </Kind>
          ))}
        </Wrapper>
      }
    />
  )
}
import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../App"

const SeasonsWrapper = styled.div`
  display: grid;
  gap: 50px;
  margin-top: 73px;
  grid-template-columns: 0.33fr 0.66fr;

	@media (max-width: 767px) {
		margin-top: 30px;
		gap: 0px;
		box-sizing: border-box;
		padding: 0px 15px;
	}
`

const LeftImage = styled.div`
  width: 435px;
  height: 100%;
  border-radius: 10px;
  background-image: url(/home/summer.webp);
  background-size: cover;
  background-position: left center;

	@media (max-width: 767px) {
		display: none;
	}
`

const Right = styled.div`
  width: calc(1320px - 435px - 74px);
  background-image: url(/home/girl.webp);
  background-repeat: no-repeat;
  background-position: bottom 20% right;
  background-size: 620px;

	@media (max-width: 767px) {
		background-size: 280px;
	}

  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  margin-top: 20px;
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 32px;
  color: #133b51;

  display: flex;
  align-items: flex-end;
  gap: 15px;

  > span {
    font-family: 'Gilroy-ExtraBold';
    color: #f2cf1c;
  }

	@media (max-width: 767px) {
		margin-top: 0px;
		font-size: 24px;
	}

  > img {
    width: 95px;
  }
`

const Descr = styled.div`
	margin-top: 21px;
	width: 556px;
	font-family: 'Gilroy-Light';
	font-weight: 400;
	font-size: 20px;
	color: #133b51;

	@media (max-width: 767px) {
		margin-top: 16px;
		font-size: 16px;
		width: 100%;
	}
`

const IconList = styled.div`
  margin-top: -15px;
  display: flex;
  flex-direction: column;
  gap: 20px;

	@media (max-width: 767px) {
		margin-top: 16px;
		gap: 16px;
	}

`

const IconListItem = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
`

const IconListIcon = styled.div`
  width: 57px;
  height: 57px;
  border-radius: 100vw;
  background-color: #fff;
  box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.1);
  background-image: url(${params => params.$icon});
  background-repeat: no-repeat;
  background-size: 28px;
  background-position: center center;

	@media (max-width: 767px) {
		width: 47px;
		height: 47px;
	}
`

const IconListText = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 20px;
  font-weight: 400;
  color: #133b51;
  width: 610px;

	@media (max-width: 767px) {
		width: 100%;
		font-size: 16px;
	}
`

const Btn = styled.a`
  display: block;
  text-decoration: none;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #f2cf1c;
  box-sizing: border-box;
  padding: 15px 32px;
  border-radius: 10px;
  border: 2px solid #f2cf1c;
  width: fit-content;
  align-self: flex-end;
`

const PromoImage = styled.div`
  margin-top: 57px;
  width: 150px;
  height: 86px;
  background-image: url(/home/ski-club.webp);
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 26px;
`

export default function Seasons ({})
{
  const { lang } = useContext(LangContext);

  const iconsList = [
    '/home/ico0.svg',
    '/home/ico1.svg',
    '/home/ico2.svg',
  ]

  return (
    <SeasonsWrapper>
      <LeftImage />
      <Right>
        <Title>
          <img src="/menu/camp.svg" />
          {Translations[lang].seasons.title}
        </Title>
        <Descr>
          {Translations[lang].seasons.descr}
        </Descr>
        <Btn href="/camp">
          {Translations[lang].seasons.btn}
        </Btn>
        <IconList>
          {iconsList.map((ico, key) => (
            <IconListItem key={key}>
              <IconListIcon $icon={ico} />
              <IconListText>
                {Translations[lang].seasons.list[key]}
              </IconListText>
            </IconListItem>
          ))}
        </IconList>
        <PromoImage />
        <div>
          <Btn href="/">
            {Translations[lang].seasons.btn2}
          </Btn>
        </div>
      </Right>
    </SeasonsWrapper>
  )
}
import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { LangContext } from "../../App"


import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import plLocale from 'i18n-iso-countries/langs/pl.json';
import deLocale from 'i18n-iso-countries/langs/de.json';
import { Translations } from "./Lang";

import axios from 'axios';
import __server from '../../Api';
import CountryTooltip from "./CountryTooltip";

const Directions = styled.div`
	margin-top: 84px;
	display: flex;
	justify-content: space-between;

	@media (max-width: 767px) {
		display: block;
		box-sizing: border-box;
		padding: 0px 15px;
	}
`

const LeftDirections = styled.div`
	background-image: url(/about/watermark2.png);
	background-size: 500px;
	background-repeat: no-repeat;
	background-position: bottom left;

	@media (max-width: 767px) {
		width: 100%;
		background-size: contain;
		background-position: bottom center;
	}
`

const DirectionsDescr = styled.div`
	font-family: 'Gilroy-Light';
	font-size: 20px;
	font-weight: 400;
	color: #133b51;
	width: 510px;
	margin-top: 19px;

	@media (max-width: 767px) {
		width: 100%;
		font-size: 16px;
		margin-top: 8px;
	}
`

const CountryBox = styled.div`
	margin-top: 29px;
	width: 510px;
	display: grid;
  grid-template-columns: 1fr 1fr;
	gap: 17px;
	
	@media (max-width: 767px) {
		width: 100%;
		margin-top: 16px;
	}
`

const Country = styled.a`
	text-decoration: none;
	font-family: 'Gilroy-ExtraBold';
	font-size: 24px;
	font-weight: 400;
	color: #133b51;
	width: 40%;

	@media (max-width: 767px) {
		font-size: 20px;
	}
`

const RightDirections = styled.div`
	margin-right: 135px;
	width: 433px;
	height: 590px;
	border-radius: 10px;
	background-image: url(/about/img3.webp);
	background-size: cover;
	background-position: center center;
	margin-top: 10px;

	@media (max-width: 767px) {
		display: none;
	}
`

const Title = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 32px;
  font-weight: 400;
  color: #133b51;

  > span {
    font-family: 'Gilroy-ExtraBold';
  }

	@media (max-width: 767px) {
		font-size: 24px;
	}
`

export default function DirectionsSection ({})
{
  const { lang } = useContext(LangContext);


  countries.registerLocale(plLocale);
  countries.registerLocale(enLocale);
  countries.registerLocale(deLocale);

  const [ data, setData ] = useState(null);

  useEffect(() => {
    axios.get(`${__server}/front/pages/about-us`).then((resp) => {
      setData(resp.data);
    }).catch((err) => {
      console.log('[failed to fetch misc data]', err);
    })
  }, []);

  const countryTooltips = [
    {
      name: 'HRV',
      hearth: 120,
      chat: 12,
      translate: '-138px 44px'
    },
    {
      name: 'GRC',
      hearth: 120,
      chat: 12,
      translate: '275px 82px'
    },
    {
      name: 'ITA',
      hearth: 120,
      chat: 12,
      translate: '-187px 278px'
    },
    {
      name: 'CRI',
      hearth: 120,
      chat: 12,
      translate: '290px 376px'
    },
    {
      name: 'SYC',
      hearth: 120,
      chat: 12,
      translate: '-74px 474px'
    }
  ]

  const today = new Date().toISOString().split('T')[0];

  return (
    <Directions>
      <LeftDirections>
        <Title>
          {Translations[lang].title}
        </Title>
        <DirectionsDescr>
          {Translations[lang].descr}
        </DirectionsDescr>
        <CountryBox>
          {data && data.map((country, key) => (
            <Country key={key} href={`/oferty?start=${today}&days=7&country=${countries.alpha3ToAlpha2(country.country)}`}>
              {countries.getName(country.country, lang)}
            </Country>
          ))}
        </CountryBox>
      </LeftDirections>
      <RightDirections>
        {countryTooltips.map((tooltip, key) => (
          <CountryTooltip
            key={key}
            hearthCount={tooltip.hearth}
            chatCount={tooltip.chat}
            name={tooltip.name}
            translate={tooltip.translate}
            thumb={`/about/directions/${key + 1}.webp`}
          />
        ))}
      </RightDirections>
    </Directions>
  )
}
import styled from "styled-components"
import ReadMore from "../Components/ReadMore"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../../../App"

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 35px;

  @media (max-width: 767px) {
    flex-direction: column;
    box-sizing: border-box;
    padding: 0px 15px;
    margin-top: 55px;
  }
`

const Left = styled.div`
  width: 40%;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Right = styled.div`
  width: 60%;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Card = styled.div`
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 32px 35px;

  @media (max-width: 767px) {
    padding: 30px 15px;
  }
`

const Title = styled.div`
  text-align: center;
  font-family: 'Gilroy-ExtraBold';
  font-size: 24px;
  font-weight: 400;
  color: #133b51;
  margin-bottom: 28px;

  @media (max-width: 767px) {
    font-size: 20px;
    margin-bottom: 19px;
  }
`

export default function InfoSection ()
{
  const { lang } = useContext(LangContext);

  return (
    <Wrapper>
      <Left>
        <Card>
          <Title>
            {Translations[lang].infoSection.leftCard.title}
          </Title>
          <ReadMore minHeight="110px">
            {Translations[lang].infoSection.leftCard.content}
          </ReadMore>
        </Card>
      </Left>
      <Right>
        <Card>
          <Title>
            {Translations[lang].infoSection.rightCard.title}
          </Title>
          <ReadMore minHeight="60px" openOnStart={true}>
            {Translations[lang].infoSection.rightCard.content}
          </ReadMore>
        </Card>
      </Right>
    </Wrapper>
  )
}
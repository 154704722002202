import styled from "styled-components";
import Footer from "../../../../Components/Footer/Footer";
import CampMenu from "../../../../Components/Menu/Factory/CampMenu";
import Newsletter from "../../../../Components/Newsletter/Newsletter";
import ContactSection from "../../../../Components/ContactSection/ContactSection";
import TopSection from "./TopSection";
import Leaflet from "../../Components/Leaflet/Leaflet";
import OffersBundle from "./Offers/OffersBundle";
import Testimonials from "../../../../Components/Misc/Testimonials";

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export default function CampPage({}) 
{
  return (
    <>
      <CampMenu
        subtitle="Camp"
        title="Tutaj, gdzie pasja do żeglarstwa spotyka się z przygodą i nauką!"
        descr="Profesjonalne programy edukacyjne, które rozwijają umiejętności, budują pewność siebie i zapewniają mnóstwo zabawy!"
        paddingBottom={166}
      />
      <Wrapper>
        <TopSection />

        <Leaflet name="camp-polkolonie" top={70} />

        <OffersBundle
          leftName="offer1"
          rightName="offer2"
          leftUrl="/camp/polkolonie-sportowo-zeglarskie"
          rightUrl="/camp/polkolonie-zeglarskie"
        />

        <Leaflet name="camp-obozy" top={56} />

        <OffersBundle
          leftName="offer3"
          rightName="offer4"
          leftUrl="/camp/oboz-szkoleniowy"
          rightUrl="/camp/obozy-zeglarskie"
        />

        <Testimonials top="80px" />

        <Newsletter top="64px" />
        <ContactSection top="64px" />
      </Wrapper>

      <Footer />
    </>
  );
}

import { useContext } from "react"
import styled from "styled-components"
import { LangContext, ThemeContext } from "../../App"
import { Translations } from "./Lang"
import Icons from "./Icons";


const Wrapper = styled.div`
  width: 1214px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: ${props => props.$top};

  @media (max-width: 767px) {
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0px 15px;
    margin-top: 50px;
  }
`

const Left = styled.div`
  width: 50%;
  background-image: url(/watermark.png);
  background-position: left bottom;
  background-size: 550px;
  background-repeat: no-repeat;

  @media (max-width: 767px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-image: none;
  }
`

const Right = styled.div`
  width: 600px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 29px 0px;

  @media (max-width: 767px) {
    margin-top: 46px;
    width: 100%;
    padding: 0px;
  }
`

const Form = styled.form`
  width: 476px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 767px) {
    box-sizing: border-box;
    padding: 20px;
  }
`

const FormTitle = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-weight: 400;
  font-size: 24px;
  color: ${params => params.$theme.primaryColor};
  width: 100%;
  text-align: center;
  
  @media (max-width: 767px) {
    width: 300px;
			align-self: center;
  }

`

const FormRow = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 15px;
  @media (max-width: 767px) {
    margin-top: 0px;
  }
`

const Input = styled.input`
  background: #fff;
  border-radius: 10px;
  border: 1px solid #133b51;
  font-family: 'Gilroy-Light';
  font-size: 14px;
  color: #133b51;
  font-weight: 400;
  box-sizing: border-box;
  padding: 22px 10px;
  outline: none;
  width: 100%;
  
  &:hover {
    border-color: hsl(0, 0%, 70%);
  }
  &:focus {
    border-color: #2684ff;
    box-shadow: 0px 0px 0px 1px #2684ff;
  }
  &::placeholder {
    color: #878b93;
  }
`

const Select = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #133b51;
  font-family: 'Gilroy-Light';
  font-size: 14px;
  color: #133b51;
  font-weight: 400;
  box-sizing: border-box;
  padding: 22px 10px;
  outline: none;
  width: 100%;
  position: relative;
  background: #fff url('/ico/chevron-input.svg') no-repeat right 10px center;
  background-size: 20px 20px;
  
  &:hover {
    border-color: hsl(0, 0%, 70%);
  }
  &:focus {
    border-color: #2684ff;
    box-shadow: 0px 0px 0px 1px #2684ff;
  }
  &::placeholder {
    color: #878b93;
  }
`
const TextArea = styled.textarea`
  background: #fff;
  border-radius: 10px;
  border: 1px solid #133b51;
  font-family: 'Gilroy-Light';
  font-size: 14px;
  color: #133b51;
  font-weight: 400;
  box-sizing: border-box;
  padding: 17px 10px;
  outline: none;
  width: 100%;
  height: 118px;
  resize: none;
  
  &:hover {
    border-color: hsl(0, 0%, 70%);
  }
  &:focus {
    border-color: #2684ff;
    box-shadow: 0px 0px 0px 1px #2684ff;
  }
  &::placeholder {
    color: #878b93;
  }
`

const Label = styled.label`
  font-family: 'Gilroy-Light';
  font-size: 12px;
  color: #133b51;
  font-weight: 400;
`

const CheckBox = styled.input`
  width: 19px;
  height: 19px;
  accent-color: #133b51;
  cursor: pointer;
`

const Rodo = styled.div`
  display: flex;
  gap: 10px;
`

const Submit = styled.button`
  font-family: 'Gilroy-ExtraBold';
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  background-color: ${params => params.$theme.primaryColor};
  box-sizing: border-box;
  border-radius: 10px;
  padding: 14px 39px;
  width: 134px;
  align-self: flex-end;
  border: none;
  outline: none;

  @media (max-width: 767px) {
    align-self: center;
  }
`

const Title = styled.div`
  margin-top: 45px;
  width: 398px;
  font-family: 'Gilroy-Light';
  font-size: 32px;
  color: #133b51;
  font-weight: 400;
  
  @media (max-width: 767px) {
    margin-top: 0px;
    width: 334px;
  }

`

const Description = styled.div`
  margin-top: 18px;
  width: 398px;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  color: #133b51;
  font-weight: 400;

  @media (max-width: 767px) {
    margin-top: 14px;
    width: 349px;
  }
`

const ContactButton = styled.a`
  display: flex;
  text-decoration: none;
  width: fit-content;
  margin-top: 27px;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 15px 37px;
  border: 2px solid ${params => params.$theme.primaryColor};
  color: ${params => params.$theme.primaryColor};
  outline: none;
  @media (max-width: 767px) {
    align-self: center;
  }
`

const IconsDesktopWrapper = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`

const IconsMobileWrapper = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`

export default function ContactSection ({ top })
{
  const { lang, setLang } = useContext(LangContext);
  const theme = useContext(ThemeContext);

  return (
    <Wrapper $top={top}>
      <Left>
        <Title>
          {Translations[lang].contactSection.title}
        </Title>
        <Description>
          {Translations[lang].contactSection.description}
        </Description>
        <ContactButton $theme={theme} href="/kontakt">
          {Translations[lang].contactSection.contactUs}
        </ContactButton>
        <IconsDesktopWrapper>
          <Icons />
        </IconsDesktopWrapper>
      </Left>
      <Right>
        <Form>
          <FormTitle $theme={theme}>
            {Translations[lang].contactForm.formTitle}
          </FormTitle>
          <FormRow>
            <Input type="text" placeholder={Translations[lang].contactForm.name} />
            <Input type="text" placeholder={Translations[lang].contactForm.surname} />
          </FormRow>
          <Input type="text" placeholder={Translations[lang].contactForm.mail} />
          <Select>
            <option hidden={true}>{Translations[lang].contactForm.subject}</option>
          </Select>
          <TextArea placeholder={Translations[lang].contactForm.message}></TextArea>
          <Rodo>
            <CheckBox id="rodo" type="checkbox" />
            <div>
              <Label htmlFor="rodo">
                {Translations[lang].contactForm.rodo}
              </Label>
            </div>
          </Rodo>
          <Submit $theme={theme}>
            {Translations[lang].contactForm.send}
          </Submit>
        </Form>
      </Right>
      <IconsMobileWrapper>
        <Icons/>
      </IconsMobileWrapper>
    </Wrapper>
  )
}

import { useContext } from "react";
import { LangContext } from "../../App";
import { Translations } from "./Lang";

import styled from "styled-components";

import Footer from "../../Components/Footer/Footer";
import Newsletter from "../../Components/Newsletter/Newsletter";
import ContactSection from "../../Components/ContactSection/ContactSection";
import Accordion from "./Accordion";
import PageMenu from "../../Components/Menu/Factory/PageMenu";

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  margin-top: 180px;

	@media (max-width: 767px) {
		width: 100%;
		margin-top: 25px;
	}
`
const MidTitle = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 32px;
  font-weight: 400;
  color: #133b51;

	@media (max-width: 767px) {
		box-sizing: border-box;
		padding: 0px 15px;
	}
`

export default function FaqPage ({})
{
  const { lang } = useContext(LangContext);

  return (
    <>
      <PageMenu title="FAQ" />
      <Wrapper>
        <MidTitle>{Translations[lang].title}</MidTitle>
        <Accordion />
        <Newsletter top="100px" />
        <ContactSection top="100px"/>
      </Wrapper>
      <Footer />
    </>
  )
}
import axios from "axios"
import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { ScaleLoader } from "react-spinners"
import __server, { __gcloud } from "../../Api"
import { LangContext } from "../../App"

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    position: fixed;
    box-sizing: border-box;
    padding: 15px;
    overflow: scroll;
    align-items: flex-start;
  }
`

const Card = styled.div`
  width: 1100px;
  background-color: #fff;
  border-radius: 40px;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Image = styled.div`
  height: 300px;
  background-size: cover;
  
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 48.34%, rgba(255, 255, 255, 0.80) 70.21%, #FFF 99.8%), url(${__gcloud}/${params => params.$src});
  background-position: center center;
  border-radius: 40px 40px 0px 0px;
`

const Content = styled.div`
  margin-top: -87px;
  color: #133b51;
  font-family: 'Gilroy-ExtraBold';
  font-size: 20px;
  font-weight: 400;
`

const Top = styled.div`
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
`

const CloseIcon = styled.img.attrs({ src: "/ico/close-popup.svg" })`
  width: 80px;
  cursor: pointer;
`

const Inner = styled.div`
  box-sizing: border-box;
  padding: 0px 67px 30px 67px;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    padding: 0px 20px 50px 20px;
  }
`

const Title = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 36px;
  font-weight: 400;
  width: 485px;
`



const Button = styled.button`
  font-family: 'Manrope';
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  background-color: #1a87c5;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px 30px;
  outline: none;
  margin-top: 25px;
  border: none;
  cursor: pointer;
  align-self: center;
  width: fit-content;
`

const Wyswig = styled.div`
  margin-top: 30px;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  color: #133b51;
  font-weight: 400;

  strong, h3 {
    font-family: 'Gilroy-ExtraBold';
  }

  p {
    margin-block-start: 0px;
    margin-block-end: 0px;
  }

  @media (max-width: 767px) {
    margin-top: 10px;
    font-size: 16px;
  }
`

export default function Popup ({ item, setItem })
{
  const { lang } = useContext(LangContext);

  const [data, setData] = useState(null);

  useEffect(() => {
    axios.get(`${__server}/front/city/${item}?lang=${lang}`).then((resp) => {
      setData(resp.data);
    }).catch((err) => {
      console.error(err);
      setItem(null);
    }).finally(() => {
      
    });
  }, []);

  const onClose = function (e)
  {
    const url = new URL(window.location);
    url.searchParams.delete('miasto');
    window.history.pushState({}, '', url);
    setItem(null);
  }

  return (
    <Wrapper>
      {data === null && <ScaleLoader color="#1a87c5" width={10} height={100} radius={20} />}
      {data && <Card>
        <Image $src={data.image} >
          <Top>
            <CloseIcon onClick={onClose} />
          </Top>
        </Image>
        <Content>
          <Inner>
            <Title>
              {data.name}
            </Title>
            <Wyswig dangerouslySetInnerHTML={{ __html: data.content }}/>
          </Inner>
        </Content>
      </Card>}
    </Wrapper>
  )
}